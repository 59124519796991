import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { usePartiesQuery } from 'features/cash4/_queries/usePartiesQuery';
import { cash4QueryKeys } from 'features/cash4/_shared/cash4QueryKeys';
import { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { useAccountGroups } from 'shared/providers/accountGroupsProvider';
import { useLegalEntityGroups } from 'shared/providers/legalEntityGroupsProvider';
import { mapReportedTransaction, TransactionRow } from '../_utilities/mappings';

export type UseReportedTransactionsQueryProps = UseQueryResult<
	TransactionRow[],
	Error
>;

export function useReportedTransactionsQuery(
	dateRange: [Moment, Moment],
): UseReportedTransactionsQueryProps {
	const { customerApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();
	const { data: parties, isLoading: isPartiesLoading } = usePartiesQuery();
	const { legalEntityGroupIds } = useLegalEntityGroups();
	const { accountGroupIds } = useAccountGroups();

	const getReportedTransactions = useCallback(async () => {
		const response = await customerApiClient.transactions.all({
			startDate: dateRange?.[0]?.format('YYYY-MM-DD') ?? '',
			endDate: dateRange?.[1]?.format('YYYY-MM-DD') ?? '',
			accountGroupIds: accountGroupIds ?? [],
			legalEntityGroupIds: legalEntityGroupIds ?? [],
		});

		if (response?.data?.data) {
			return response.data.data.map((x) => {
				const party = parties
					?.filter((x) => x.type === 'Entity')
					?.find(
						(party) =>
							party.accounts?.find((account) => account.id === x.e4AccountId),
					);

				return mapReportedTransaction(x, party);
			});
		}

		throw new Error('Unable to get reported transactions.');
	}, [
		accountGroupIds,
		customerApiClient.transactions,
		dateRange,
		legalEntityGroupIds,
		parties,
	]);

	const result = useQuery<TransactionRow[], Error>({
		enabled: !isPartiesLoading,
		queryKey: [cash4QueryKeys.reportedTransactions],
		queryFn: getReportedTransactions,
		refetchOnWindowFocus: false,
	});

	const { isLoading, isError, refetch } = result;
	useEffect(() => {
		if (!isLoading && isError) {
			enqueueSnackbar('Unable to get reported transactions.', {
				key: 'cash4-reported-transactions-query-failure',
				preventDuplicate: true,
				variant: 'error',
			});
		}
	}, [enqueueSnackbar, isError, isLoading]);

	useEffect(() => {
		if (!isError) {
			refetch();
		}
	}, [isError, refetch, getReportedTransactions]);

	return result;
}
