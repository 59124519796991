/* eslint-disable mobx/missing-observer */
import { DragHandle } from '@mui/icons-material';
import {
	GRID_REORDER_COL_DEF,
	GridColDef,
	GridRowOrderChangeParams,
} from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { FC, useCallback } from 'react';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { useUser } from 'shared/hooks/useUser';
import { TransactionRuleListItem } from './models';
import { fetchRules, useUpdateRulePriority } from './services';
import { cash4QueryKeys } from 'features/cash4/_shared/cash4QueryKeys';


export type ListRulesProps = {
	columns: GridColDef<TransactionRuleListItem>[];
	onRowClick: (row: TransactionRuleListItem) => void;
};

const ListRules: FC<ListRulesProps> = ({ columns, onRowClick }) => {
	const { cash4 } = useUser();
	const { updateRulePriority, isLoadingPriorities } = useUpdateRulePriority();

	const handleRowOrderChange = useCallback(
		(params: GridRowOrderChangeParams) => {
			if (isLoadingPriorities) return;
			updateRulePriority(params);
		},
		[isLoadingPriorities, updateRulePriority],
	);

	const { data: rules, isFetching: isLoading } = useQuery(
		[cash4QueryKeys.rules],
		fetchRules,
		{
			refetchOnWindowFocus: false,
		},
	);

	return (
		<UserPreferencesDataGrid
			tableKey="rulesPage"
			stonlyId="rules"
			columns={columns}
			rows={rules ? rules : []}
			loading={isLoading}
			emptyMessage="No Rules"
			density="standard"
			columnVisibilityModel={{
				cfcName: false,
				cftName: false,
				cfstName: false,
			}}
			initialState={{
				pinnedColumns: {
					left: ['menu', GRID_REORDER_COL_DEF.field, 'priority'],
				},
			}}
			slots={{
				rowReorderIcon: () => <DragHandle />,
			}}
			rowReordering={cash4.isAuthor && !isLoadingPriorities}
			onRowOrderChange={handleRowOrderChange}
			onRowClick={(params) => onRowClick(params.row)}
			showToolbar
			showCustomViewButton
			hideFooter={false}
			hideFooterSelectedRowCount={true}
			hideFooterPagination={true}
		/>
	);
};
export default ListRules;
