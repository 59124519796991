import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Party } from 'modules/clients/customer-api/src/api/cash4';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { cash4QueryKeys } from '../_shared/cash4QueryKeys';

export type UsePartiesQueryProps = UseQueryResult<Party[], Error>;

export function usePartiesQuery(): UsePartiesQueryProps {
	const { customerApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();

	const getParties = useCallback(async () => {
		const response = await customerApiClient.api.cash4.parties();

		if (response?.data?.data) {
			return response.data.data;
		}

		throw new Error('Unable to get parties.');
	}, [customerApiClient.api.cash4]);

	const result = useQuery<Party[], Error>({
		queryKey: [cash4QueryKeys.parties],
		queryFn: getParties,
		refetchOnWindowFocus: false,
	});

	const { isInitialLoading, isError } = result;
	useEffect(() => {
		if (!isInitialLoading && isError) {
			enqueueSnackbar('Unable to get parties.', {
				key: 'cash4-parties-query-failure',
				preventDuplicate: true,
				variant: 'error',
			});
		}
	}, [enqueueSnackbar, isError, isInitialLoading]);

	return result;
}
