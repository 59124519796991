import { Read, T4ApiResponse, Write } from 'modules/clients/types';
import { User } from '..';
import { AxiosInstance } from 'axios';

export type BankConnection = {
	id: string;
	name: string;
	bankCode: string;
	isGlobalConnection: boolean;
	connectionSettings: { [key: string]: string };
	e4EntityIds: string[];
	e4AccountIds: string[];
	notes: string | null;
	createdOn: string;
	createdBy: User;
	updatedOn: string;
	updatedBy: User;
};

// REQUESTS
export type CreateBankConnectionRequest = {
	name: string;
	bankCode: string;
	isGlobalConnection: boolean;
	connectionSettings: { [key: string]: string };
	e4EntityIds: string[];
	e4AccountIds: string[];
	notes: string | null;
};

export type UpdateBankConnectionRequest = {
	name: string;
	isGlobalConnection: boolean;
	connectionSettings: { [key: string]: string };
	e4EntityIds: string[];
	e4AccountIds: string[];
	notes: string | null;
};

export type LegalEntity = {
	id: string;
	isApproved: boolean;
	code: string;
	displayName: string;
	anglicizedLegalName: string | null;
};

export type Account = {
	id: string;
	isApproved: boolean;
	code: string;
	displayName: string;
	shortName: string | null;
};

export type BankConnectionEndpoints = {
	get: Read<T4ApiResponse<BankConnection>, string>;
	getAllCertificateNames: Read<T4ApiResponse<string[]>>;
	getAll: Read<T4ApiResponse<BankConnection[]>>;
	create: Write<T4ApiResponse<BankConnection>, CreateBankConnectionRequest>;
	update: Write<
		T4ApiResponse<BankConnection>,
		{ id: string; data: UpdateBankConnectionRequest }
	>;
	delete: Write<T4ApiResponse<string>, string>;
	getAllLegalEntities: Read<T4ApiResponse<LegalEntity[]>>;
	getAllAccounts: Read<T4ApiResponse<Account[]>>;
};

export function bankConnections(
	axiosInstance: AxiosInstance,
): BankConnectionEndpoints {
	return {
		get: async (id, config = {}) =>
			await axiosInstance.get(`payments4/bankConnections/${id}`, {
				...config,
			}),
		getAllCertificateNames: async () =>
			await axiosInstance.get('payments4/bankConnections/certificates'),
		getAll: async (config: any = {}) =>
			await axiosInstance.get('payments4/bankConnections', {
				...config,
			}),
		create: async (data, config = {}) =>
			await axiosInstance.post('payments4/bankConnections', data, {
				...config,
			}),
		update: async ({ id, data }, config: any = {}) =>
			await axiosInstance.put(`payments4/bankConnections/${id}`, data, {
				...config,
			}),
		delete: async (id, config = {}) =>
			await axiosInstance.delete(`payments4/bankConnections/${id}`, {
				...config,
			}),
		getAllLegalEntities: async () =>
			await axiosInstance.get('payments4/bankConnections/legalentities'),
		getAllAccounts: async () =>
			await axiosInstance.get('payments4/bankConnections/accounts'),
	};
}
