/* eslint-disable mobx/missing-observer */
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import {
	Box,
	Button,
	TextField,
	TextFieldProps,
	useTheme,
} from '@mui/material';
import { FC, useMemo, useState } from 'react';
import {
	NumericFormat,
	NumericFormatProps,
	numericFormatter,
	SourceInfo,
} from 'react-number-format';

const T4NumberTextField: FC<TextFieldProps> = ({
	sx,
	variant = 'outlined',
	margin = 'dense',
	size = 'small',
	fullWidth = true,
	...rest
}) => {
	const theme = useTheme();

	return (
		<TextField
			{...rest}
			variant={variant}
			margin={margin}
			size={size}
			fullWidth={fullWidth}
			InputLabelProps={{ ...rest.InputLabelProps, shrink: true }}
			sx={{
				...sx,
				'& .MuiFormLabel-root.Mui-disabled': {
					color: theme.palette.text.primary,
				},
				'& .MuiInputBase-input': {
					color: theme.palette.text.primary,
				},
				'& .MuiFormControlLabel-root.Mui-disabled': {
					color: theme.palette.text.primary,
				},
				'& .MuiFormControlLabel-label': {
					color: theme.palette.text.primary,
				},
				...(rest.InputProps?.readOnly && {
					'& label.Mui-focused': {
						color: '#414042',
					},
					'& .MuiOutlinedInput-root': {
						'&.Mui-focused fieldset': {
							borderColor: '#C0C0C0',
							borderWidth: '1px',
						},
						'&:hover fieldset': {
							borderColor: '#C0C0C0',
							borderWidth: '1px',
						},
					},
				}),
			}}
		/>
	);
};

type CustomNumberFormatProps = Omit<
	NumericFormatProps<TextFieldProps>,
	'customInput' | 'onChange' | 'isAllowed' | 'value'
>;

export type T4NumberInputProps = CustomNumberFormatProps & {
	min?: number;
	max?: number;
	incrementBy?: number;
	value: number | null;
};

export const T4NumberInput: FC<T4NumberInputProps> = ({
	value,
	min,
	max,
	incrementBy,
	thousandSeparator = true,
	allowLeadingZeros = false,
	decimalScale = 2,
	...rest
}) => {
	const [showIncrementors, setShowIncrementors] = useState<boolean>(false);

	const incrementAdornment = useMemo(() => {
		return !!incrementBy && showIncrementors ? (
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<Button
					aria-label="Increment Value"
					variant="contained"
					onMouseDown={(event) => {
						event.preventDefault();
						if (rest.onValueChange) {
							let incrementedValue = ((value ?? 0) as number) + incrementBy;
							if (max === undefined || incrementedValue <= max) {
								if (!!min && incrementedValue < min) incrementedValue = min;
								rest.onValueChange(
									{
										floatValue: incrementedValue,
										formattedValue: numericFormatter(
											incrementedValue.toString(),
											{
												...rest,
												thousandSeparator,
												allowLeadingZeros,
												decimalScale,
											} as NumericFormatProps,
										),
										value: incrementedValue.toString(),
									},
									{} as SourceInfo,
								);
							}
						}
					}}
					sx={(theme) => ({
						'&.MuiButtonBase-root.MuiButton-root': {
							backgroundColor: theme.palette.charcoal[100],
							color: theme.palette.charcoal[900],
							height: '0.75rem',
							minWidth: '1rem',
							width: '1rem',
							padding: 0,
							boxShadow: 'none',
							borderRadius: 0,
							':hover': {
								backgroundColor: theme.palette.charcoal[200],
							},
						},
					})}
				>
					<ArrowDropUp sx={{ fontSize: '1.25rem' }} />
				</Button>
				<Button
					aria-label="Decrement Value"
					onMouseDown={(event) => {
						event.preventDefault();
						if (rest.onValueChange) {
							let decrementedValue = ((value ?? 0) as number) - incrementBy;

							if (
								(min === undefined || decrementedValue >= min) &&
								(rest.allowNegative === true || decrementedValue >= 0)
							) {
								rest.onValueChange(
									{
										floatValue: decrementedValue,
										formattedValue: numericFormatter(
											decrementedValue.toString(),
											{
												...rest,
												thousandSeparator,
												allowLeadingZeros,
												decimalScale,
											} as NumericFormatProps,
										),
										value: decrementedValue.toString(),
									},
									{} as SourceInfo,
								);
							}
						}
					}}
					sx={(theme) => ({
						'&.MuiButtonBase-root.MuiButton-root': {
							backgroundColor: theme.palette.charcoal[100],
							color: theme.palette.charcoal[900],
							height: '0.75rem',
							minWidth: '1rem',
							width: '1rem',
							padding: 0,
							boxShadow: 'none',
							borderRadius: 0,
							':hover': {
								backgroundColor: theme.palette.charcoal[200],
							},
						},
					})}
				>
					<ArrowDropDown sx={{ fontSize: '1.25rem' }} />
				</Button>
			</Box>
		) : undefined;
	}, [
		showIncrementors,
		value,
		rest,
		min,
		max,
		incrementBy,
		thousandSeparator,
		allowLeadingZeros,
		decimalScale,
	]);

	return (
		<NumericFormat<TextFieldProps>
			{...rest}
			value={value}
			thousandSeparator={thousandSeparator}
			allowLeadingZeros={allowLeadingZeros}
			decimalScale={decimalScale}
			fixedDecimalScale
			isAllowed={(values) => {
				const { floatValue, formattedValue } = values;
				if (floatValue === null) return formattedValue === '';

				let valid = true;
				if (floatValue !== undefined && min !== undefined)
					valid = floatValue >= min;
				if (floatValue !== undefined && max !== undefined)
					valid = valid && floatValue <= max;
				return valid;
			}}
			customInput={T4NumberTextField}
			InputProps={{
				...rest.InputProps,
				endAdornment: (
					<>
						{rest.InputProps?.endAdornment}
						{incrementAdornment}
					</>
				),
			}}
			onFocus={(event) => {
				setShowIncrementors(true);
				rest.onFocus?.(event);
			}}
			onBlur={(event) => {
				setShowIncrementors(false);
				rest.onBlur?.(event);
			}}
		/>
	);
};
