export const sessionStorageKeys = {
	balancesStartDate: 'cash4-balances-start-date',
	balancesEndDate: 'cash4-balances-end-date',
	reportedTransactionsStartDate: 'cash4-reported-transactions-start-date',
	reportedTransactionsEndDate: 'cash4-reported-transactions-end-date',
	projectedTransactionsStartDate: 'cash4-projected-transaactions-start-date',
	projectedTransactionsEndDate: 'cash4-projected-transactions-end-date',
	forecastedTransactionsStartDate: 'cash4-forecasted-transactions-start-date',
	forecastedTransactionsEndDate: 'cash4-forecasted-transactions-end-date',
};

export function getSessionItem(key: string): string | undefined {
	return sessionStorage.getItem(`t4-${key}`) ?? undefined;
}

export function updateSessionItem(key: string, value?: string): void {
	if (value) {
		sessionStorage.setItem(`t4-${key}`, value);
	} else {
		sessionStorage.removeItem(`t4-${key}`);
	}
}
