import { useSigma } from 'features/_shared/_providers/sigmaProvider';
import {
	sigmaEmbed,
	SigmaWorkbook,
} from 'modules/clients/customer-api/src/sigmaEmbed';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useClients } from './useClients';

const initialLoadingMessage = 'Restoring to your My Reports folder...';

export type UseRecentlyDeletedProps = {
	isLoading: boolean;
	error: string;
	loadingMessage: string;
	selectedWorkbooks: SigmaWorkbook[];
	resetForm: () => void;
	modifySelectedWorkbooks: (
		selectedWorkbook: SigmaWorkbook,
		isSelected: boolean,
	) => void;
	onSubmit: (onSuccess: () => void) => Promise<void>;
	onDelete: (onSuccess: () => void) => Promise<void>;
};

export const useRecentlyDeleted = (): UseRecentlyDeletedProps => {
	const { enqueueSnackbar } = useSnackbar();
	const { customerApiClient } = useClients();
	const {
		folders,
		workbooks,
		updateWorkbook,
		selectWorkbook,
		removeWorkbooks,
	} = useSigma();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [loadingMessage, setLoadingMessage] = useState<string>(
		initialLoadingMessage,
	);
	const [error, setError] = useState<string>('');
	const [selectedWorkbooks, setSelectedWorkbooks] = useState<SigmaWorkbook[]>(
		[],
	);

	const resetForm = useCallback(() => {
		setIsLoading(false);
		setLoadingMessage(initialLoadingMessage);
		setError('');
		setSelectedWorkbooks([]);
	}, []);

	const modifySelectedWorkbooks = useCallback(
		(selectedWorkbook: SigmaWorkbook, isSelected: boolean) => {
			if (!selectedWorkbooks.some((x) => x.id === selectedWorkbook.id)) {
				const nextSelectedWorkbooks = [...selectedWorkbooks];
				nextSelectedWorkbooks.push(selectedWorkbook);
				setSelectedWorkbooks(nextSelectedWorkbooks);
			} else {
				const nextSelectedWorkbooks = selectedWorkbooks.filter(
					(x) => x.id !== selectedWorkbook.id,
				);
				setSelectedWorkbooks(nextSelectedWorkbooks);
			}
		},
		[selectedWorkbooks],
	);

	const onSubmit = useCallback(
		async (onSuccess: () => void) => {
			setIsLoading(true);
			setLoadingMessage('Moving...');

			for (let workbook of selectedWorkbooks) {
				try {
					const fileId = workbook.id;
					const folderId = folders.find((x) => x.name === 'My Reports')?.id;
					if (!folderId) {
						setError('Could not find "My Reports" folder');
					} else {
						await customerApiClient.sigmaEmbed.moveWorkbook({
							fileId: fileId,
							folderId: folderId!,
						});

						const editedReport = workbooks.find((x) => x.id === workbook.id);

						if (editedReport && folderId) {
							const newReport: SigmaWorkbook = {
								...editedReport,
								folderId: folderId,
							};
							updateWorkbook(newReport);
							selectWorkbook(newReport, 'edit');
							resetForm();
							onSuccess();
						}
					}
				} catch {
					setError('Failed to restore report');
				}
			}
			setIsLoading(false);
		},
		[
			customerApiClient.sigmaEmbed,
			folders,
			resetForm,
			selectWorkbook,
			selectedWorkbooks,
			updateWorkbook,
			workbooks,
		],
	);

	const onDelete = useCallback(
		async (onSuccess: () => void) => {
			setLoadingMessage('Deleting...');
			setIsLoading(true);

			try {
				const removedWorkbooks: SigmaWorkbook[] = [];
				for (let workbook of selectedWorkbooks) {
					try {
						await customerApiClient.sigmaEmbed.deleteFile({
							id: workbook.id,
						});
						removedWorkbooks.push(workbook);
						customerApiClient.sigmaEmbed.deleteFolderItems({
							itemId: workbook.id,
						});
					} catch {
						enqueueSnackbar(
							`Failed to delete report with name: ${workbook.name}.`,
							{
								variant: 'error',
							},
						);
					}
				}
				removeWorkbooks(removedWorkbooks);
				resetForm();
				onSuccess();
			} catch {
				setError('Failed to delete reports.');
			} finally {
				setIsLoading(false);
			}
		},
		[
			customerApiClient.sigmaEmbed,
			enqueueSnackbar,
			removeWorkbooks,
			resetForm,
			selectedWorkbooks,
		],
	);

	useEffect(() => {
		return () => resetForm();
	}, [resetForm]);

	return {
		isLoading: isLoading,
		error: error,
		loadingMessage: loadingMessage,
		selectedWorkbooks: selectedWorkbooks,
		resetForm: resetForm,
		modifySelectedWorkbooks: modifySelectedWorkbooks,
		onSubmit: onSubmit,
		onDelete: onDelete,
	};
};
