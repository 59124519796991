import { Party } from 'modules/clients/customer-api/src/api/cash4';
import { TransactionListItem } from '../models';

export type TransactionRow = TransactionListItem & {
	entityCode?: string;
	entityAnglicizedLegalName?: string;
};

export function mapReportedTransaction(
	transaction: TransactionListItem,
	party?: Party,
): TransactionRow {
	return {
		...transaction,
		entityCode: party?.code,
		entityAnglicizedLegalName: party?.anglicizedLegalName,
	};
}
