import { AxiosInstance, AxiosResponse } from 'axios';
import {
	AccountEventsHistory,
	Cash4Account,
	Entity4Account,
} from 'models/apiModels';
import { T4DataResponse, T4Response } from 'modules/clients/types';

export type AccountsEndpoints = {
	getAll: () => Promise<T4DataResponse<Cash4Account[]>>;
	linkAccounts: (
		Cash4AccountId: string,
		Entity4AccountId: string,
	) => Promise<T4DataResponse<Cash4Account>>;
	unlinkAccounts: (
		Cash4AccountId: string,
		Entity4AccountId: string,
	) => Promise<T4DataResponse<Cash4Account>>;
	ignoreAccount: (accountId: string) => Promise<T4DataResponse<Cash4Account>>;
	getAllEntity4Accounts: () => Promise<T4Response<Entity4Account[]>>;
	getAccountEventsHistory: (
		accountId: string,
	) => Promise<T4Response<AccountEventsHistory[]>>;
};

export function accounts(axiosInstance: AxiosInstance): AccountsEndpoints {
	return {
		getAll: async (): Promise<T4DataResponse<Cash4Account[]>> => {
			const response: T4Response<T4DataResponse<Cash4Account[]>> =
				await axiosInstance.get<T4DataResponse<Cash4Account[]>>(
					'api/v1.0/cash4/accounts',
				);
			return response.data;
		},
		linkAccounts: async (
			Cash4AccountId: string,
			Entity4AccountId: string,
		): Promise<T4DataResponse<Cash4Account>> => {
			const response: AxiosResponse<T4DataResponse<Cash4Account>> =
				await axiosInstance.post<T4DataResponse<Cash4Account>>(
					'api/v1.0/cash4/accounts/link',
					{
						Cash4AccountId,
						Entity4AccountId,
					},
				);
			return response.data;
		},
		unlinkAccounts: async (
			Cash4AccountId: string,
			Entity4AccountId: string,
		): Promise<T4DataResponse<Cash4Account>> => {
			const response: AxiosResponse<T4DataResponse<Cash4Account>> =
				await axiosInstance.delete<T4DataResponse<Cash4Account>>(
					'api/v1.0/cash4/accounts/unlink',
					{
						data: {
							Cash4AccountId,
							Entity4AccountId,
						},
					},
				);
			return response.data;
		},
		ignoreAccount: async (
			accountId: string,
		): Promise<T4DataResponse<Cash4Account>> => {
			const response: AxiosResponse<T4DataResponse<Cash4Account>> =
				await axiosInstance.put<T4DataResponse<Cash4Account>>(
					`api/v1.0/cash4/accounts/${accountId}/ignore`,
					// You can include any additional data if required in the request body.
				);
			return response.data;
		},
		getAllEntity4Accounts: async (): Promise<T4Response<Entity4Account[]>> => {
			const response: AxiosResponse<T4Response<Entity4Account[]>> =
				await axiosInstance.get<T4Response<Entity4Account[]>>(
					'api/v1.0/cash4/entity4accounts',
				);
			return response.data;
		},
		getAccountEventsHistory: async (
			accountId: string,
		): Promise<T4Response<AccountEventsHistory[]>> => {
			const response: AxiosResponse<T4Response<AccountEventsHistory[]>> =
				await axiosInstance.get<T4Response<AccountEventsHistory[]>>(
					`api/v1.0/cash4/accounts/${accountId}/eventshistory`,
				);
			return response.data;
		},
	};
}
