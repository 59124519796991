/* eslint-disable mobx/missing-observer */
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, CircularProgress, Divider, IconButton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { E4AccountLink } from 'features/cash4/shared/components/T4SideDrawer/E4AccountLink';
import T4BlueHeader from 'features/cash4/shared/components/T4SideDrawer/T4BlueHeader';
import T4DetailBox, {
	T4DetailBoxProps,
} from 'features/cash4/shared/components/T4SideDrawer/T4DetailBox';
import T4TopBar from 'features/cash4/shared/components/T4SideDrawer/T4TopBar';
import { T4Checkbox } from 'features/entity4/shared/components/atoms/t4Checkbox';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { FC, useMemo, useState } from 'react';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import { ModalBase } from 'shared/components/modalBase';
import {
	ACCESS_DENIED_MESSAGING,
	NOT_FOUND_MESSAGING,
} from 'shared/constants/cannotDisplayMessaging';
import { paths } from 'shared/constants/paths';
import { useClients } from 'shared/hooks/useClients';
import { useUser } from 'shared/hooks/useUser';
import { convertDate, formatReadDate } from 'shared/utilities/dateUtilities';
import { formatCurrency } from 'utilities/currencyUtils';
import { Action, Actions } from '../../../shared/components/T4ActionMenu';
import {
	formattedExchangeRate,
	getProjectedTransactionCopyContentValue,
} from '../../utilities';
import { ReconciliationSummaryView } from '../ReconciliationSummaryView';

export type ProjectionViewDrawerProps = {
	projectionId?: string;
	disableLink?: boolean;
	projectedTransactionActions?: Action[];
};

export const ProjectionViewDrawer: FC<ProjectionViewDrawerProps> = ({
	disableLink = false,
	projectedTransactionActions,
	projectionId,
}) => {
	const { cash4 } = useUser();
	const { customerApiClient } = useClients();
	const { data: projectedTransaction, isLoading } = useQuery(
		['projection', projectionId],
		() =>
			customerApiClient.api.cash4
				.singleProjected(projectionId!)
				.then((res) => res?.data.data),
		{
			refetchOnWindowFocus: false,
			enabled: !!projectionId,
		},
	);

	const [
		isForecastModelExcludedModalOpen,
		setIsForecastModelExcludedModalOpen,
	] = useState<boolean>(false);

	const projectedActions = useMemo(() => {
		return (
			<Actions
				objecttype="Transaction"
				stonlyId={'cash4-projected-transactions-drawer-context-menu'}
				actions={projectedTransactionActions!}
				id="projectedTransactionId-more-menu"
			/>
		);
	}, [projectedTransactionActions]);

	const details = useMemo<T4DetailBoxProps['details']>(() => {
		if (!projectedTransaction) {
			return [];
		}

		const values = [
			{
				type: 'bold',
				label: 'Entity',
				value: projectedTransaction?.primaryParty.object?.name ?? null,
				shouldDisplay: !!projectedTransaction.primaryParty.object?.name,
			},
			{
				type: 'bold',
				label: 'Account',
				value: projectedTransaction.primaryParty.account?.name ?? null,
				shouldDisplay: !!projectedTransaction.primaryParty.account?.name,
			},
			{
				type: 'bold',
				label: 'Counterparty Name',
				value: projectedTransaction.bankName ?? null,
				shouldDisplay: !!projectedTransaction.bankName,
			},
			{
				type: 'bold',
				label: 'Counterparty Code',
				value: projectedTransaction.bankCode ?? null,
				shouldDisplay: !!projectedTransaction.bankCode,
			},
			<Divider sx={{ mt: 2, mb: 2 }} />,
			{
				type: 'bold',
				label: 'Secondary Party Type',
				value: projectedTransaction.secondaryParty?.type ?? null,
				shouldDisplay: !!projectedTransaction.secondaryParty?.type,
			},
			{
				type: 'bold',
				label: 'Secondary Entity',
				value: projectedTransaction.secondaryParty?.object?.name ?? null,
				shouldDisplay: !!projectedTransaction.secondaryParty?.object?.name,
			},
			{
				type: 'bold',
				label: 'Secondary Account',
				value: projectedTransaction.secondaryParty?.account?.name ?? null,
				shouldDisplay: !!projectedTransaction.secondaryParty?.account?.name,
			},
			<Divider sx={{ mt: 2, mb: 2 }} />,
			{
				type: 'bold',
				label: 'Label',
				value: projectedTransaction.label ?? null,
				shouldDisplay: !!projectedTransaction.label,
			},
			{
				type: 'bold',
				label: 'Transaction Amount',
				value: `${formatCurrency(
					projectedTransaction.amount.accountCurrencyAmount,
					{
						currency: projectedTransaction.amount.accountCurrencyCode,
					},
				)}  ${projectedTransaction.amount.accountCurrencyCode.toUpperCase()}`,
				shouldDisplay: !!projectedTransaction.amount.accountCurrencyAmount,
			},
			{
				type: 'bold',
				label: 'Accounting Booking FX Rate',
				value: `${formattedExchangeRate(
					projectedTransaction.amount.accountBookingFxRate,
				)}`,
				shouldDisplay: !!projectedTransaction.amount.accountBookingFxRate,
			},
			{
				type: 'bold',
				label: 'Reporting Amount',
				value: `${formatCurrency(
					projectedTransaction.amount.reportingCurrencyAmount,
					{
						currency: projectedTransaction.amount.reportingCurrencyCode,
					},
				)}  ${
					projectedTransaction.amount.reportingCurrencyCode?.toUpperCase() ||
					'USD'
				}`,
				shouldDisplay: !!projectedTransaction.amount.reportingCurrencyAmount,
			},

			<Divider sx={{ mt: 2, mb: 2 }} />,
			{
				type: 'bold',
				label: 'Expected Value Date',
				value:
					formatReadDate(
						convertDate(projectedTransaction?.expectedValueDate),
					) ?? null,
				shouldDisplay: !!projectedTransaction.expectedValueDate,
			},
			{
				type: 'bold',
				label: 'Actual Value Date',
				value:
					formatReadDate(convertDate(projectedTransaction?.actualValueDate)) ??
					null,
				shouldDisplay: !!projectedTransaction.actualValueDate,
			},

			<Divider sx={{ mt: 2, mb: 2 }} />,
			{
				type: 'bold',
				label: 'Check Number',
				value: projectedTransaction.checkNumber ?? null,
				shouldDisplay: !!projectedTransaction.checkNumber,
			},
			{
				type: 'bold',
				label: 'End-to-End ID Number',
				value: projectedTransaction.endToEndId ?? null,
				shouldDisplay: !!projectedTransaction.endToEndId,
			},
			{
				type: 'bold',
				label: 'Instruction ID Number',
				value: projectedTransaction.instructionalId ?? null,
				shouldDisplay: !!projectedTransaction.instructionalId,
			},
			{
				type: 'bold',
				label: 'Source',
				value: projectedTransaction.source.sourceType ?? null,
				shouldDisplay: !!projectedTransaction.source.sourceType,
			},
		];

		values.push(
			<Divider sx={{ mt: 2, mb: 2 }} />,
			{
				type: 'bold',
				label: 'Cash Flow Class',
				value: `${projectedTransaction.categorization?.class?.name} (${projectedTransaction.categorization?.class?.code})`,
				shouldDisplay: !!projectedTransaction.categorization?.class,
			},
			{
				type: 'bold',
				label: 'Cash Flow Type',
				value: `${projectedTransaction.categorization?.type?.name} (${projectedTransaction.categorization?.type?.code})`,
				shouldDisplay: !!projectedTransaction.categorization?.type,
			},
			{
				type: 'bold',
				label: 'Cash Flow Subtype',
				value: `${projectedTransaction.categorization?.subtype?.name} (${projectedTransaction.categorization?.subtype?.code})`,
				shouldDisplay: !!projectedTransaction.categorization?.subtype,
			},
			{
				type: 'bold',
				label: 'GL Code',
				value: projectedTransaction.categorization?.glCode?.code || '',
				shouldDisplay: !!projectedTransaction.categorization?.glCode,
			},
		);

		return values as T4DetailBoxProps['details'];
	}, [projectedTransaction]);

	if (!cash4.isViewer) {
		return (
			<CannotDisplay
				headingText={ACCESS_DENIED_MESSAGING.HEADING}
				bodyText={ACCESS_DENIED_MESSAGING.BODY}
				imageSrc={ACCESS_DENIED_MESSAGING.IMAGE}
			/>
		);
	} else if (isLoading) {
		return (
			<Box
				sx={{
					display: 'flex',
					width: '100%',
					padding: '1rem',
					justifyContent: 'center',
				}}
			>
				<CircularProgress />
			</Box>
		);
	} else if (!projectedTransaction) {
		return (
			<CannotDisplay
				headingText="Not found"
				bodyText="Projected transaction doesn't exist."
				imageSrc={NOT_FOUND_MESSAGING.IMAGE}
			/>
		);
	}

	return (
		<>
			<T4TopBar
				title="Projected Transaction Details"
				copyText={getProjectedTransactionCopyContentValue(projectedTransaction)}
				stonlyPrefix="projected-item-details"
				copyUrl={`${window.location.origin}${paths.cash4.projectedTransactions.href}/${projectedTransaction.id}?tab=projected`}
				disableLink={disableLink}
				customActionDropdown={
					projectedTransactionActions && projectedTransactionActions!.length > 0
						? projectedActions
						: undefined
				}
			/>
			<T4BlueHeader
				bigNumber={projectedTransaction.amount.accountCurrencyAmount}
				currency={projectedTransaction.amount.accountCurrencyCode}
				subheadlines={[
					<E4AccountLink
						e4AccountId={projectedTransaction.primaryParty.account?.id}
						e4AccountName={projectedTransaction.primaryParty.account?.name}
						stonlyPrefix="projected-item-details"
					/>,

					formatReadDate(
						convertDate(projectedTransaction?.expectedValueDate),
					) ?? null,
				]}
			/>
			<T4DetailBox details={details} />

			<ReconciliationSummaryView
				summary={projectedTransaction.reconciliationSummary}
				noReconciliationText="Unreconciled"
			/>

			<Box>
				<IconButton
					onClick={() => {
						setIsForecastModelExcludedModalOpen(true);
					}}
				>
					<InfoOutlinedIcon />
				</IconButton>
				<T4Checkbox
					label="Exclude from forecast reports"
					checked={projectedTransaction.isForecastModelExcluded}
					value={projectedTransaction.isForecastModelExcluded}
					disabled={true}
				/>
				<ModalBase
					title="Exclude from forecast reports"
					open={isForecastModelExcludedModalOpen}
					onClose={() => {
						setIsForecastModelExcludedModalOpen(false);
					}}
				>
					Select to exclude the transaction from forecast model calculations.
					This is useful for removing one-time or non-recurring transactions
					that may skew future projections.
				</ModalBase>
			</Box>

			<Box>
				<T4TextFieldV2
					sx={{ mt: 2, mb: 2 }}
					multiline
					minRows={5}
					maxRows={5}
					label="Notes"
					value={projectedTransaction.description}
					disabled
					helperText={`${projectedTransaction.description?.length ?? 0}/2048`}
				/>
			</Box>
		</>
	);
};
