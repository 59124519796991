import { Alert, Box, Collapse, Grid, Tooltip, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { observer } from 'mobx-react-lite';
import { Account as E4Account } from 'modules/clients/apiGateway/entity4/accounts';
import { T4DataResponse2 } from 'modules/clients/types';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CancellationModal } from 'shared/components/CancellationModal';
import { T4DrawerBase } from 'shared/components/drawer/drawerBase';
import {
	DrawerCancelButton,
	DrawerSubmitButton,
} from 'shared/components/drawer/drawerButtons';
import { T4CurrencyInput } from 'shared/components/t4CurrencyInput';
import { T4DatePicker } from 'shared/components/t4DatePicker';
import { useClients } from 'shared/hooks/useClients';
import { useReferenceDataFetcher } from 'shared/hooks/useReferenceDataFetcher';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { useCreateBalanceMutation } from './_hooks/useCreateBalanceMutation';
import { yupResolver } from '@hookform/resolvers/yup';
import { BalanceForm, defaultBalanceForm } from './_hooks/constants';
import { BalanceFormValidator, E4AccountValidator } from './validators';
import moment from 'moment';
import { useEditBalanceMutation } from './_hooks/useEditBalanceMutation';
import { BalanceSourceType } from 'modules/clients/customer-api/src/api/v1/cash4/balances';
import { useParams } from 'react-router-dom';
import { useBalanceQuery } from '../../_hooks/useBalanceQuery';

type CreateOrEditBalanceDrawerProps = {
	isOpen: boolean;
	onClose: () => void;
};

export const CreateOrEditBalanceDrawer: FC<CreateOrEditBalanceDrawerProps> =
	observer(({ isOpen, onClose }) => {
		const { balanceId } = useParams<{ balanceId: string | undefined }>();
		const { applicationApiClient } = useClients();
		const { fetch: fetchReferenceData } = useReferenceDataFetcher();
		const { enqueueSnackbar } = useSnackbar();

		const {
			control,
			watch,
			setValue,
			trigger,
			formState,
			handleSubmit,
			reset,
		} = useForm<BalanceForm>({
			defaultValues: defaultBalanceForm,
			resolver: yupResolver(BalanceFormValidator),
		});
		const { mutate: createBalance, isLoading: isCreateLoading } =
			useCreateBalanceMutation();

		const { mutate: editBalance, isLoading: isEditLoading } =
			useEditBalanceMutation();

		const { isLoading: isBalanceLoading, data: balance } =
			useBalanceQuery(balanceId);

		const {
			isLoading: areE4AccountsLoading,
			isFetching: areE4AccountsFetching,
			data: e4Accounts,
			error: loadingE4AccountsError,
		} = useQuery<E4Account[], Error>({
			queryKey: ['cash4', 'e4-account', 'get-all'],
			queryFn: async () => {
				const response = await applicationApiClient.entity4.accounts.getAll();
				if (response.status === 200)
					return (response.data as T4DataResponse2<E4Account[]>).data.sort(
						(a, b) => a.displayName.localeCompare(b.displayName),
					);
				else throw new Error();
			},
			enabled: isOpen,
		});
		useEffect(() => {
			if (loadingE4AccountsError)
				enqueueSnackbar(
					'Unable to load Entity4 accounts. Please try again later.',
					{
						key: 'get-all-entity4-accounts-error',
						preventDuplicate: true,
						variant: 'error',
					},
				);
		});

		const {
			isLoading: areCurrencyCodesLoading,
			isFetching: areCurrencyCodesFetching,
			data: currencyCodes,
			error: loadingCurrencyCodesError,
		} = useQuery<string[], Error>({
			queryKey: ['cash4', 'currency-codes', 'get-all'],
			queryFn: async () => {
				const response = await fetchReferenceData({
					referenceListNames: ['FunctionalCurrency'],
				});
				if (response)
					return response.referenceLists['FunctionalCurrency'].map(
						(x) => x.value,
					);
				else throw new Error();
			},
			enabled: isOpen,
		});
		useEffect(() => {
			if (loadingCurrencyCodesError)
				enqueueSnackbar(
					'Unable to load currency codes. Please try again later.',
					{
						key: 'get-all-currency-codes-error',
						preventDuplicate: true,
						variant: 'error',
					},
				);
		});

		useEffect(() => {
			if (!!balance) {
				const existingForm: BalanceForm = {
					e4Account:
						(e4Accounts ?? []).find((x) => x.id === balance.e4AccountId) ??
						null,
					balanceDate: moment(balance.statementDate) ?? null,
					currency: balance.currency ?? 'USD',
					note: balance.note ?? null,
					openingLedgerBalance: balance.openingLedgerBalance.value ?? null,
					openingAvailableBalance:
						balance.openingAvailableBalance.value ?? null,
					closingLedgerBalance: balance.openingLedgerBalance.value ?? null,
					closingAvailableBalance:
						balance.openingAvailableBalance.value ?? null,
				};
				reset(existingForm);
			}
		}, [balance, e4Accounts, reset]);

		const [isCancellationModalOpen, setIsCancellationModalOpen] =
			useState<boolean>(false);

		const resetDrawer = useCallback(() => {
			setIsCancellationModalOpen(false);
			reset(defaultBalanceForm);
			onClose();
		}, [reset, onClose]);

		const tryCloseDrawer = useCallback(() => {
			if (formState.isDirty) setIsCancellationModalOpen(true);
			else resetDrawer();
		}, [formState, resetDrawer]);

		const e4Account = watch('e4Account');
		const accountInfoBox = useMemo(
			() => (
				<Collapse
					in={!!e4Account}
					sx={{
						marginTop: !!e4Account ? '1rem' : 0,
						transition: 'margin-top 300ms',
					}}
				>
					<Grid
						container
						item
						xs="auto"
						sx={(theme) => ({
							gap: 1,
							borderRadius: '4px',
							padding: '.5rem',
							backgroundColor: theme.palette.cornflower[50],
							color: theme.palette.charcoal[500],
						})}
					>
						<Grid container item xs={12}>
							<Grid item xs={5}>
								<Typography fontWeight={500}>
									Natural Account Number:
								</Typography>
							</Grid>
							<Grid item xs>
								<Typography sx={{ textAlign: 'right' }}>
									{e4Account?.accountNumbers.naturalAccountNumber}
								</Typography>
							</Grid>
						</Grid>
						<Grid container item xs={12}>
							<Grid item xs={5}>
								<Typography fontWeight={500}>Bank Name:</Typography>
							</Grid>
							<Grid item xs>
								<Typography sx={{ textAlign: 'right' }}>
									{e4Account?.heldWithCounterparty?.displayName}
								</Typography>
							</Grid>
						</Grid>
						<Grid container item xs={12}>
							<Grid item xs={5}>
								<Typography fontWeight={500}>Bank Code:</Typography>
							</Grid>
							<Grid item xs>
								<Typography sx={{ textAlign: 'right' }}>
									{e4Account?.heldWithCounterparty?.bankCode}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Collapse>
			),
			[e4Account],
		);

		const currencyCode = watch('currency');
		const openingLedgerBalance = watch('openingLedgerBalance');
		const openingAvailableBalance = watch('openingAvailableBalance');
		const closingLedgerBalance = watch('closingLedgerBalance');
		const closingAvailableBalance = watch('closingAvailableBalance');

		return (
			<T4DrawerBase
				title={!!balance ? 'Edit Balance Record' : 'Create Balance Record'}
				open={isOpen}
				onClose={tryCloseDrawer}
				initializing={
					isBalanceLoading || (areE4AccountsLoading && areE4AccountsFetching)
				}
				actions={[
					<DrawerCancelButton onCancel={tryCloseDrawer} />,
					<DrawerSubmitButton
						label={!!balance ? 'Save' : 'Create'}
						onSubmit={handleSubmit((data) =>
							!!balance
								? editBalance(
										{ id: balance.id, data },
										{ onSuccess: () => resetDrawer() },
								  )
								: createBalance(data, { onSuccess: () => resetDrawer() }),
						)}
						disabled={isCreateLoading || isEditLoading || !formState.isDirty}
					/>,
				]}
				loading={isCreateLoading || isEditLoading}
				disableNavigationCollapse
			>
				<Grid container sx={{ flexDirection: 'column', gap: 2 }}>
					<Grid
						container
						item
						xs="auto"
						sx={(theme) => ({
							flexDirection: 'column',

							border: '1px solid',
							borderColor: theme.palette.charcoal[50],
							borderRadius: '4px',
							padding: '.5rem',
						})}
					>
						<Grid
							container
							item
							xs="auto"
							sx={{ flexDirection: 'column', gap: 2 }}
						>
							<Grid item xs="auto">
								<Typography variant="h4">Account Information</Typography>
							</Grid>
							<Grid item xs="auto">
								<Controller
									name="e4Account"
									control={control}
									render={({
										field: { onChange, value },
										fieldState: { error },
									}) => (
										<T4Autocomplete<E4Account>
											id="account"
											label="Account"
											options={e4Accounts ?? []}
											value={value}
											onChange={(_, value) => {
												onChange(value);
												if (value !== null)
													setValue('currency', value.currencyCode ?? 'USD');
											}}
											getOptionLabel={(option) => option.displayName}
											isOptionEqualToValue={(option, value) =>
												option.id === value.id
											}
											getOptionDisabled={(option) =>
												!E4AccountValidator.isValidSync(option)
											}
											renderOption={(props, option, _, ownerState) => {
												const labelBox = (
													<Box
														component="li"
														{...props}
														key={props.id ?? option.id}
													>
														{ownerState.getOptionLabel(option)}
													</Box>
												);

												try {
													E4AccountValidator.validateSync(option, {
														abortEarly: false,
													});
													return labelBox;
												} catch (error: any) {
													return (
														<Box key={option.id}>
															<Tooltip
																title={
																	<Box>
																		<Typography
																			variant="caption"
																			sx={(theme) => ({
																				display: 'block',
																				color:
																					theme.palette.primary.contrastText,
																			})}
																		>
																			Account has missing or invalid data:
																		</Typography>
																		{(error?.errors ?? []).map(
																			(errorMessage: string, index: number) => (
																				<Typography
																					key={`${option.id}-${index}`}
																					variant="caption"
																					sx={(theme) => ({
																						display: 'block',
																						color:
																							theme.palette.primary
																								.contrastText,
																					})}
																				>
																					- {errorMessage}
																				</Typography>
																			),
																		)}
																	</Box>
																}
																placement="left"
																arrow
															>
																<span>{labelBox}</span>
															</Tooltip>
														</Box>
													);
												}
											}}
											error={!!error}
											helperText={error && error.message}
											required
											loading={areE4AccountsLoading || areE4AccountsFetching}
										/>
									)}
								/>
							</Grid>
						</Grid>
						{accountInfoBox}
					</Grid>
					<Grid
						container
						item
						xs="auto"
						sx={(theme) => ({
							flexDirection: 'column',
							gap: 2,
							border: '1px solid',
							borderColor: theme.palette.charcoal[50],
							borderRadius: '4px',
							padding: '.5rem',
						})}
					>
						<Grid item xs="auto">
							<Typography variant="h4">Balance Information</Typography>
						</Grid>
						<Grid container item xs="auto" spacing={1}>
							<Grid item xs={6}>
								<Controller
									name="balanceDate"
									control={control}
									render={({
										field: { onChange, value },
										fieldState: { error },
									}) => (
										<T4DatePicker
											label="Balance Date"
											value={value}
											onChange={onChange}
											slotProps={{
												textField: {
													error: !!error,
													helperText: error && error.message,
													required: true,
												},
											}}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={6}>
								<Controller
									name="currency"
									control={control}
									render={({
										field: { onChange, value },
										fieldState: { error },
									}) => (
										<T4Autocomplete<string, false, true>
											id="currency"
											label="Currency"
											options={currencyCodes ?? []}
											value={value ?? ''}
											onChange={(_, value) => onChange(value)}
											loading={
												areCurrencyCodesLoading || areCurrencyCodesFetching
											}
											error={!!error}
											helperText={error && error.message}
											required
											disableClearable
										/>
									)}
								/>
							</Grid>
						</Grid>
						<Grid container item xs="auto">
							<Controller
								name="openingLedgerBalance"
								control={control}
								render={({ field: { onChange, value } }) => (
									<T4CurrencyInput
										label="Opening Ledger Balance"
										value={value}
										onValueChange={({ floatValue }) => {
											onChange(floatValue ?? null);
											trigger();
										}}
										locale="en-US"
										currencyCode={currencyCode ?? undefined}
										required={
											!!value ||
											(openingAvailableBalance === null &&
												closingLedgerBalance === null &&
												closingAvailableBalance === null)
										}
									/>
								)}
							/>
						</Grid>
						<Grid container item xs="auto">
							<Controller
								name="openingAvailableBalance"
								control={control}
								render={({ field: { onChange, value } }) => (
									<T4CurrencyInput
										label="Opening Available Balance"
										value={value}
										onValueChange={({ floatValue }) => {
											onChange(floatValue ?? null);
											trigger();
										}}
										locale="en-US"
										currencyCode={currencyCode ?? undefined}
										required={
											!!value ||
											(openingLedgerBalance === null &&
												closingLedgerBalance === null &&
												closingAvailableBalance === null)
										}
									/>
								)}
							/>
						</Grid>
						<Grid container item xs="auto">
							<Controller
								name="closingLedgerBalance"
								control={control}
								render={({ field: { onChange, value } }) => (
									<T4CurrencyInput
										label="Closing Ledger Balance"
										value={value}
										onValueChange={({ floatValue }) => {
											onChange(floatValue ?? null);
											trigger();
										}}
										locale="en-US"
										currencyCode={currencyCode ?? undefined}
										required={
											!!value ||
											(openingLedgerBalance === null &&
												openingAvailableBalance === null &&
												closingAvailableBalance === null)
										}
									/>
								)}
							/>
						</Grid>
						<Grid container item xs="auto">
							<Controller
								name="closingAvailableBalance"
								control={control}
								render={({ field: { onChange, value } }) => (
									<T4CurrencyInput
										label="Closing Available Balance"
										value={value}
										onValueChange={({ floatValue }) => {
											onChange(floatValue ?? null);
											trigger();
										}}
										locale="en-US"
										currencyCode={currencyCode ?? undefined}
										required={
											!!value ||
											(openingLedgerBalance === null &&
												openingAvailableBalance === null &&
												closingLedgerBalance === null)
										}
									/>
								)}
							/>
						</Grid>

						{(formState.errors.openingLedgerBalance ||
							formState.errors.openingAvailableBalance ||
							formState.errors.closingLedgerBalance ||
							formState.errors.closingAvailableBalance) && (
							<Alert
								sx={{ '&.MuiPaper-root': { height: '100%', margin: 0 } }}
								severity="error"
							>
								At least one balance amount must be present.
							</Alert>
						)}
					</Grid>

					<Grid
						container
						item
						xs="auto"
						sx={(theme) => ({
							flexDirection: 'column',
							gap: 2,
							border: '1px solid',
							borderColor: theme.palette.charcoal[50],
							borderRadius: '4px',
							padding: '.5rem',
						})}
					>
						<Grid item xs="auto">
							<Typography variant="h4">Additional Details</Typography>
						</Grid>
						{balance?.sourceType === BalanceSourceType.Manual && (
							<Grid item xs="auto">
								<T4TextFieldV2
									id="source-type"
									label="Source"
									value={balance?.sourceType ?? ''}
									disabled
								/>
							</Grid>
						)}

						<Grid item xs="auto">
							<Controller
								name="note"
								control={control}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<T4TextFieldV2
										id="note"
										label="Note"
										value={value ?? ''}
										onChange={(value: string) => {
											if (isStringUndefinedOrNullOrWhitespace(value))
												onChange(null);
											else onChange(value);
										}}
										minRows={4}
										maxRows={4}
										multiline
										error={!!error}
										helperText={`${value?.length ?? 0}/2048${
											error?.message ? ' ' + error.message : ''
										}`}
										inputProps={{
											maxLength: 2048,
										}}
									/>
								)}
							/>
						</Grid>
					</Grid>
				</Grid>

				<CancellationModal
					variant={!!balance ? 'edit' : 'create'}
					resourceType="balance"
					isOpen={isCancellationModalOpen}
					onClose={() => setIsCancellationModalOpen(false)}
					onSubmit={resetDrawer}
				/>
			</T4DrawerBase>
		);
	});
