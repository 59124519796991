import moment, { Moment } from 'moment';
import { useState } from 'react';
import {
	getSessionItem,
	sessionStorageKeys,
	updateSessionItem,
} from 'shared/constants';

export type UseReportedTransactionsDateRangeProps = {
	defaultDateRange: [Moment, Moment];
	startDate: Moment;
	endDate: Moment;
	setStartDate: (date: Moment) => void;
	setEndDate: (date: Moment) => void;
};

export function useReportedTransactionsDateRange(): UseReportedTransactionsDateRangeProps {
	const defaultDateRange: [Moment, Moment] = [
		moment().subtract(8, 'days').startOf('day'),
		moment().startOf('day'),
	];

	const [startDate, _setStartDate] = useState<Moment>(() => {
		const date = getSessionItem(
			sessionStorageKeys.reportedTransactionsStartDate,
		);

		if (date) {
			return moment(date);
		} else {
			return defaultDateRange[0];
		}
	});

	const [endDate, _setEndDate] = useState<Moment>(() => {
		const date = getSessionItem(sessionStorageKeys.reportedTransactionsEndDate);

		if (date) {
			return moment(date);
		} else {
			return defaultDateRange[1];
		}
	});

	function setStartDate(date: Moment) {
		updateSessionItem(
			sessionStorageKeys.reportedTransactionsStartDate,
			date.toISOString(),
		);
		_setStartDate(date);
	}

	function setEndDate(date: Moment) {
		updateSessionItem(
			sessionStorageKeys.reportedTransactionsEndDate,
			date.toISOString(),
		);
		_setEndDate(date);
	}

	return {
		defaultDateRange: defaultDateRange,
		startDate: startDate,
		endDate: endDate,
		setStartDate: setStartDate,
		setEndDate: setEndDate,
	};
}
