import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { cash4QueryKeys } from 'features/cash4/_shared/cash4QueryKeys';
import { ForecastedTransaction } from 'modules/clients/customer-api/src/api/cash4';
import { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { useLegalEntityGroups } from 'shared/providers/legalEntityGroupsProvider';

export type UseForecastedTransactionsQueryProps = UseQueryResult<
	ForecastedTransaction[],
	Error
>;

export function useForecastedTransactionsQuery(
	dateRange: [Moment, Moment],
): UseForecastedTransactionsQueryProps {
	const { customerApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();
	const { legalEntityGroupIds } = useLegalEntityGroups();

	const getForecastedTransactions = useCallback(async () => {
		const response =
			await customerApiClient.api.cash4.getForecastedTransactions({
				startDate: dateRange?.[0]?.format('YYYY-MM-DD') ?? '',
				endDate: dateRange?.[1]?.format('YYYY-MM-DD') ?? '',
				legalEntityGroupIds: legalEntityGroupIds ?? [],
			});

		if (response?.data?.data) {
			return response.data.data;
		}

		throw new Error('Unable to get forecasted transactions.');
	}, [customerApiClient.api.cash4, dateRange, legalEntityGroupIds]);

	const result = useQuery<ForecastedTransaction[], Error>({
		queryKey: [cash4QueryKeys.forecastedTransactions],
		queryFn: getForecastedTransactions,
		refetchOnWindowFocus: false,
	});

	const { isInitialLoading, isError, refetch } = result;
	useEffect(() => {
		if (!isInitialLoading && isError) {
			enqueueSnackbar('Unable to get forecasted transactions.', {
				key: 'cash4-forecasted-transactions-query-failure',
				preventDuplicate: true,
				variant: 'error',
			});
		}
	}, [enqueueSnackbar, isError, isInitialLoading]);

	useEffect(() => {
		if (!isError) {
			refetch();
		}
	}, [isError, refetch, getForecastedTransactions]);

	return result;
}
