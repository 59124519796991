import {
	TransactionRule,
	TransactionRuleListItem,
	TransactionRuleRequest,
} from '../models';

export const stonlyIds = {
	runRulesButton: 'run-rules-button',
	createRuleButton: 'create-rule-button',
	editRuleButton: 'edit-rule-button',
	deleteRuleButton: 'delete-rule-button',
	menuOptionsButton: 'menu-options-button',
};

export const dataTestIds = {
	runRulesButton: 'run-rules-button',
	createRuleButton: 'create-rule-button',
	editRuleButton: 'edit-rule-button',
	deleteRuleButton: 'delete-rule-button',
	menuOptionsButton: 'menu-options-button',
};

export const mapRuleToRuleListItem = (
	rule: TransactionRule,
): TransactionRuleListItem => ({
	...rule,
	id: rule.id,
	priority: rule.priority,
	name: rule.name,
	matchConditions: rule.matchConditions,
	cfc: rule.cashFlowClass,
	cft: rule.cashFlowType,
	cfst: rule.cashFlowSubtype,
	glCode: rule.glCode,
	menu: rule,
	transactionCount: rule.transactionCount,
	lastAppliedOn: rule.lastAppliedOn,
});

export const mapRuleToRuleRequest = (
	rule: TransactionRule,
): TransactionRuleRequest => ({
	transactionId: rule.id,
	name: rule.name,
	priority: rule.priority,
	isManualCategorization: rule.isManualCategorization,
	matchConditions: rule.matchConditions,
	classId: rule.cashFlowClass.id,
	typeId: rule.cashFlowType.id,
	subtypeId: rule.cashFlowSubtype?.id,
	glCode: rule.glCode,
});

export const sortRules = (rules: TransactionRuleListItem[]) => {
	return rules.sort((a, b) => {
		if (a.priority === 0 && b.priority === 0) return 0;
		if (a.priority === 0) return 1;
		if (b.priority === 0) return -1;
		return a.priority - b.priority;
	});
};
