import { Box, Divider, Grid, MenuItem, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { T4DataGrid } from 'shared/components/dataGrid/dataGrid';
import { DeleteMenuItem } from 'shared/components/deleteMenuItem';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { useUser } from 'shared/hooks/useUser';
import { stonlyData } from 'stonly/functions';
import {
	USER_PREFERENCES_FIELD_OPTIONS,
	getOptionsMenuColDef,
} from '../../../shared/utilities/dataGrid/dataGridUtils';
import { AddSubtypeModal } from './addSubtypeModal/addSubtypeModal';
import { AddTypeModal } from './addTypeModal/addTypeModal';
import { CashFlowListItem, CategoriesViewModel } from './categoriesViewModel';
import { DeleteSubtypeModal } from './deleteSubtypeModal/deleteSubtypeModal';
import { DeleteTypeModal } from './deleteTypeModal/deleteTypeModal';
import { EditSubtypeModal } from './editSubtypeModal/editSubtypeModal';
import { EditTypeModel } from './editTypeModal/editTypeModal';

export const stonlyIds = {
	addTypeMenuItem: 'add-cashflowtype-menu-item',
	addSubTypeMenuItem: 'add-cashflowsubtype-menu-item',
	editTypeMenuItem: 'edit-cashflowtype-menu-item',
	editSubTypeMenuItem: 'edit-cashflowsubtype-menu-item',
	deleteTypeMenuItem: 'delete-cashflowtype-menu-item',
	deleteSubTypeMenuItem: 'delete-cashflowsubtype-menu-item',
	overflowMenuButton: 'overflow-menu-button',
};

export const dataTestIds = {
	addTypeMenuItem: 'add-cashflowtype-menu-item',
	addSubTypeMenuItem: 'add-cashflowsubtype-menu-item',
	editTypeMenuItem: 'edit-cashflowtype-menu-item',
	editSubTypeMenuItem: 'edit-cashflowsubtype-menu-item',
	deleteTypeMenuItem: 'delete-cashflowtype-menu-item',
	deleteSubTypeMenuItem: 'delete-cashflowsubtype-menu-item',
	overflowMenuButton: 'overflow-menu-button',
};

export const CategoriesRoute: FC = observer(() => {
	const viewModel = useMemo(() => new CategoriesViewModel(), []);

	useEffect(() => {
		viewModel.load();
	}, [viewModel]);

	return <CategoriesPage viewModel={viewModel} />;
});

export type CategoriesPageProps = {
	viewModel: CategoriesViewModel;
};

export const CategoriesPage: FC<CategoriesPageProps> = observer(
	({ viewModel }) => {
		const {
			cash4: { isAuthor },
		} = useUser();

		const getColumns = useCallback((): GridColDef<CashFlowListItem>[] => {
			const columns: GridColDef<CashFlowListItem>[] = [];

			if (isAuthor) {
				columns.push({
					...getOptionsMenuColDef((params) => {
						if (!isAuthor) return null;
						switch (params.row.path.length) {
							case 1:
								return (
									<OverflowMenu
										iconButtonProps={{
											'data-testid': `${dataTestIds.overflowMenuButton}-${params.row.id}`,
											...stonlyData({
												id: dataTestIds.overflowMenuButton,
											}),
										}}
										id={`${dataTestIds.overflowMenuButton}-${params.row.id}`}
									>
										<MenuItem
											onClick={() =>
												viewModel.addTypeViewModel.open(
													params.row as CashFlowListItem,
													viewModel.getClasses(),
												)
											}
											{...stonlyData({ id: stonlyIds.addTypeMenuItem })}
											data-testid={`${dataTestIds.addTypeMenuItem}-${params.row.id}`}
										>
											Add Type
										</MenuItem>
									</OverflowMenu>
								);
							case 2:
								return (
									<OverflowMenu
										iconButtonProps={{
											'data-testid': `${dataTestIds.overflowMenuButton}-${params.row.id}`,
											...stonlyData({
												id: dataTestIds.overflowMenuButton,
											}),
										}}
										id={`type-option-menu-${params.row.id}`}
									>
										<MenuItem
											onClick={() =>
												viewModel.editTypeViewModel.open(
													params.row as CashFlowListItem,
													viewModel.getClasses(),
												)
											}
											{...stonlyData({ id: stonlyIds.editTypeMenuItem })}
											data-testid={`${dataTestIds.editTypeMenuItem}-${params.row.id}`}
										>
											Edit Type
										</MenuItem>
										<MenuItem
											onClick={() =>
												viewModel.addSubtypeViewModel.open(
													params.row as CashFlowListItem,
													viewModel.getClasses(),
												)
											}
											{...stonlyData({ id: stonlyIds.addSubTypeMenuItem })}
											data-testid={`${dataTestIds.addSubTypeMenuItem}-${params.row.id}`}
										>
											Add Subtype
										</MenuItem>
										<Divider />
										<DeleteMenuItem
											objecttype="Type"
											onClick={() =>
												viewModel.deleteTypeViewModel.openModal(
													params.row as CashFlowListItem,
												)
											}
											{...stonlyData({ id: stonlyIds.deleteTypeMenuItem })}
											data-testid={`${dataTestIds.deleteTypeMenuItem}-${params.row.id}`}
										/>
									</OverflowMenu>
								);
							case 3:
								return (
									<OverflowMenu
										iconButtonProps={{
											'data-testid': `${dataTestIds.overflowMenuButton}-${params.row.id}`,
											...stonlyData({
												id: dataTestIds.overflowMenuButton,
											}),
										}}
										id={`subtype-option-menu-${params.row.id}`}
									>
										<MenuItem
											onClick={() =>
												viewModel.editSubtypeViewModel.open(
													params.row as CashFlowListItem,
													viewModel.getClasses(),
												)
											}
											{...stonlyData({ id: stonlyIds.editSubTypeMenuItem })}
											data-testid={`${dataTestIds.editSubTypeMenuItem}-${params.row.id}`}
										>
											Edit Subtype
										</MenuItem>
										<Divider />
										<DeleteMenuItem
											objecttype="Subtype"
											onClick={() =>
												viewModel.deleteSubtypeViewModel.openModal(
													params.row as CashFlowListItem,
												)
											}
											{...stonlyData({ id: stonlyIds.deleteSubTypeMenuItem })}
											data-testid={`${dataTestIds.deleteSubTypeMenuItem}-${params.row.id}`}
										/>
									</OverflowMenu>
								);
							default:
								return null;
						}
					}),
				});
			}

			columns.push(
				{
					field: 'code',
					headerName: 'Code',
					type: 'string',
					flex: 1,
				},
				{
					field: 'transactionCount',
					headerName: 'Transactions',
					type: 'number',
					flex: 1,
				},
				{
					field: 'transactionRuleCount',
					headerName: 'Rules',
					type: 'number',
					flex: 1,
				},
			);

			return columns;
		}, [isAuthor, viewModel]);

		return (
			<Box>
				<PageHeader
					id={pageHeaderStonlyIds.categoriesPage}
					title="Categories"
				/>
				<Grid
					paddingX={3}
					container
					sx={{
						marginY: '1.rem',
						paddingY: '1rem',
						flexDirection: 'column',
						flexWrap: 'nowrap',
						'& .cash-flow-class': {
							color: '#426789',
							backgroundColor: '#F7F7F7',
						},
						'& .cash-flow-class-text': {
							fontWeight: 'bold',
						},
					}}
				>
					<Grid item xs="auto" paddingY={1}>
						<Typography variant="subtitle1">
							Explore the categories in the system. Add and edit the type and
							subtype categories to support your cash flow categorization needs.
						</Typography>
					</Grid>
					<Grid item sx={{ height: '65vh' }} paddingY={1}>
						<T4DataGrid
							stonlyId="categories"
							columns={getColumns()}
							rows={viewModel.getCategories()}
							loading={viewModel.isLoading()}
							treeData
							defaultGroupingExpansionDepth={viewModel.getDepth()}
							getTreeDataPath={(row) => row.path}
							getRowClassName={(params) =>
								`cash-flow-${params.row.path.length === 1 ? 'class' : ''}`
							}
							groupingColDef={{
								headerName: 'Name',
								hideDescendantCount: true,
								flex: 3,
								cellClassName: 'cash-flow-class-text',
							}}
							pinnedColumns={{ left: [USER_PREFERENCES_FIELD_OPTIONS] }}
							density="standard"
							sx={{
								height: '100%',
							}}
						/>
					</Grid>
				</Grid>
				<AddTypeModal viewModel={viewModel.addTypeViewModel} />
				<AddSubtypeModal viewModel={viewModel.addSubtypeViewModel} />
				<EditTypeModel viewModel={viewModel.editTypeViewModel} />
				<EditSubtypeModal viewModel={viewModel.editSubtypeViewModel} />
				<DeleteTypeModal viewModel={viewModel.deleteTypeViewModel} />
				<DeleteSubtypeModal viewModel={viewModel.deleteSubtypeViewModel} />
			</Box>
		);
	},
);
