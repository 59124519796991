import {
	useMutation,
	UseMutationResult,
	useQueryClient,
} from '@tanstack/react-query';
import { cash4QueryKeys } from 'features/cash4/_shared/cash4QueryKeys';
import { TransactionRule } from 'modules/clients/customer-api/src/api/cash4/transactionRules';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useClients } from 'shared/hooks/useClients';

export type UseDeleteRuleMutationProps = UseMutationResult<
	TransactionRule,
	Error,
	string
>;

export function useDeleteRuleMutation(): UseDeleteRuleMutationProps {
	const { customerApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();
	const queryClient = useQueryClient();

	const deleteRule = useCallback(
		async (id: string) => {
			if (!id) {
				throw new Error('Rule id provided is invalid.');
			}

			const response =
				await customerApiClient.api.cash4.transactionRules.delete(id);

			if (response?.data?.data) {
				return response?.data?.data;
			}

			throw new Error('Rule deletion failed.');
		},
		[customerApiClient.api.cash4.transactionRules],
	);

	return useMutation<TransactionRule, Error, string>({
		mutationFn: deleteRule,
		onSuccess: () => {
            queryClient.invalidateQueries([cash4QueryKeys.rules]);
			enqueueSnackbar({
				message: 'Rule deleted successfully.',
				variant: 'success',
			});
		},
		onError: () => {
			enqueueSnackbar({
				message: 'Unable to delete rule.',
				variant: 'error',
			});
		},
	});
}
