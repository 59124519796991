import { WarningAmber } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { useRunRulesMutation } from 'features/cash4/rules/_hooks/useRunRulesMutation';
import { T4Alert } from 'features/entity4/shared/components/atoms/t4Alert';
import {
	T4Autocomplete,
	T4AutocompleteProps,
} from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import { FC, useCallback, useState } from 'react';
import T4DateRangePicker from 'shared/components/dateRangePicker/t4DateRangePicker';
import {
	DrawerCancelButton,
	DrawerSubmitButton,
} from 'shared/components/drawer/drawerButtons';
import { ModalBase, ModalBaseActions } from 'shared/components/modalBase';

const defaultDateRange: [Moment, Moment] = [
	moment().startOf('day').subtract(1, 'month'),
	moment().startOf('day'),
];

const defaultRunAgainstOption: RunAgainstOption = {
	key: 'uncategorized',
	name: 'Uncategorized Transactions',
};

export type RunAgainstOption = {
	key: string;
	name: string;
};

export type RunRulesModalProps = {
	open: boolean;
	onClose?: () => void;
	onSubmit?: () => void;
};

export const RunRulesModal: FC<RunRulesModalProps> = observer(
	({ open, onClose, onSubmit }) => {
		const { isLoading, mutateAsync: runRules } = useRunRulesMutation();

		const [runAgainst, setRunAgainst] = useState<RunAgainstOption>(
			defaultRunAgainstOption,
		);

		const [dateRange, setDateRange] = useState<[Moment, Moment]>(
			() => defaultDateRange,
		);

		const handleRunAgainstChange = useCallback<
			/* @ts-ignore */
			T4AutocompleteProps<RunAgainstOption>['onChange']
		>((_, value) => {
			setRunAgainst(value ?? defaultRunAgainstOption);
		}, []);

		const handleDateRangeChange = useCallback(
			(value: DateRange<moment.Moment | null>) => {
				if (value[0] && value[1] && value[0].isSameOrBefore(value[1])) {
					setDateRange([value[0], value[1]]);
				}
			},
			[],
		);

		const handleClose = useCallback(() => {
			onClose?.();
			setRunAgainst(defaultRunAgainstOption);
			setDateRange(defaultDateRange);
		}, [onClose]);

		const handleSubmit = useCallback(async () => {
			await runRules({
				uncategorizedTransactionsOnly: runAgainst
					? runAgainst.key === 'uncategorized'
					: undefined,
				fromDate: dateRange[0],
				toDate: dateRange[1],
			});
			onSubmit?.();
			handleClose();
		}, [dateRange, handleClose, onSubmit, runAgainst, runRules]);

		return (
			<ModalBase
				title="Run Rules"
				open={open}
				actions={
					<ModalBaseActions>
						<DrawerCancelButton onCancel={handleClose} />
						<DrawerSubmitButton
							label="Run Rules"
							disabled={isLoading}
							onSubmit={handleSubmit}
						/>
					</ModalBaseActions>
				}
			>
				<Grid container sx={{ gap: 1 }}>
					<Grid item xs={12}>
						<T4Autocomplete<RunAgainstOption>
							label="Run Against"
							value={runAgainst ?? null}
							options={[
								defaultRunAgainstOption,
								{
									key: 'all',
									name: 'All Transactions',
								},
							]}
							disableClearable
							getOptionKey={(x) => x?.key}
							getOptionLabel={(x) => x?.name}
							isOptionEqualToValue={(option, value) =>
								option?.key === value?.key
							}
							onChange={handleRunAgainstChange}
						/>
					</Grid>
					<Grid item xs={12}>
						<T4DateRangePicker
							defaultValue={dateRange}
							fromLabel="From"
							toLabel="To"
							disableFuture
							onChange={handleDateRangeChange}
							sx={{
								width: '100%',
								maxWidth: undefined,
							}}
						/>
					</Grid>
					{runAgainst?.key === 'all' && (
						<Grid item xs={12}>
							<T4Alert
								color="warning"
								icon={<WarningAmber />}
								sx={{ width: '100%' }}
							>
								{
									'Running rules against all transactions may affect prior categorization.'
								}
							</T4Alert>
						</Grid>
					)}
				</Grid>
			</ModalBase>
		);
	},
);
