import { AxiosInstance, AxiosResponse } from 'axios';
import { T4DataResponse } from 'modules/clients/types';

//#region Models

export type CashFlowSubtype = {
	typeId: string;
	id: string;
	code?: string;
	name?: string;
	transactionCount?: number;
	transactionRuleCount?: number;
};

export type CashFlowType = {
	classId?: string;
	id: string;
	code?: string;
	name?: string;
	transactionCount?: number;
	transactionRuleCount?: number;
	subtypes?: CashFlowSubtype[];
};

export type CashFlowClass = {
	id: string;
	code?: string;
	name?: string;
	transactionCount?: number;
	transactionRuleCount?: number;
	types?: CashFlowType[];
};

export type GlCode = {
	id?: string;
	code?: string;
};

export type MatchCondition = {
	matchConditionId: string;
	field: string;
	operator: string;
	values: string[];
};

export type TransactionRule = {
	id: string;
	name?: string;
	priority: number;
	isManualCategorization: boolean;
	cashFlowClass: CashFlowClass;
	cashFlowType: CashFlowType;
	cashFlowSubtype?: CashFlowSubtype;
	glCode?: GlCode;
	transactionCount?: number;
	lastAppliedOn?: string;
	matchConditions?: MatchCondition[];
};

//#endregion

//#region Models

export type RunRulesReq = {
	uncategorizedTransactionsOnly?: boolean;
	fromDate?: string;
	toDate?: string;
};

//#endregion

export type TransactionRuleEndpoints = {
	run: (req: RunRulesReq) => Promise<AxiosResponse<void>>;
	delete: (
		id: string,
	) => Promise<AxiosResponse<T4DataResponse<TransactionRule>>>;
};

export function transactionRules(
	axiosInstance: AxiosInstance,
): TransactionRuleEndpoints {
	return {
		run: async (req) =>
			axiosInstance.post<void>(
				'/api/v1.0/cash4/transactionrules/runrules',
				req,
			),
		delete: async (id) =>
			axiosInstance.delete<T4DataResponse<TransactionRule>>(
				`/api/v1.0/cash4/transactionrules/${id}`,
			),
	};
}
