import {
	useMutation,
	UseMutationResult,
	useQueryClient,
} from '@tanstack/react-query';
import { cash4QueryKeys } from 'features/cash4/_shared/cash4QueryKeys';
import { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { formatDate } from 'shared/utilities/dateUtilities';

export type UseRunRulesMutationParams = {
	uncategorizedTransactionsOnly?: boolean;
	fromDate: Moment;
	toDate: Moment;
};

export type UseRunRulesMutationProps = UseMutationResult<
	void,
	Error,
	UseRunRulesMutationParams
>;

export function useRunRulesMutation(): UseRunRulesMutationProps {
	const { customerApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();
	const queryClient = useQueryClient();

	const runRules = useCallback(
		async (params: UseRunRulesMutationParams) => {
			const response = await customerApiClient.api.cash4.transactionRules.run({
				uncategorizedTransactionsOnly: params.uncategorizedTransactionsOnly,
				fromDate: formatDate(params.fromDate),
				toDate: formatDate(params.toDate),
			});

			if (response.status === 200) {
				queryClient.invalidateQueries([cash4QueryKeys.rulesExecution]);
				return;
			}

			throw new Error('Unable to run rules.');
		},
		[customerApiClient.api.cash4.transactionRules, queryClient],
	);

	return useMutation<void, Error, UseRunRulesMutationParams>({
		mutationFn: runRules,
		onSuccess: () => {
			enqueueSnackbar(
				'Run request received! Rules will run automatically, and categorized transactions will be available shortly. Please check back later if rules are still running.',
				{
					key: 'cash4-rules-run-success',
					preventDuplicate: true,
					variant: 'info',
				},
			);
		},
		onError: () => {
			enqueueSnackbar('Unable to run rules.', {
				key: 'cash4-rules-run-error',
				preventDuplicate: true,
				variant: 'error',
			});
		},
	});
}
