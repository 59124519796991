import {
	Cancel,
	CheckCircle,
	Outbound,
	Pending,
	PlaylistAddCheckCircle,
	WarningAmber,
} from '@mui/icons-material';
import {
	Payment,
	PaymentApprovalLevelStatusTypes,
	PaymentBankStatusTypes,
	PaymentStatusTypes,
} from 'modules/clients/apiGateway/payments4/payments';

export const getPaymentApprovalStatusText = (
	payment: Payment | null | undefined,
): string => {
	if (!payment?.approvalState || payment.approvalState === null) return '-';

	if (
		payment.approvalState.currentStatus !==
		PaymentApprovalLevelStatusTypes[PaymentApprovalLevelStatusTypes.Pending]
	)
		return (
			payment.approvalState.currentStatus ??
			'Approval status is currently unavailable.'
		); // null coalesce string handles the edge case where approval state exists but no status is set

	const completedLevelCount =
		payment?.approvalState?.paymentApprovalLevelStates.filter(
			(levelState) =>
				levelState.status ===
				PaymentApprovalLevelStatusTypes[
					PaymentApprovalLevelStatusTypes.Approved
				],
		).length ?? 0;
	return `${completedLevelCount} of ${payment?.approvalState?.paymentApprovalLevelStates.length} approvals`;
};

export const getPaymentStatusText = (
	value: PaymentStatusTypes | string | undefined,
): string | undefined => {
	if (value === undefined) return;

	if (
		value === PaymentStatusTypes.Created ||
		value === PaymentStatusTypes[PaymentStatusTypes.Created]
	)
		return 'No Matching Tier';
	else if (
		value === PaymentStatusTypes.Submitted ||
		value === PaymentStatusTypes[PaymentStatusTypes.Submitted]
	)
		return 'Pending Approval';
	else {
		if (typeof value !== 'string') return PaymentStatusTypes[value];

		const stringKeys = Object.keys(PaymentStatusTypes).filter((x) =>
			isNaN(Number(x)),
		);
		if (stringKeys.find((x) => x === value) !== undefined) return value;
	}

	return undefined;
};

export const getPaymentStatusIcon = (status: string) => {
	switch (status) {
		case PaymentStatusTypes[PaymentStatusTypes.Created]:
			return <WarningAmber color="warning" />;
		case PaymentStatusTypes[PaymentStatusTypes.Submitted]:
			return <Pending color="warning" />;
		case PaymentStatusTypes[PaymentStatusTypes.Approved]:
			return <PlaylistAddCheckCircle color="warning" />;
		case PaymentStatusTypes[PaymentStatusTypes.Initiated]:
		case PaymentStatusTypes[PaymentStatusTypes.Transmitted]:
			return <Outbound color="warning" />;
		case PaymentStatusTypes[PaymentStatusTypes.Pending]:
			return <Pending color="warning" />;
		case PaymentStatusTypes[PaymentStatusTypes.Rejected]:
		case PaymentStatusTypes[PaymentStatusTypes.Failed]:
			return <Cancel color="error" />;
		case PaymentStatusTypes[PaymentStatusTypes.Canceled]:
			return <Cancel color="warning" />;
		case PaymentStatusTypes[PaymentStatusTypes.Completed]:
			return <CheckCircle color="success" />;
		default:
			return null;
	}
};

export const getPaymentBankStatusText = (
	value: PaymentBankStatusTypes | string | undefined,
): string | undefined => {
	if (value === undefined) return;

	if (
		value === PaymentBankStatusTypes.NotFound ||
		value === PaymentBankStatusTypes[PaymentBankStatusTypes.NotFound]
	)
		return 'Not Found';
	else {
		if (typeof value !== 'string') return PaymentBankStatusTypes[value];

		const stringKeys = Object.keys(PaymentBankStatusTypes).filter((x) =>
			isNaN(Number(x)),
		);
		if (stringKeys.find((x) => x === value) !== undefined) return value;
	}

	return undefined;
};

export const canCancelPayment = (payment: Payment | null | undefined) =>
	!!payment
		? [
				PaymentStatusTypes[PaymentStatusTypes.Created],
				PaymentStatusTypes[PaymentStatusTypes.Submitted],
		  ].includes(payment.currentStatus)
		: false;

export const canGetPaymentBankStatus = (payment: Payment | null | undefined) =>
	!!payment
		? [
				PaymentStatusTypes[PaymentStatusTypes.Transmitted],
				PaymentStatusTypes[PaymentStatusTypes.Pending],
		  ].includes(payment.currentStatus)
		: false;

export const isPaymentCompleted = (payment: Payment | null | undefined) =>
	!!payment
		? [
				PaymentStatusTypes[PaymentStatusTypes.Rejected],
				PaymentStatusTypes[PaymentStatusTypes.Failed],
				PaymentStatusTypes[PaymentStatusTypes.Completed],
				PaymentStatusTypes[PaymentStatusTypes.Canceled],
		  ].includes(payment.currentStatus)
		: false;
