/* eslint-disable mobx/missing-observer */
import { useQuery } from '@tanstack/react-query';
import { Tag } from 'modules/clients/customer-api/src/entity4/tags';
import { useSnackbar } from 'notistack';
import { FC, ReactNode, useEffect } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { TagsContext } from './contexts/tagsContext';

export const TagQueryKeys = {
	base: ['tags'] as const,
	getAll: () => [...TagQueryKeys.base, 'get-all'] as const,
};

export type TagsProviderProps = {
	children: ReactNode;
};

export const TagsProvider: FC<TagsProviderProps> = ({ children }) => {
	const { customerApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();

	const { data, isLoading, isFetching, refetch, error } = useQuery<
		Tag[],
		Error
	>({
		queryKey: TagQueryKeys.getAll(),
		queryFn: async () => {
			let response = await customerApiClient.entity4.tags.getAll();

			if (response.data.success) return response.data.data;
			else throw new Error();
		},
	});

	useEffect(() => {
		if (!isLoading && !isFetching && error)
			enqueueSnackbar('Tags were not retrieved.', {
				variant: 'error',
			});
	}, [isLoading, isFetching, error, enqueueSnackbar]);

	return (
		<TagsContext.Provider
			value={{
				initializing: isLoading && isFetching,
				loading: isFetching,
				tags: data ?? [],
				refetchTags: async () => {
					await refetch();
				},
			}}
		>
			{children}
		</TagsContext.Provider>
	);
};
