import { InfoOutlined } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';
import { T4Modal } from 'features/entity4/shared/components/atoms/t4Modal';
import { observer } from 'mobx-react-lite';
import { FC, ReactNode, useState } from 'react';

export type T4InfoIconProps = {
	title?: string;
	description?: string;
	children: ReactNode;
};

export const T4InfoIcon: FC<T4InfoIconProps> = observer(
	({ title, description, children }) => {
		const [isOpen, setIsOpen] = useState(false);

		return (
			<Grid
				container
				item
				sx={{ flexWrap: 'nowrap', gap: 1, alignItems: 'center' }}
			>
				<Grid item>
					<IconButton size="small" onClick={() => setIsOpen(true)}>
						<InfoOutlined />
					</IconButton>
				</Grid>
				<Grid item>{children}</Grid>
				{title && description && (
					<T4Modal open={isOpen} title={title} onClose={() => setIsOpen(false)}>
						<Typography>{description}</Typography>
					</T4Modal>
				)}
			</Grid>
		);
	},
);
