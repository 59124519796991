import { TransactionRule } from '../../models';

interface Validation {
	helperText: string;
	valid: boolean;
}

export const isValidName = (name: string, dirty: boolean): Validation => {
	let helperText: string = '';
	let valid: boolean = true;
	if (!dirty) return { valid, helperText: '' };
	if (!name?.length) {
		helperText = 'Name is required';
		valid = false;
	}
	if (name.length > 200) {
		helperText = 'Name must be less than 200 characters';
		valid = false;
	}
	return { helperText, valid };
};

export const isValidClass = (
	cfcId: string | null | undefined,
	dirty: boolean,
): Validation => {
	let helperText: string = '';
	let valid: boolean = true;
	if (!dirty) return { valid, helperText: '' };
	if (!cfcId) {
		helperText = 'CFC is required';
		valid = false;
	}
	return { helperText, valid };
};

export const isValidType = (
	typeId: string | null | undefined,
	dirty: boolean,
): Validation => {
	let helperText: string = '';
	let valid: boolean = true;
	if (!dirty) return { valid, helperText: '' };
	if (!typeId) {
		helperText = 'CFT is required';
		valid = false;
	}
	return { helperText, valid };
};

export const isValidMatchConditionField = (
	field: string | null,
	dirty: boolean,
): Validation => {
	let helperText: string = '';
	let valid: boolean = true;
	if (!dirty) return { valid, helperText: '' };
	if (!field) {
		helperText = 'Field is required';
		valid = false;
	}
	return { helperText, valid };
};

export const isValidMatchConditionOperator = (
	operator: string | null,
	dirty: boolean,
): Validation => {
	let helperText: string = '';
	let valid: boolean = true;
	if (!dirty) return { valid, helperText: '' };
	if (!operator) {
		helperText = 'Operator is required';
		valid = false;
	}
	return { helperText, valid };
};

export const isValidMatchConditionValue = (
	value: string,
	dirty: boolean,
	values: string[],
): Validation => {
	let helperText: string = '';
	let valid: boolean = true;
	if (!dirty) return { valid, helperText: '' };
	if (!value?.length) {
		helperText = 'Value is required';
		valid = false;
	}
	if (values?.toString().length > 1800) {
		helperText = 'Match condition length must be less than 1800 characters';
		valid = false;
	}
	return { helperText, valid };
};

export const isFormValid = (rule: TransactionRule) => {
	return (
		isValidName(rule.name, true).valid === true &&
		isValidClass(rule.cashFlowClass?.id ?? null, true).valid === true &&
		isValidType(rule.cashFlowType?.id ?? null, true).valid === true &&
		rule.matchConditions.every((condition) => {
			return (
				isValidMatchConditionField(condition.field, true).valid === true &&
				isValidMatchConditionOperator(condition.operator, true).valid ===
					true &&
				condition.values.every(
					(value) =>
						isValidMatchConditionValue(value, true, condition.values).valid,
				) === true
			);
		}) &&
		rule.matchConditions?.length &&
		rule.priority
	);
};
