import { Add, DeleteOutlined } from '@mui/icons-material';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import {
	Box,
	Button,
	Grid,
	IconButton,
	Tooltip,
	Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { usePartiesQuery } from 'features/cash4/_queries/usePartiesQuery';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { observer } from 'mobx-react-lite';
import { Party } from 'modules/clients/customer-api/src/api/cash4';
import { enqueueSnackbar } from 'notistack';
import React, { FC, useCallback, useMemo } from 'react';
import { T4NumberInput } from 'shared/components/t4NumberInput';
import { stonlyData } from 'stonly/functions';
import { MatchCondition } from '../../models';
import { useDataContext } from '../providers/DataProvider';
import { FormInput, useFormContext } from '../providers/FormProvider';
import { dataTestIds, stonlyIds } from '../utilities';
import {
	isValidMatchConditionField,
	isValidMatchConditionOperator,
	isValidMatchConditionValue,
} from '../validation';

export const RuleConditions: FC = observer(() => {
	const { matchConditions, addMatchCondition } = useFormContext();

	return (
		<>
			<Grid container item xs={12}>
				<Grid item xs={12} padding={1}>
					<Box sx={{ bgcolor: '#e4e4e5', p: 1 }}>
						<Typography variant="subtitle2">
							This rule applies to transactions that match the following
							conditions
						</Typography>
					</Box>
				</Grid>
			</Grid>
			<Grid container item xs={12} paddingTop={1} rowSpacing={1.5}>
				{matchConditions
					?.sort(
						(a, b) => a.field.field?.localeCompare(b.field.field ?? '') ?? 0,
					)
					.map((value, index) => (
						<Condition
							key={index}
							conditionIndex={index}
							matchCondition={value}
						/>
					))}
				<Grid item xs={12} paddingTop={1}>
					<Button
						type="button"
						variant="outlined"
						color="primary"
						startIcon={<Add />}
						data-testid={dataTestIds.addConditionButton}
						{...stonlyData({ id: stonlyIds.addConditionButton })}
						onClick={addMatchCondition}
					>
						Add "And" Condition
					</Button>
				</Grid>
			</Grid>
		</>
	);
});

type ConditionProps = {
	conditionIndex: number;
	matchCondition: FormInput<MatchCondition>;
};

const Condition: FC<ConditionProps> = observer(
	({ conditionIndex, matchCondition }) => {
		const { data: parties, isLoading: isLoadingParties } = usePartiesQuery();
		const { fields, operators, getOperatorOperations } = useDataContext();
		const {
			selectConditionField,
			selectConditionOperator,
			handleChangeConditionValue,
			clearConditionValues,
			removeMatchCondition,
			addMatchConditionOr,
			removeMatchConditionOr,
		} = useFormContext();

		const noOrCondition = useCallback(
			(operator: string | null): boolean => {
				return !getOperatorOperations(operator ?? '').includes('Or');
			},
			[getOperatorOperations],
		);

		const handleSelectConditionField = useCallback(
			(_: React.ChangeEvent<{}>, value: string | null) => {
				selectConditionField(conditionIndex, value);
				selectConditionOperator(conditionIndex, null);
				clearConditionValues(conditionIndex);
			},
			[
				clearConditionValues,
				conditionIndex,
				selectConditionField,
				selectConditionOperator,
			],
		);

		const handleSelectConditionOperator = useCallback(
			(_: React.ChangeEvent<{}>, value: string | null) => {
				if (noOrCondition(value) && matchCondition.field.values.length > 1) {
					enqueueSnackbar({
						message: `${value} operator does not support 'or' operation.`,
						variant: 'error',
					});
				} else {
					selectConditionOperator(conditionIndex, value);
				}
			},
			[
				conditionIndex,
				matchCondition.field.values.length,
				noOrCondition,
				selectConditionOperator,
			],
		);

		const handleRemoveMatchCondition = useCallback(() => {
			if (matchCondition.field.values.length > 1) {
				removeMatchConditionOr(conditionIndex, 0);
			} else {
				removeMatchCondition(conditionIndex);
			}
		}, [
			conditionIndex,
			matchCondition.field.values.length,
			removeMatchCondition,
			removeMatchConditionOr,
		]);

		const entities = useMemo(
			() => parties?.filter((x) => x.type === 'Entity') ?? [],
			[parties],
		);

		return (
			<Grid
				key={`condition-wrapper-${conditionIndex}`}
				container
				item
				xs={12}
				justifyContent="center"
			>
				<Stack
					direction={'row'}
					alignItems="center"
					gap={1}
					sx={{ width: '100%' }}
				>
					<T4Autocomplete<string>
						data-testid={`field-${conditionIndex}`}
						label="Field"
						options={fields}
						getOptionLabel={(option) => option ?? ''}
						isOptionEqualToValue={(option, value) => option === value}
						value={matchCondition.field.field}
						onChange={handleSelectConditionField}
						required
						error={
							!isValidMatchConditionField(
								matchCondition.field.field,
								matchCondition.dirty,
							).valid
						}
						helperText={
							isValidMatchConditionField(
								matchCondition.field.field,
								matchCondition.dirty,
							).helperText
						}
					/>
					<T4Autocomplete<string>
						data-testid={`operator-${conditionIndex}`}
						label="Operator"
						value={matchCondition.field.operator}
						onChange={handleSelectConditionOperator}
						required
						options={operators(matchCondition.field.field!) || []}
						disabled={!matchCondition.field}
						error={
							!isValidMatchConditionOperator(
								matchCondition.field.operator,
								matchCondition.dirty,
							).valid
						}
						helperText={
							isValidMatchConditionOperator(
								matchCondition.field.operator,
								matchCondition.dirty,
							).helperText
						}
					/>
					{matchCondition.field.field === 'Amount' ? (
						<T4NumberInput
							data-testid={`value-${conditionIndex}`}
							label="Value"
							value={
								matchCondition.field.values[0]
									? Number(matchCondition.field.values[0])
									: null
							}
							onValueChange={({ floatValue }) => {
								handleChangeConditionValue(
									floatValue?.toString() ?? '',
									conditionIndex,
									0,
								);
							}}
							error={
								!isValidMatchConditionValue(
									matchCondition.field.values[0] ?? '',
									matchCondition.dirty,
									matchCondition.field.values,
								).valid
							}
							helperText={
								isValidMatchConditionValue(
									matchCondition.field.values[0] ?? '',
									matchCondition.dirty,
									matchCondition.field.values,
								).helperText
							}
							decimalScale={2}
							required
						/>
					) : matchCondition.field.field === 'Entity Name' ? (
						<T4Autocomplete
							loading={isLoadingParties}
							label="Value"
							options={entities}
							getOptionLabel={(x) => x?.name}
							getOptionKey={(x) => x?.id}
							isOptionEqualToValue={(option, value) => option?.id === value?.id}
							value={
								entities.find((x) => x.id === matchCondition.field.values[0]) ??
								null
							}
							onChange={(_, value) =>
								handleChangeConditionValue(value?.id ?? '', conditionIndex, 0)
							}
						/>
					) : (
						<T4TextFieldV2
							data-testid={`value-${conditionIndex}`}
							label="Value"
							value={matchCondition.field.values[0] ?? ''}
							onChange={(value) =>
								handleChangeConditionValue(value, conditionIndex, 0)
							}
							error={
								!isValidMatchConditionValue(
									matchCondition.field.values[0] ?? '',
									matchCondition.dirty,
									matchCondition.field.values,
								).valid
							}
							helperText={
								isValidMatchConditionValue(
									matchCondition.field.values[0] ?? '',
									matchCondition.dirty,
									matchCondition.field.values,
								).helperText
							}
							required
						/>
					)}

					<IconButton
						data-testid={`${dataTestIds.removeConditionButton}-${conditionIndex}`}
						{...stonlyData({
							id: `${stonlyIds.removeConditionButton}-${conditionIndex}`,
						})}
						onClick={handleRemoveMatchCondition}
					>
						<DeleteOutlined />
					</IconButton>
				</Stack>

				{matchCondition.field.values.slice(1).map((value, index) => (
					<OrCondition
						key={index}
						isLoadingParties={isLoadingParties}
						entities={entities}
						valueIndex={index + 1}
						conditionIndex={conditionIndex}
						value={value}
						matchCondition={matchCondition}
					/>
				))}
				<Grid item xs={12} paddingTop={1}>
					<SubdirectoryArrowRightIcon />
					<Tooltip
						title={
							noOrCondition(matchCondition.field.operator)
								? 'The operator selected does not support this action'
								: ''
						}
						placement="right"
					>
						<span>
							<Button
								variant="outlined"
								color="primary"
								startIcon={<Add />}
								onClick={() => addMatchConditionOr(conditionIndex)}
								disabled={noOrCondition(matchCondition.field.operator)}
							>
								Add "Or" Condition
							</Button>
						</span>
					</Tooltip>
				</Grid>
			</Grid>
		);
	},
);

interface OrConditionProps {
	isLoadingParties: boolean;
	entities: Party[];
	valueIndex: number;
	conditionIndex: number;
	value: string;
	matchCondition: FormInput<MatchCondition>;
}

const OrCondition: React.FC<OrConditionProps> = observer(
	({
		isLoadingParties,
		entities,
		valueIndex,
		conditionIndex,
		value,
		matchCondition,
	}) => {
		const { handleChangeConditionValue, removeMatchConditionOr } =
			useFormContext();

		return (
			<Stack
				direction={'row'}
				alignItems="center"
				gap={1}
				sx={{ width: '100%' }}
			>
				<Typography sx={{ fontWeight: 'bold' }}>OR</Typography>
				<T4TextFieldV2 value={matchCondition.field.field} disabled />
				<T4TextFieldV2 value={matchCondition.field.operator} disabled />
				{matchCondition.field.field === 'Amount' ? (
					<T4NumberInput
						data-testid={`value-${conditionIndex}-${valueIndex}`}
						label="Value"
						value={value ? Number.parseFloat(value) : null}
						onValueChange={({ floatValue }) => {
							handleChangeConditionValue(
								floatValue?.toString() ?? '',
								conditionIndex,
								valueIndex,
							);
						}}
						error={
							!isValidMatchConditionValue(
								value,
								matchCondition.dirty,
								matchCondition.field.values,
							).valid
						}
						helperText={
							isValidMatchConditionValue(
								value,
								matchCondition.dirty,
								matchCondition.field.values,
							).helperText
						}
						decimalScale={2}
						required
					/>
				) : matchCondition.field.field === 'Entity Name' ? (
					<T4Autocomplete
						loading={isLoadingParties}
						label="Value"
						options={entities}
						getOptionLabel={(x) => x?.name}
						getOptionKey={(x) => x?.id}
						isOptionEqualToValue={(option, value) => option?.id === value?.id}
						value={
							entities.find(
								(x) => x.id === matchCondition.field.values[valueIndex],
							) ?? null
						}
						onChange={(_, value) =>
							handleChangeConditionValue(
								value?.id ?? '',
								conditionIndex,
								valueIndex,
							)
						}
					/>
				) : (
					<T4TextFieldV2
						data-testid={`value-${conditionIndex}-${valueIndex}`}
						label="Value"
						value={value}
						onChange={(value) =>
							handleChangeConditionValue(value, conditionIndex, valueIndex)
						}
						error={
							!isValidMatchConditionValue(
								value,
								matchCondition.dirty,
								matchCondition.field.values,
							).valid
						}
						helperText={
							isValidMatchConditionValue(
								value,
								matchCondition.dirty,
								matchCondition.field.values,
							).helperText
						}
						required
					/>
				)}

				<IconButton
					data-testid={`${dataTestIds.removeConditionButton}-${conditionIndex}-${valueIndex}`}
					{...stonlyData({
						id: `${stonlyIds.removeConditionButton}-${conditionIndex}-${valueIndex}`,
					})}
					onClick={() => removeMatchConditionOr(conditionIndex, valueIndex)}
				>
					<DeleteOutlined />
				</IconButton>
			</Stack>
		);
	},
);
