import { useState } from 'react';
import { getSessionItem, updateSessionItem } from 'shared/constants';

const keys = {
	organizationId: 'organizationId',
};

export type UseSessionStorageProps = {
	organizationId: string | undefined;
	setOrganizationId: (organizationId?: string) => void;
};

export function useSessionStorage(): UseSessionStorageProps {
	//#region organizationId

	const [organizationId, _setOrganizationId] = useState<string | undefined>(
		getSessionItem(keys.organizationId),
	);

	function setOrganizationId(organizationId?: string) {
		updateSessionItem(keys.organizationId, organizationId);
		_setOrganizationId(organizationId);
	}

	//#endregion

	return {
		organizationId,
		setOrganizationId,
	};
}
