import { Add, DeleteOutline, ExpandMore } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Collapse,
	Divider,
	Grid,
	IconButton,
	InputAdornment,
	Paper,
	ToggleButton,
	ToggleButtonGroup,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import { AxiosResponse } from 'axios';
import { useCash4ConfigurationsQuery } from 'features/_shared/_hooks/useCash4ConfigurationsQuery';
import { usePartiesQuery } from 'features/cash4/_queries/usePartiesQuery';
import { useT4Query } from 'features/cash4/_shared/_utilities/useT4Query';
import { useDataContext } from 'features/cash4/rules/createRuleModal/providers/DataProvider';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4Checkbox } from 'features/entity4/shared/components/atoms/t4Checkbox';
import { T4DateField } from 'features/entity4/shared/components/atoms/t4DateField';
import {
	T4TextFieldV2,
	WrappedT4TextFieldV2,
} from 'features/entity4/shared/components/atoms/t4TextField';
import { observer } from 'mobx-react-lite';
import {
	CashFlowDetail,
	GeneralLedgerDetail,
	Party,
	PartyAccount,
	ProjectedTransaction,
	ProjectedTransactionReq,
} from 'modules/clients/customer-api/src/api/cash4';
import { T4DataResponse } from 'modules/clients/types';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CancellationModal } from 'shared/components/CancellationModal';
import { T4DrawerBase } from 'shared/components/drawer/drawerBase';
import {
	DrawerCancelButton,
	DrawerSubmitButton,
} from 'shared/components/drawer/drawerButtons';
import { ModalBase } from 'shared/components/modalBase';
import { T4NumberInput } from 'shared/components/t4NumberInput';
import { useClients } from 'shared/hooks/useClients';
import { useReferenceDataFetcher } from 'shared/hooks/useReferenceDataFetcher';
import { useUser } from 'shared/hooks/useUser';
import { convertDate } from 'shared/utilities/dateUtilities';
import { stonlyData } from 'stonly/functions';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { TransactionAmountInfoModal } from '../shared/TransactionAmountInfoModal';

const stonlyIds = {
	submitButton: 'create-projected-item-submit-button',
	cancelButton: 'create-projected-item-cancel-button',
	detailsSection: 'create-projected-item-details-section',
	primaryPartySection: 'create-projected-item-primaryParty-section',
	secondaryPartySection: 'create-projected-item-secondaryParty-section',
	categorizationSection: 'create-projected-item-categorization-section',
	flowDirectionBtnGroup: 'create-projected-item-flow-direction',
	secondaryFlowDirectionBtnGroup:
		'create-projected-item-secondary-flow-direction',
	addSecondaryPartyButton: 'create-projected-item-add-secondary-party-button',
	removeSecondaryPartyButton:
		'create-projected-item-remove-secondary-party-button',
};

export type ProjectedTransactionForm = {
	primaryPartyType: string | null;
	primaryPartyObject: Party | null;
	primaryPartyAccount: PartyAccount | null;
	secondaryPartyType: string | null;
	secondaryPartyObject: Party | null;
	secondaryPartyAccount: PartyAccount | null;
	label: string | null;
	currencyCode: string | null;
	amount: number | null;
	expectedValueDate: Date | null;
	checkNumber: string | null;
	description: string | null;
	class: CashFlowDetail | null;
	type: CashFlowDetail | null;
	subtype: CashFlowDetail | null;
	glDetail: GeneralLedgerDetail | null;
	glCode: string | null;
	flowDirection: string;
	secondaryFlowDirection: string;
	isForecastModelExcluded: boolean;
	endToEndId: string | null;
	instructionalId: string | null;
	sourceType: string;
	accountBookingFxRate: number | null;
	reportingAmount: number | null;
	reportingCurrencyCode: string | null;
	actualValueDate: Date | null;
};

export type ProjectedTransactionDrawerProps = {
	isOpen: boolean;
	projectedTransaction?: ProjectedTransaction;
	onClose: () => void;
	onSubmit?: (id: string | undefined) => void;
};

export const ProjectedTransactionDrawer: FC<ProjectedTransactionDrawerProps> =
	observer(
		({ isOpen, projectedTransaction, onClose, onSubmit: onDrawerSubmit }) => {
			const theme = useTheme();
			const { cash4 } = useUser();
			const { customerApiClient } = useClients();
			const { fetch } = useReferenceDataFetcher();
			const { enqueueSnackbar } = useSnackbar();
			const {
				categories,
				types,
				subtypes,
				glCodes,
				isLoadingGlCodes,
				refetchGlCodes,
				projectedTransactionSourceDefinitions,
			} = useDataContext();

			//#region Queries

			const { isLoading: loadingParties, data: parties } = usePartiesQuery();
			const { data: configurations } = useCash4ConfigurationsQuery();

			const getCurrencyCodes = useCallback(async () => {
				const response = await fetch({
					referenceListNames: ['FunctionalCurrency'],
				});

				return (
					response?.referenceLists['FunctionalCurrency'].map((x) => x.value) ??
					[]
				);
			}, [fetch]);
			const { loading: loadingCurrencyCodes, data: currencyCodes } =
				useT4Query(getCurrencyCodes);

			//#endregion

			//#region State

			const [showSecondaryParties, setShowSecondaryParties] =
				useState<boolean>(false);

			const getDefaultValues = useCallback(
				(projectedTransaction?: ProjectedTransaction) => {
					let values: ProjectedTransactionForm = {
						primaryPartyType: 'Entity',
						primaryPartyObject: null,
						primaryPartyAccount: null,
						secondaryPartyType: null,
						secondaryPartyObject: null,
						secondaryPartyAccount: null,
						label: null,
						currencyCode: 'USD',
						amount: null,
						expectedValueDate: null,
						checkNumber: null,
						description: null,
						class: null,
						type: null,
						subtype: null,
						glDetail: null,
						glCode: null,
						flowDirection: 'Inflow',
						secondaryFlowDirection: 'Outflow',
						isForecastModelExcluded: false,
						endToEndId: null,
						instructionalId: null,
						sourceType: 'Cash4-Manual',
						accountBookingFxRate: null,
						reportingAmount: null,
						reportingCurrencyCode:
							configurations?.reportingCurrencyCode ?? null,
						actualValueDate: null,
					};

					if (projectedTransaction) {
						values.primaryPartyType = projectedTransaction.primaryParty.type;
						values.primaryPartyObject = projectedTransaction.primaryParty.object
							? {
									id: projectedTransaction.primaryParty.object.id,
									type: projectedTransaction.primaryParty.type,
									name: projectedTransaction.primaryParty.object.name,
									code: '',
									accounts: parties?.filter(
										(x) =>
											x.id === projectedTransaction.primaryParty?.object?.id,
									)[0]?.accounts,
							  }
							: null;
						values.primaryPartyAccount = projectedTransaction.primaryParty
							.account
							? {
									id: projectedTransaction.primaryParty.account.id,
									number: projectedTransaction.primaryParty.account.number,
									name: projectedTransaction.primaryParty.account.name,
							  }
							: null;
						values.secondaryPartyType =
							projectedTransaction.secondaryParty?.type ?? null;
						if (values.secondaryPartyType) {
							setShowSecondaryParties(true);
						}
						values.secondaryPartyObject = projectedTransaction.secondaryParty
							?.object
							? {
									id: projectedTransaction.secondaryParty.object.id,
									type: projectedTransaction.secondaryParty.type,
									name: projectedTransaction.secondaryParty.object.name,
									code: '',
									accounts: parties?.filter(
										(x) =>
											x.id === projectedTransaction.secondaryParty?.object?.id,
									)[0]?.accounts,
							  }
							: null;
						values.secondaryPartyAccount = projectedTransaction.secondaryParty
							?.account
							? {
									id: projectedTransaction.secondaryParty.account.id,
									number: projectedTransaction.secondaryParty.account.number,
									name: projectedTransaction.secondaryParty.account.name,
							  }
							: null;
						values.label = projectedTransaction.label ?? null;
						values.currencyCode = projectedTransaction.currencyCode;
						values.amount = Math.abs(
							projectedTransaction.amount.accountCurrencyAmount,
						);
						values.expectedValueDate = convertDate(
							projectedTransaction.expectedValueDate,
						)!.toDate();
						values.checkNumber = projectedTransaction.checkNumber ?? null;
						values.description = projectedTransaction.description ?? null;
						values.class = projectedTransaction.categorization?.class
							? {
									id: projectedTransaction.categorization.class.id,
									code: projectedTransaction.categorization.class.code,
									name: projectedTransaction.categorization.class.name,
							  }
							: null;
						values.type = projectedTransaction.categorization?.type
							? {
									id: projectedTransaction.categorization.type.id,
									code: projectedTransaction.categorization.type.code,
									name: projectedTransaction.categorization.type.name,
							  }
							: null;
						values.subtype = projectedTransaction.categorization?.subtype
							? {
									id: projectedTransaction.categorization.subtype.id,
									code: projectedTransaction.categorization.subtype.code,
									name: projectedTransaction.categorization.subtype.name,
							  }
							: null;
						values.glDetail = projectedTransaction.categorization?.glCode
							? {
									id: projectedTransaction.categorization.glCode.id,
									code: projectedTransaction.categorization.glCode.code,
							  }
							: null;
						values.glCode =
							projectedTransaction.categorization?.glCode?.code ?? null;
						values.flowDirection = projectedTransaction.flowDirection;
						values.isForecastModelExcluded =
							projectedTransaction.isForecastModelExcluded;
						values.endToEndId = projectedTransaction.endToEndId ?? null;
						values.instructionalId =
							projectedTransaction.instructionalId ?? null;

						values.sourceType =
							projectedTransaction.source.sourceType ?? 'Cash4-Manual';

						values.reportingAmount =
							projectedTransaction.amount.reportingCurrencyAmount ?? null;
						values.actualValueDate = !!projectedTransaction.actualValueDate
							? convertDate(projectedTransaction.actualValueDate)!.toDate()
							: null;
						values.accountBookingFxRate =
							projectedTransaction.amount.accountBookingFxRate ?? null;
					}

					return values;
				},
				[configurations?.reportingCurrencyCode, parties],
			);

			const defaultValues = useMemo<ProjectedTransactionForm>(() => {
				return getDefaultValues(projectedTransaction);
			}, [getDefaultValues, projectedTransaction]);

			const { handleSubmit, control, reset, formState, watch, setValue } =
				useForm<ProjectedTransactionForm>({
					defaultValues: defaultValues,
				});

			useEffect(() => {
				if (isOpen) reset(defaultValues);
			}, [isOpen, defaultValues, reset]);

			const primaryPartyType = watch('primaryPartyType');
			const primaryPartyObject = watch('primaryPartyObject');
			const primaryPartyAccount = watch('primaryPartyAccount');
			const secondaryPartyType = watch('secondaryPartyType');
			const secondaryPartyObject = watch('secondaryPartyObject');
			const secondaryPartyAccount = watch('secondaryPartyAccount');
			const amount = watch('amount');
			const currencyCode = watch('currencyCode');
			const expectedValueDate = watch('expectedValueDate');
			const classValue = watch('class');
			const typeValue = watch('type');
			const subtypeValue = watch('subtype');
			const glDetail = watch('glDetail');
			const glCode = watch('glCode');
			const flowDirection = watch('flowDirection');
			const secondaryFlowDirection = watch('secondaryFlowDirection');
			const isForecastModelExcluded = watch('isForecastModelExcluded');
			const sourceType = watch('sourceType');
			const reportingCurrencyCode = watch('reportingCurrencyCode');

			const [isLoading, setIsLoading] = useState<boolean>(false);
			const [isCancellationModalOpen, setIsCancellationModalOpen] =
				useState<boolean>(false);
			const [isAmountInfoModalOpen, setIsAmountInfoModalOpen] =
				useState<boolean>(false);
			const [
				isForecastModelExcludedModalOpen,
				setIsForecastModelExcludedModalOpen,
			] = useState<boolean>(false);

			const cashFlowClasses = useMemo<CashFlowDetail[]>(() => {
				return categories.map((x) => ({
					id: x.id,
					code: x.code,
					name: x.name,
				}));
			}, [categories]);

			const cashFlowTypes = useMemo<CashFlowDetail[]>(() => {
				if (classValue) {
					return types(classValue.id)?.map((x) => ({
						id: x.id,
						code: x.code,
						name: x.name,
					}));
				}

				return [];
			}, [classValue, types]);

			const cashFlowSubtypes = useMemo(() => {
				if (typeValue) {
					return subtypes(typeValue.id)?.map((x) => ({
						id: x.id,
						code: x.code,
						name: x.name,
					}));
				}

				return [];
			}, [typeValue, subtypes]);

			const mappedGlCodes = useMemo<GeneralLedgerDetail[]>(() => {
				return glCodes.map((x) => ({
					id: x.id!,
					code: x.code!,
				}));
			}, [glCodes]);

			const canSave = useMemo(
				() =>
					primaryPartyType &&
					(primaryPartyAccount === null ||
						primaryPartyAccount?.id !== secondaryPartyAccount?.id) &&
					primaryPartyObject !== null &&
					primaryPartyAccount !== null &&
					(secondaryPartyType !== 'Entity' ||
						(secondaryPartyObject !== null &&
							secondaryPartyAccount !== null)) &&
					amount !== null &&
					amount >= 0 &&
					currencyCode !== null &&
					expectedValueDate !== null &&
					moment(expectedValueDate).isValid() &&
					((classValue !== null && typeValue !== null) ||
						(classValue === null && typeValue === null)) &&
					flowDirection !== null &&
					formState.isDirty,
				[
					primaryPartyType,
					primaryPartyAccount,
					secondaryPartyAccount,
					primaryPartyObject,
					secondaryPartyType,
					secondaryPartyObject,
					amount,
					currencyCode,
					expectedValueDate,
					classValue,
					typeValue,
					flowDirection,
					formState.isDirty,
				],
			);

			const actionType = useMemo(() => {
				return projectedTransaction ? 'update' : 'create';
			}, [projectedTransaction]);

			const handleDrawerClose = useCallback(() => {
				reset(getDefaultValues());
				onClose();
				setShowSecondaryParties(false);
				setIsLoading(false);
			}, [getDefaultValues, onClose, reset]);

			const isFieldEditable = (fieldName: string) => {
				if (projectedTransactionSourceDefinitions) {
					//look at each source defition and see if it matches the source type
					for (const key in projectedTransactionSourceDefinitions) {
						if (
							projectedTransactionSourceDefinitions[
								key as keyof typeof projectedTransactionSourceDefinitions
							].sourceName.toUpperCase() === sourceType.toUpperCase()
						) {
							var sourceDefinition =
								projectedTransactionSourceDefinitions[
									key as keyof typeof projectedTransactionSourceDefinitions
								];
							//if we have a source definition we want to find out if the field is editable
							if (sourceDefinition && sourceDefinition.hasRestrictedFields) {
								var res = sourceDefinition.editableFields
									.map((x) => x.toUpperCase())
									.includes(fieldName.toUpperCase());
								return res;
							}
						}
					}
					//if we get here we want to allow editing
					return true;
				}
			};

			const isFieldEditableWarning = (isEditable: boolean | undefined) => {
				switch (sourceType.toLowerCase()) {
					case 'payments4':
						return !isEditable
							? 'This information is related to a submitted payment and cannot be modified.'
							: '';

					default:
						return '';
				}
			};

			const isAccountBookingFxRateDisabled = useCallback(() => {
				if (!currencyCode || !reportingCurrencyCode) {
					return true;
				}
				if (currencyCode === reportingCurrencyCode) {
					return true;
				}
				return false;
			}, [currencyCode, reportingCurrencyCode]);

			const onSubmit = useCallback(
				async (data: ProjectedTransactionForm) => {
					if (canSave) {
						let id: string | undefined;

						try {
							setIsLoading(true);

							const reqeustData: ProjectedTransactionReq = {
								amount: data.amount ?? 0,
								currencyCode: data.currencyCode!,
								expectedValueDate: data
									.expectedValueDate!.toISOString()
									.split('T')?.[0],
								label: data.label ?? undefined,
								description: data.description ?? undefined,
								checkNumber: data.checkNumber ?? undefined,
								categorization:
									data.class && data.type
										? {
												classId: data.class?.id,
												typeId: data.type?.id,
												subtypeId: data.subtype?.id,
												glCode: data.glDetail
													? data.glDetail
													: {
															id: undefined,
															code: data.glCode ?? undefined,
													  },
										  }
										: undefined,
								primaryParty: {
									partyType: 'Entity',
									entityId: data.primaryPartyObject?.id,
									accountId: data.primaryPartyAccount?.id,
								},
								secondaryParty: !!data.secondaryPartyType
									? {
											partyType: data.secondaryPartyType ?? '',
											entityId: data.secondaryPartyObject?.id,
											accountId: data.secondaryPartyAccount?.id,
									  }
									: undefined,
								flowDirection: data.flowDirection,
								isForecastModelExcluded: data.isForecastModelExcluded,

								endToEndId: data.endToEndId ?? undefined,
								instructionalId: data.instructionalId ?? undefined,
								source: { sourceType: data.sourceType },
								accountBookingFxRate: data.accountBookingFxRate ?? undefined,
								reportingCurrencyCode: data.reportingCurrencyCode ?? undefined,
							};

							let response:
								| AxiosResponse<T4DataResponse<string>, ProjectedTransactionReq>
								| undefined;
							if (projectedTransaction) {
								response = await customerApiClient.api.cash4.updateProjected(
									projectedTransaction?.id,
									reqeustData,
								);
							} else {
								response = await customerApiClient.api.cash4.createProjected(
									reqeustData,
								);

								id = response?.data as any as string; // todo c4: api is messed up
							}

							if (
								response.data.success !== undefined &&
								!response.data.success
							) {
								throw new Error('Failed response.');
							} else {
								if (!projectedTransaction) {
									reset(defaultValues);
								}
							}

							handleDrawerClose();
							onDrawerSubmit?.(id);
							enqueueSnackbar(
								`Projected transaction ${actionType}d successfully!`,
								{
									variant: 'success',
								},
							);
						} catch (error) {
							enqueueSnackbar(
								`An unexpected error occurred and we were unable to ${actionType} projected transaction. Please try again later.`,
								{
									variant: 'error',
								},
							);
						} finally {
							refetchGlCodes();
							setIsLoading(false);
						}
					}
				},
				[
					canSave,
					projectedTransaction,
					handleDrawerClose,
					onDrawerSubmit,
					enqueueSnackbar,
					actionType,
					customerApiClient.api.cash4,
					reset,
					defaultValues,
					refetchGlCodes,
				],
			);

			const handleAddSecondaryPartyButtonClick = useCallback(() => {
				if (!showSecondaryParties) {
					defaultValues.secondaryPartyType = 'Entity';
				} else {
					defaultValues.secondaryPartyType = null;
				}
				defaultValues.secondaryPartyObject = null;
				defaultValues.secondaryPartyAccount = null;
				setValue('secondaryPartyType', defaultValues.secondaryPartyType, {
					shouldDirty: true,
				});
				setValue('secondaryPartyObject', defaultValues.secondaryPartyObject, {
					shouldDirty: true,
				});
				setValue('secondaryPartyAccount', defaultValues.secondaryPartyAccount, {
					shouldDirty: true,
				});

				setShowSecondaryParties(!showSecondaryParties);
			}, [defaultValues, setValue, showSecondaryParties]);

			// #endregion

			return (
				<T4DrawerBase
					open={isOpen}
					initializing={loadingParties || loadingCurrencyCodes}
					loading={isLoading}
					title={`${
						projectedTransaction ? 'Edit' : 'Create'
					} Projected Transaction`}
					onClose={() => {
						if (formState.isDirty) {
							setIsCancellationModalOpen(true);
						} else {
							handleDrawerClose();
						}
					}}
					actions={[
						<DrawerCancelButton
							stonlyId={stonlyIds.cancelButton}
							onCancel={() => {
								if (formState.isDirty) {
									setIsCancellationModalOpen(true);
								} else {
									handleDrawerClose();
								}
							}}
						/>,
						<DrawerSubmitButton
							stonlyId={stonlyIds.submitButton}
							label={projectedTransaction ? 'Save' : 'Create'}
							onSubmit={handleSubmit(onSubmit)}
							disabled={!canSave || isLoading}
						/>,
					]}
					disableNavigationCollapse
				>
					<Grid container item xs={12} spacing={1}>
						<Grid
							container
							item
							xs={12}
							spacing={1}
							{...stonlyData({ id: stonlyIds.primaryPartySection })}
						>
							{/* Primary Party */}
							<Grid container item xs={12}>
								<Accordion
									defaultExpanded
									sx={{
										height: 'fit-content !important',
										width: '100%',
										borderRadius: '4px',
										marginTop: '0px !important',
									}}
								>
									<AccordionSummary expandIcon={<ExpandMore />}>
										<Typography variant="h4">Account Information</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Grid spacing={1} container>
											<Grid item xs={12}>
												<Controller
													name="flowDirection"
													control={control}
													render={({ field: { onChange, value } }) => (
														<Tooltip
															title={isFieldEditableWarning(
																isFieldEditable('flowDirection'),
															)}
														>
															<ToggleButtonGroup
																disabled={!isFieldEditable('flowDirection')}
																color="primary"
																value={flowDirection}
																exclusive
																onChange={(_, newValue) => {
																	if (newValue) {
																		onChange(newValue);
																		if (newValue !== value) {
																			setValue('flowDirection', newValue);
																			setValue(
																				'secondaryFlowDirection',
																				newValue === 'Inflow'
																					? 'Outflow'
																					: 'Inflow',
																			);
																		}
																	}
																}}
																size="small"
																aria-label="Flow Direction"
																style={{ alignItems: 'center' }}
																{...stonlyData({
																	id: stonlyIds.flowDirectionBtnGroup,
																})}
															>
																<ToggleButton
																	value="Inflow"
																	sx={{
																		'&.Mui-selected': {
																			backgroundColor:
																				theme.palette.primary.main,
																			color: theme.palette.primary.contrastText,
																		},
																		'&.Mui-selected:hover': {
																			backgroundColor:
																				theme.palette.primary.light,
																			color: theme.palette.primary.contrastText,
																		},
																	}}
																>
																	Inflow
																</ToggleButton>
																<ToggleButton
																	value="Outflow"
																	sx={{
																		'&.Mui-selected': {
																			backgroundColor:
																				theme.palette.primary.main,
																			color: theme.palette.primary.contrastText,
																		},
																		'&.Mui-selected:hover': {
																			backgroundColor:
																				theme.palette.primary.light,
																			color: theme.palette.primary.contrastText,
																		},
																	}}
																>
																	Outflow
																</ToggleButton>
															</ToggleButtonGroup>
														</Tooltip>
													)}
												/>
											</Grid>
											<Grid item xs={12}>
												<Controller
													name="primaryPartyObject"
													control={control}
													render={({
														field: { onChange, value },
														fieldState: { error },
													}) => (
														<Tooltip
															title={isFieldEditableWarning(
																isFieldEditable('primaryPartyObject'),
															)}
														>
															<T4Autocomplete<Party>
																disabled={
																	!isFieldEditable('primaryPartyObject')
																}
																id="primaryParty"
																loading={loadingParties}
																label="Entity"
																options={
																	parties
																		?.filter(
																			(x) =>
																				x.type === primaryPartyType &&
																				(x.accounts?.length ?? 0) > 0,
																		)
																		?.sort((a, b) =>
																			(a.name ?? '').localeCompare(
																				b.name ?? '',
																			),
																		) ?? []
																}
																value={primaryPartyObject ?? null}
																onChange={(_, newValue) => {
																	onChange(newValue ?? null);
																	if (value?.id !== newValue?.id) {
																		setValue('primaryPartyAccount', null);
																	}
																}}
																isOptionEqualToValue={(option, value) =>
																	option?.id === value?.id
																}
																getOptionLabel={(option) => option.name}
																error={!!error}
																helperText={error && error.message}
																required={true}
																readOnly={!primaryPartyType}
															/>
														</Tooltip>
													)}
												/>
											</Grid>
											<Grid item xs={12}>
												<Controller
													name="primaryPartyAccount"
													control={control}
													render={({
														field: { onChange, value },
														fieldState: { error },
													}) => (
														<Tooltip
															title={isFieldEditableWarning(
																isFieldEditable('primaryPartyAccount'),
															)}
														>
															<T4Autocomplete<PartyAccount>
																disabled={
																	!isFieldEditable('primaryPartyAccount')
																}
																id="primaryParty-account"
																loading={loadingParties}
																label="Account"
																options={
																	primaryPartyObject?.accounts?.filter(
																		(x) => x.id !== secondaryPartyAccount?.id,
																	) ?? []
																}
																value={primaryPartyAccount ?? null}
																onChange={(_, newValue) => {
																	onChange(newValue);
																	if (value?.id !== newValue?.id) {
																		setValue(
																			'currencyCode',
																			newValue?.currencyCode ?? 'USD',
																		);
																	}
																}}
																isOptionEqualToValue={(option, value) =>
																	option?.id === value?.id
																}
																getOptionLabel={(option) => option.name}
																error={!!error}
																helperText={error && error.message}
																required={true}
																readOnly={!primaryPartyObject}
															/>
														</Tooltip>
													)}
												/>
											</Grid>
											<Grid item xs={12}>
												<Typography variant="h4">Categorization</Typography>
											</Grid>
											<Grid item xs={12}>
												<Controller
													name="class"
													control={control}
													render={({
														field: { onChange, value },
														fieldState: { error },
													}) => (
														<Tooltip
															title={isFieldEditableWarning(
																isFieldEditable('cashFlowClass'),
															)}
														>
															<T4Autocomplete<CashFlowDetail>
																disabled={!isFieldEditable('cashFlowClass')}
																id="cash-flow-class"
																label="Cash Flow Class (CFC)"
																options={cashFlowClasses}
																value={classValue ?? null}
																onChange={(_, newValue) => {
																	if (value?.id !== newValue?.id) {
																		onChange(newValue ?? null);
																		setValue('type', null);
																		setValue('subtype', null);
																	}

																	if (
																		newValue === null ||
																		newValue === undefined
																	) {
																		setValue('glDetail', null);
																		setValue('glCode', null);
																	}
																}}
																isOptionEqualToValue={(option, value) =>
																	option.id === value.id
																}
																getOptionLabel={(option) => option.name}
																error={!!error}
																helperText={error && error.message}
																required={!!classValue}
															/>
														</Tooltip>
													)}
												/>
											</Grid>
											<Grid item xs={12}>
												<Controller
													name="type"
													control={control}
													render={({
														field: { onChange, value },
														fieldState: { error },
													}) => (
														<Tooltip
															title={isFieldEditableWarning(
																isFieldEditable('cashFlowType'),
															)}
														>
															<T4Autocomplete<CashFlowDetail>
																disabled={!isFieldEditable('cashFlowType')}
																id="cash-flow-type"
																label="Cash Flow Type (CFT)"
																options={cashFlowTypes}
																value={typeValue ?? null}
																onChange={(_, newValue) => {
																	if (newValue?.id !== value?.id) {
																		onChange(newValue ?? null);
																		setValue('subtype', null);
																	}

																	if (newValue === null || newValue === null) {
																		setValue('glDetail', null);
																		setValue('glCode', null);
																	}
																}}
																isOptionEqualToValue={(option, value) =>
																	option.id === value.id
																}
																getOptionLabel={(option) => option.name}
																error={!!error}
																helperText={error && error.message}
																readOnly={!classValue}
																required={!!classValue}
															/>
														</Tooltip>
													)}
												/>
											</Grid>
											<Grid item xs={12}>
												<Controller
													name="subtype"
													control={control}
													render={({
														field: { onChange },
														fieldState: { error },
													}) => (
														<Tooltip
															title={isFieldEditableWarning(
																isFieldEditable('cashFlowSubtype'),
															)}
														>
															<T4Autocomplete<CashFlowDetail>
																disabled={!isFieldEditable('cashFlowSubtype')}
																id="cash-flow-subtype"
																label="Cash Flow Subtype (CFST)"
																options={cashFlowSubtypes}
																value={subtypeValue ?? null}
																onChange={(_, newValue) =>
																	onChange(newValue ?? null)
																}
																isOptionEqualToValue={(option, value) =>
																	option.id === value.id
																}
																getOptionLabel={(option) => option.name}
																error={!!error}
																helperText={error && error.message}
																readOnly={!typeValue}
															/>
														</Tooltip>
													)}
												/>
											</Grid>
											<Grid item xs={12}>
												<Controller
													name="glCode"
													control={control}
													render={({ field: { onChange } }) => (
														<Tooltip
															title={isFieldEditableWarning(
																isFieldEditable('glCode'),
															)}
														>
															<T4Autocomplete<
																GeneralLedgerDetail,
																false,
																false,
																true
															>
																disabled={!isFieldEditable('glCode')}
																loading={isLoadingGlCodes}
																label="GL Code"
																options={mappedGlCodes}
																isOptionEqualToValue={(option, value) =>
																	option?.id === value?.id
																}
																getOptionLabel={(option) =>
																	typeof option === 'string'
																		? option
																		: option.code!
																}
																value={glDetail ?? glCode ?? null}
																inputValue={glCode ?? ''}
																onInputChange={(_, value) => {
																	const foundGlCode = mappedGlCodes?.find(
																		(x) => x.code === value,
																	);
																	if (foundGlCode) {
																		onChange(foundGlCode.code ?? null);
																		setValue('glDetail', foundGlCode ?? null);
																	} else {
																		onChange(value ?? null);
																		setValue('glDetail', null);
																	}
																}}
																onChange={(_, value) => {
																	if (typeof value !== 'string') {
																		onChange(null);
																		setValue('glDetail', value ?? null);
																	}
																}}
																readOnly={!typeValue}
																freeSolo
																autoSelect
																autoHighlight={false}
															/>
														</Tooltip>
													)}
												/>
											</Grid>
										</Grid>
									</AccordionDetails>
								</Accordion>
							</Grid>
							{/* Details */}
							<Grid
								container
								item
								xs={12}
								{...stonlyData({ id: stonlyIds.secondaryPartySection })}
							>
								<Paper
									variant="outlined"
									sx={{
										padding: '8px 16px',
										marginTop: '0px !important',
										height: '100% !important',
									}}
								>
									<Grid
										container
										item
										xs={12}
										spacing={1}
										{...stonlyData({ id: stonlyIds.detailsSection })}
									>
										<Grid item xs={12}>
											<Typography variant="h4">Details</Typography>
										</Grid>
										<Grid item xs={12}>
											<Controller
												name="label"
												control={control}
												render={({
													field: { onChange, value },
													fieldState: { error },
												}) => (
													<Tooltip
														title={isFieldEditableWarning(
															isFieldEditable('label'),
														)}
													>
														<WrappedT4TextFieldV2
															disabled={!isFieldEditable('label')}
															id="projected-item-label"
															label="Label"
															value={value ?? ''}
															onChange={(value: string) => {
																if (
																	isStringUndefinedOrNullOrWhitespace(value)
																) {
																	onChange(null);
																} else {
																	onChange(value);
																}
															}}
															error={!!error}
														/>
													</Tooltip>
												)}
											/>
										</Grid>
										<Grid item xs={6}>
											<Controller
												name="amount"
												control={control}
												render={({
													field: { onChange, value },
													fieldState: { error },
												}) => (
													<Tooltip
														title={isFieldEditableWarning(
															isFieldEditable('amount'),
														)}
													>
														<span>
															<T4NumberInput
																disabled={!isFieldEditable('amount')}
																id="projected-item-amount"
																label="Transaction Amount"
																min={0}
																value={value === null ? null : Math.abs(value)}
																onValueChange={({ floatValue }) => {
																	onChange(floatValue);
																	// calculateReportedAmount(
																	// 	'amount',
																	// 	parseFloat(event.target.value),
																	// );
																}}
																thousandSeparator={false}
																error={!!error}
																required
																InputProps={{
																	startAdornment: (
																		<InputAdornment position="start">
																			<IconButton
																				onClick={() => {
																					setIsAmountInfoModalOpen(true);
																				}}
																			>
																				<InfoOutlinedIcon />
																			</IconButton>
																		</InputAdornment>
																	),
																}}
															/>
														</span>
													</Tooltip>
												)}
											/>
										</Grid>
										<Grid item xs={6}>
											<Controller
												name="currencyCode"
												control={control}
												render={({
													field: { onChange },
													fieldState: { error },
												}) => (
													<Tooltip
														title={isFieldEditableWarning(
															isFieldEditable('currencyCode'),
														)}
													>
														<T4Autocomplete<string, false, true>
															disabled={!isFieldEditable('currencyCode')}
															id="currency-code"
															loading={loadingCurrencyCodes}
															label="Transaction Currency"
															options={currencyCodes ?? []}
															value={currencyCode ?? ''}
															onChange={(_, value) => {
																onChange(value ?? null);
																if (value ?? 'USD' === reportingCurrencyCode) {
																	setValue('accountBookingFxRate', null);
																	setValue('reportingAmount', null);
																}
															}}
															error={!!error}
															helperText={error && error.message}
															disableClearable
															required
														/>
													</Tooltip>
												)}
											/>
										</Grid>

										<Grid item xs={12}>
											<Controller
												name="accountBookingFxRate"
												control={control}
												render={({
													field: { onChange, value },
													fieldState: { error },
												}) => (
													<T4NumberInput
														disabled={isAccountBookingFxRateDisabled()}
														id="projected-item-accounting-booking-fx-rate"
														label="Accounting Booking FX Rate"
														min={0}
														value={value === null ? null : Math.abs(value)}
														onValueChange={({ floatValue }) => {
															onChange(floatValue ?? null);
															// calculateReportedAmount(
															// 	'accountBookingFxRate',
															// 	parseFloat(event.target.value),
															// );
														}}
														thousandSeparator={false}
														decimalScale={8}
														error={!!error}
													/>
												)}
											/>
										</Grid>

										<Grid item xs={6}>
											<Controller
												name="reportingAmount"
												control={control}
												render={({
													field: { value },
													fieldState: { error },
												}) => (
													<Tooltip title="When saved, the Reporting Amount is calculated using the provided Accounting Booking FX Rate or system FX rates if none is provided. This value cannot be manually edited.">
														<span>
															<T4NumberInput
																disabled
																id="projected-item-reporting-amount"
																label="Reporting Amount"
																value={value === null ? null : Math.abs(value)}
																error={!!error}
																decimalScale={2}
															/>
														</span>
													</Tooltip>
												)}
											/>
										</Grid>
										<Grid item xs={6}>
											<Controller
												name="reportingCurrencyCode"
												control={control}
												render={({
													field: { value },
													fieldState: { error },
												}) => (
													<WrappedT4TextFieldV2
														disabled
														id="reporting-currency-code"
														label="Reporting Currency"
														value={value ?? ''}
														error={!!error}
													/>
												)}
											/>
										</Grid>

										<Grid item xs={12}>
											<Divider sx={{ mt: 2, mb: 2 }} />
											<Controller
												name="expectedValueDate"
												control={control}
												render={({ field: { onChange, value } }) => (
													<Tooltip
														title={isFieldEditableWarning(
															isFieldEditable('expectedValueDate'),
														)}
													>
														<span>
															<T4DateField
																disabled={!isFieldEditable('expectedValueDate')}
																id="projected-item-expected-value-date"
																label="Expected Value Date"
																value={value ? moment(value) : null}
																onChange={(value) =>
																	onChange(value?.toDate() ?? null)
																}
																required
															/>
														</span>
													</Tooltip>
												)}
											/>
										</Grid>

										{!!projectedTransaction && (
											<Grid item xs={12}>
												<Controller
													name="actualValueDate"
													control={control}
													render={({ field: { value } }) => (
														<T4DateField
															disabled
															id="projected-item-expected-value-date"
															label="Actual Value Date"
															value={value ? moment(value) : null}
														/>
													)}
												/>
											</Grid>
										)}
										<Grid item xs={12}>
											<Divider sx={{ mt: 2, mb: 2 }} />
											<Controller
												name="checkNumber"
												control={control}
												render={({
													field: { onChange, value },
													fieldState: { error },
												}) => (
													<Tooltip
														title={isFieldEditableWarning(
															isFieldEditable('checkNumber'),
														)}
													>
														<WrappedT4TextFieldV2
															disabled={!isFieldEditable('checkNumber')}
															id="check-number"
															label="Check Number"
															value={value ?? ''}
															onChange={(value) => {
																if (isStringUndefinedOrNullOrWhitespace(value))
																	onChange(null);
																else onChange(value);
															}}
															error={!!error}
														/>
													</Tooltip>
												)}
											/>
										</Grid>
										<Grid item xs={12}>
											<Controller
												name="endToEndId"
												control={control}
												render={({
													field: { onChange, value },
													fieldState: { error },
												}) => (
													<Tooltip
														title={isFieldEditableWarning(
															isFieldEditable('endToEndId'),
														)}
													>
														<WrappedT4TextFieldV2
															disabled={!isFieldEditable('endToEndId')}
															id="end-to-end-id"
															label="End to End ID Number"
															value={value ?? ''}
															onChange={(value) => {
																if (isStringUndefinedOrNullOrWhitespace(value))
																	onChange(null);
																else onChange(value);
															}}
															error={!!error}
														/>
													</Tooltip>
												)}
											/>
										</Grid>
										<Grid item xs={12}>
											<Controller
												name="instructionalId"
												control={control}
												render={({
													field: { onChange, value },
													fieldState: { error },
												}) => (
													<Tooltip
														title={isFieldEditableWarning(
															isFieldEditable('instructionalId'),
														)}
													>
														<WrappedT4TextFieldV2
															disabled={!isFieldEditable('instructionalId')}
															id="instructional-id"
															label="Instruction ID Number"
															value={value ?? ''}
															onChange={(value) => {
																if (isStringUndefinedOrNullOrWhitespace(value))
																	onChange(null);
																else onChange(value);
															}}
															error={!!error}
														/>
													</Tooltip>
												)}
											/>
										</Grid>
										<Grid item xs={12}>
											<Controller
												name="sourceType"
												control={control}
												render={({ field: { value } }) => (
													<T4TextFieldV2
														id="sourceType"
														label="Source"
														value={value}
														disabled
													/>
												)}
											/>
										</Grid>
										<Grid item xs={12}>
											<Controller
												name="description"
												control={control}
												render={({
													field: { onChange, value },
													fieldState: { error },
												}) => (
													<Tooltip
														title={isFieldEditableWarning(
															isFieldEditable('description'),
														)}
													>
														<WrappedT4TextFieldV2
															disabled={!isFieldEditable('description')}
															id="notes"
															label="Notes"
															value={value ?? ''}
															onChange={(value: string) => {
																if (
																	isStringUndefinedOrNullOrWhitespace(value)
																) {
																	onChange(null);
																} else {
																	onChange(value);
																}
															}}
															minRows={4}
															maxRows={4}
															multiline
															error={!!error}
															helperText={`${value?.length ?? 0}/2048${
																error?.message ? ' ' + error.message : ''
															}`}
															inputProps={{
																maxLength: 2048,
															}}
														/>
													</Tooltip>
												)}
											/>
										</Grid>

										{cash4.isAuthor && (
											<Grid item xs={12}>
												<Controller
													name="isForecastModelExcluded"
													control={control}
													render={({ field: { onChange, value } }) => (
														<>
															<Tooltip
																title={isFieldEditableWarning(
																	isFieldEditable('isForecastModelExcluded'),
																)}
															>
																<span>
																	<IconButton
																		onClick={() => {
																			setIsForecastModelExcludedModalOpen(true);
																		}}
																	>
																		<InfoOutlinedIcon />
																	</IconButton>

																	<T4Checkbox
																		disabled={
																			!isFieldEditable(
																				'isForecastModelExcluded',
																			)
																		}
																		label="Exclude from forecast reports"
																		checked={isForecastModelExcluded}
																		value={isForecastModelExcluded}
																		onChange={(_, newValue) => {
																			onChange(newValue);
																			if (newValue !== value) {
																				setValue(
																					'isForecastModelExcluded',
																					newValue,
																				);
																			}
																		}}
																	/>
																</span>
															</Tooltip>
															<ModalBase
																title="Exclude from forecast reports"
																open={isForecastModelExcludedModalOpen}
																onClose={() => {
																	setIsForecastModelExcludedModalOpen(false);
																}}
															>
																Select to exclude the transaction from forecast
																model calculations. This is useful for removing
																one-time or non-recurring transactions that may
																skew future projections.
															</ModalBase>
														</>
													)}
												/>
											</Grid>
										)}
									</Grid>
								</Paper>
							</Grid>
							{/* Secondary Party */}
							<Grid item xs={12}>
								<Collapse orientation="vertical" in={!showSecondaryParties}>
									<Tooltip
										title={isFieldEditableWarning(
											isFieldEditable('secondaryPartyType'),
										)}
									>
										<span>
											<Button
												startIcon={<Add />}
												type="button"
												variant="outlined"
												color="primary"
												{...stonlyData({
													id: stonlyIds.addSecondaryPartyButton,
												})}
												onClick={handleAddSecondaryPartyButtonClick}
												disabled={!isFieldEditable('secondaryPartyType')}
											>
												Secondary Party Information
											</Button>
										</span>
									</Tooltip>
								</Collapse>
								<Collapse orientation="vertical" in={showSecondaryParties}>
									<Accordion
										defaultExpanded
										sx={{
											height: 'fit-content !important',
											width: '100%',
											borderRadius: '4px',
											marginTop: '0px !important',
										}}
									>
										<AccordionSummary expandIcon={<ExpandMore />}>
											<Typography variant="h4">
												Secondary Party Information
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Grid spacing={1} container>
												<Grid item xs={12}>
													<Tooltip
														title={isFieldEditableWarning(
															isFieldEditable('secondaryPartyType'),
														)}
													>
														<ToggleButtonGroup
															color="primary"
															value={secondaryFlowDirection}
															exclusive
															disabled
															size="small"
															aria-label="Secondary Flow Direction"
															style={{ alignItems: 'center' }}
															{...stonlyData({
																id: stonlyIds.secondaryFlowDirectionBtnGroup,
															})}
														>
															<ToggleButton
																value="Inflow"
																sx={{
																	'&.Mui-selected': {
																		backgroundColor: theme.palette.primary.main,
																		color: theme.palette.primary.contrastText,
																	},
																	'&.Mui-selected:hover': {
																		backgroundColor:
																			theme.palette.primary.light,
																		color: theme.palette.primary.contrastText,
																	},
																}}
															>
																Inflow
															</ToggleButton>
															<ToggleButton
																value="Outflow"
																sx={{
																	'&.Mui-selected': {
																		backgroundColor: theme.palette.primary.main,
																		color: theme.palette.primary.contrastText,
																	},
																	'&.Mui-selected:hover': {
																		backgroundColor:
																			theme.palette.primary.light,
																		color: theme.palette.primary.contrastText,
																	},
																}}
															>
																Outflow
															</ToggleButton>
														</ToggleButtonGroup>
													</Tooltip>
												</Grid>
												<Grid item xs={12}>
													<Controller
														name="secondaryPartyType"
														control={control}
														render={({
															field: { onChange, value },
															fieldState: { error },
														}) => (
															<Tooltip
																title={isFieldEditableWarning(
																	isFieldEditable('secondaryPartyType'),
																)}
															>
																<T4Autocomplete<string>
																	disabled={
																		!isFieldEditable('secondaryPartyType')
																	}
																	id="secondaryPartyType"
																	label="Secondary Party Type"
																	options={[
																		'Entity',
																		'Partner',
																		'Counterparty',
																	]}
																	value={secondaryPartyType ?? null}
																	onChange={(_, newValue) => {
																		if (value !== newValue) {
																			onChange(newValue ?? null);
																			setValue('secondaryPartyObject', null);
																			setValue('secondaryPartyAccount', null);
																		}
																	}}
																	error={!!error}
																	helperText={error && error.message}
																	required={secondaryPartyType === 'Entity'}
																/>
															</Tooltip>
														)}
													/>
												</Grid>
												<Grid item xs={12}>
													<Controller
														name="secondaryPartyObject"
														control={control}
														render={({
															field: { onChange, value },
															fieldState: { error },
														}) => (
															<Tooltip
																title={isFieldEditableWarning(
																	isFieldEditable('secondaryPartyObject'),
																)}
															>
																<T4Autocomplete<Party>
																	disabled={
																		!isFieldEditable('secondaryPartyObject')
																	}
																	id="secondaryParty"
																	loading={loadingParties}
																	label="Entity"
																	options={
																		parties
																			?.filter(
																				(x) =>
																					x.type === secondaryPartyType &&
																					(x.type !== 'Entity' ||
																						(x.accounts?.length ?? 0) > 0),
																			)
																			?.sort((a, b) =>
																				(a.name ?? '').localeCompare(
																					b.name ?? '',
																				),
																			) ?? []
																	}
																	value={secondaryPartyObject ?? null}
																	onChange={(_, newValue) => {
																		onChange(newValue);
																		if (value?.id !== newValue?.id) {
																			setValue('secondaryPartyAccount', null);
																		}
																	}}
																	isOptionEqualToValue={(option, value) =>
																		option?.id === value?.id
																	}
																	getOptionLabel={(option) => option.name}
																	error={!!error}
																	helperText={error && error.message}
																	required={secondaryPartyType === 'Entity'}
																	readOnly={!secondaryPartyType}
																/>
															</Tooltip>
														)}
													/>
												</Grid>
												<Grid item xs={12}>
													<Controller
														name="secondaryPartyAccount"
														control={control}
														render={({
															field: { onChange },
															fieldState: { error },
														}) => (
															<Tooltip
																title={isFieldEditableWarning(
																	isFieldEditable('secondaryPartyAccount'),
																)}
															>
																<T4Autocomplete<PartyAccount>
																	disabled={
																		!isFieldEditable('secondaryPartyAccount')
																	}
																	id="secondaryParty-account"
																	loading={loadingParties}
																	label="Account"
																	options={
																		secondaryPartyObject?.accounts?.filter(
																			(x) => x.id !== primaryPartyAccount?.id,
																		) ?? []
																	}
																	value={secondaryPartyAccount ?? null}
																	onChange={(_, newValue) => {
																		onChange(newValue);
																	}}
																	isOptionEqualToValue={(option, value) =>
																		option?.id === value?.id
																	}
																	getOptionLabel={(option) => option.name}
																	error={!!error}
																	helperText={error && error.message}
																	required={secondaryPartyType === 'Entity'}
																	readOnly={!secondaryPartyObject}
																/>
															</Tooltip>
														)}
													/>
												</Grid>
												<Grid item xs={12}>
													<Tooltip
														title={isFieldEditableWarning(
															isFieldEditable('secondaryPartyAccount'),
														)}
													>
														<span>
															<Button
																disabled={
																	!isFieldEditable('secondaryPartyType')
																}
																startIcon={<DeleteOutline />}
																type="button"
																variant="outlined"
																color="error"
																onClick={handleAddSecondaryPartyButtonClick}
																{...stonlyData({
																	id: stonlyIds.removeSecondaryPartyButton,
																})}
															>
																Remove Secondary Party
															</Button>
														</span>
													</Tooltip>
												</Grid>
											</Grid>
										</AccordionDetails>
									</Accordion>
								</Collapse>
							</Grid>
						</Grid>
					</Grid>
					<CancellationModal
						isOpen={isCancellationModalOpen}
						resourceType="projected transaction"
						variant={projectedTransaction ? 'edit' : 'create'}
						onClose={() => setIsCancellationModalOpen(false)}
						onSubmit={() => handleDrawerClose()}
					/>

					<TransactionAmountInfoModal
						isOpen={isAmountInfoModalOpen}
						onCancel={() => {
							setIsAmountInfoModalOpen(false);
						}}
					/>
				</T4DrawerBase>
			);
		},
	);
