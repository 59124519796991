import { Account as E4Account } from 'modules/clients/apiGateway/entity4/accounts';
import moment, { Moment } from 'moment';

export type BalanceForm = {
	e4Account: E4Account | null;
	balanceDate: Moment | null;
	currency: string;
	openingLedgerBalance: number | null;
	openingAvailableBalance: number | null;
	closingLedgerBalance: number | null;
	closingAvailableBalance: number | null;
	note: string | null;
};

export const defaultBalanceForm: BalanceForm = {
	e4Account: null,
	balanceDate: moment().subtract(1, 'days'),
	currency: 'USD',
	openingLedgerBalance: null,
	openingAvailableBalance: null,
	closingLedgerBalance: null,
	closingAvailableBalance: null,
	note: null,
};
