import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { CancellationModal } from 'shared/components/CancellationModal';
import { FormModal } from 'shared/components/formModal';
import { TransactionRuleListItem } from '../models';
import { useCreateRule, useUpdateRule } from '../services';
import { RuleForm } from './components/RuleForm';
import { useDataContext } from './providers/DataProvider';
import { useFormContext } from './providers/FormProvider';
import { isFormValid } from './validation';

export type RuleModalProps = {
	open: boolean;
	onClose: () => void;
	rule?: TransactionRuleListItem | null | undefined;
	isEditing: boolean;
};

export const RuleModal: FC<RuleModalProps> = observer(
	({ open, onClose, rule, isEditing }) => {
		const [cancelOpen, setCancelOpen] = useState(false);
		const [isInitialised, setIsInitialised] = useState(false);
		const { initializeRule, disposeRule, getRule } = useFormContext();
		const { isLoadingCategories, categoriesError, categories } =
			useDataContext();

		const handleClose = useCallback(() => {
			onClose();
			setIsInitialised(false);
			disposeRule();
		}, [disposeRule, onClose]);

		const { mutateAsync: updateRule, isLoading: isLoadingUpdate } =
			useUpdateRule(handleClose);

		const { mutateAsync: createRule, isLoading: isLoadingCreate } =
			useCreateRule(handleClose);

		const handleCancelOpenDialog = useCallback(() => {
			setCancelOpen(true);
		}, []);

		const handleCancelCloseDialog = useCallback(() => {
			setCancelOpen(false);
		}, []);

		const handleCancel = useCallback(() => {
			handleCancelCloseDialog();
			handleClose();
		}, [handleCancelCloseDialog, handleClose]);

		const handleSubmit = useCallback(async () => {
			if (isEditing) {
				await updateRule(getRule());
			} else {
				await createRule(getRule());
			}
		}, [createRule, getRule, isEditing, updateRule]);

		useEffect(() => {
			if (rule && open === true && !isInitialised && !isLoadingCategories()) {
				initializeRule(rule);
				setIsInitialised(true);
			}
		}, [
			initializeRule,
			isInitialised,
			isLoadingCategories,
			rule,
			open,
			categories,
		]);

		return (
			<>
				<FormModal
					isDraggable={true}
					open={open}
					onClose={handleCancelOpenDialog}
					onSubmit={handleSubmit}
					submitButtonLabel={isEditing ? 'Edit Rule' : 'Create Rule'}
					title={isEditing ? 'Edit Rule' : 'Create Rule'}
					loading={
						(isLoadingUpdate || isLoadingCreate || isLoadingCategories()) &&
						!categoriesError
					}
					error={
						categoriesError
							? 'Error fetching data.  If you continue to see this error, contact our Customer Success team at support@treasury4.com.'
							: ''
					}
					fullWidth
					maxWidth="lg"
					submitDisabled={!isFormValid(getRule())}
				>
					<RuleForm isEditing={isEditing} />
				</FormModal>
				<CancellationModal
					isOpen={cancelOpen}
					resourceType="rule"
					variant={isEditing ? 'edit' : 'create'}
					onClose={handleCancelCloseDialog}
					onSubmit={handleCancel}
				/>
			</>
		);
	},
);
