import { Add, CurrencyExchange } from '@mui/icons-material';
import { Box, Grid, MenuItem, Tooltip, useTheme } from '@mui/material';
import {
	GridCellParams,
	GridColDef,
	GridRowSelectionModel,
	GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { useCash4ConfigurationsQuery } from 'features/_shared/_hooks/useCash4ConfigurationsQuery';
import { T4Alert } from 'features/entity4/shared/components/atoms/t4Alert';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { LegalEntityGroupsFilter } from 'features/entity4/shared/components/legalEntityGroupsFilter';
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { AccountGroupsFilter } from 'shared/components/accountGroupsFilter';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import T4DateRangePicker from 'shared/components/dateRangePicker/t4DateRangePicker';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { paths, validIdRegex } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import {
	getCurrencyColumnDefinition,
	getDateColumnDefinition,
	getFormattedDateTimeColumnDefinition,
	getIntegerColumnDefinition,
} from 'shared/utilities/dataGrid/columnDefinitions';
import { stonlyData } from 'stonly/functions';
import {
	CurrencySource,
	defaultCurrencyCode,
	formatCurrency,
} from 'utilities/currencyUtils';
import AmountCurrencyTooltipGridCell from '../_shared/_components/AmountCurrencyTooltipGridCell';
import { useRowSelectionCalculations } from '../_shared/_utilities/useRowSelectionCalculations';
import { useAccountIntegrationsContext } from '../accountIntegrations/providers/accountIntegrationsProviders';
import { C4AlertBold } from '../reconciliations/_components/c4AlertBold';
import { Actions, ActionsEnum } from '../shared/components/T4ActionMenu';
import { BalanceDrawer } from './_components/balanceDrawer';
import { DeleteBalanceConfirmationDialog } from './_components/deleteBalanceConfirmationDialog';
import { useBalancesDateRange } from './_hooks/useBalancesDateRange';
import {
	Balance,
	BalanceValue,
	useBalancesQuery,
} from './_hooks/useBalancesQuery';
import { CreateOrEditBalanceDrawer } from './components/createOrEditBalanceDrawer/CreateOrEditBalanceDrawer';
import { BalanceSourceType } from 'modules/clients/customer-api/src/api/v1/cash4/balances';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import {
	NOT_FOUND_MESSAGING,
	RETURN_TO_HOME,
} from 'shared/constants/cannotDisplayMessaging';

export type DisplayCurrency = 'original' | 'reporting';

export type DisplayCurrencyOptions = {
	id: DisplayCurrency;
	value: string;
};

export const stonlyIds = {
	balancesTable: 'balances',
	balancesRowContextMenu: 'balances-row-context-menu',
	balancesRowContextMenuEdit: 'balances-row-context-menu-edit',
	balancesRowContextMenuDelete: 'balances-row-context-menu-delete',
	balancesRowContextMenuView: 'balances-row-context-menu-view',
	balancesDisplayCurrencyDropdown: 'balances-display-currency-dropdown',
};

export const BalancesPageRoutes: FC = observer(() => {
	return (
		<Switch>
			<Route
				path={`${paths.cash4.balances.href}/:balanceId`.concat(validIdRegex)}
				exact
			>
				<BalancesPage />
			</Route>
			<Route
				path={
					`${paths.cash4.balances.href}/:balanceId`.concat(validIdRegex) +
					'/edit'
				}
				exact
			>
				<BalancesPage />
			</Route>
			<Route path={paths.cash4.balances.href} exact>
				<BalancesPage />
			</Route>
			<Route>
				<CannotDisplay
					headingText={NOT_FOUND_MESSAGING.HEADING}
					bodyText={NOT_FOUND_MESSAGING.BODY}
					imageSrc={NOT_FOUND_MESSAGING.IMAGE}
					buttonText={RETURN_TO_HOME}
					buttonHref={paths.cash4.href}
				/>
			</Route>
		</Switch>
	);
});

export const BalancesPage: FC = observer(() => {
	const theme = useTheme();
	const history = useHistory();
	const { balanceId: routeBalanceId } = useParams<{
		balanceId: string | undefined;
	}>();
	const { cash4 } = useUser();
	const { data: configurations } = useCash4ConfigurationsQuery();

	const { startDate, endDate, setStartDate, setEndDate } =
		useBalancesDateRange();
	const [dateRange, setDateRange] = useState<[Moment, Moment]>(() => [
		startDate,
		endDate,
	]);

	const {
		isLoading: isLoadingBalances,
		isFetching: isFetchingBalances,
		data: balances,
	} = useBalancesQuery(dateRange);

	const {
		updateCurrentDay,
		getCurrentDayRefreshStatus,
		getCurrentDayRefreshDate,
	} = useAccountIntegrationsContext();

	const [balanceIdToDelete, setBalanceIdToDelete] = useState<string>();
	const [isCreateOrEditBalanceDrawerOpen, setIsCreateOrEditBalanceDrawerOpen] =
		useState<boolean>(false);

	const [selectedReportingCurrency, setSelectedReportingCurrency] =
		useState<DisplayCurrency>('original');
	const isReportingCurrency = useMemo(() => {
		return selectedReportingCurrency === 'reporting';
	}, [selectedReportingCurrency]);

	const handleClose = useCallback(() => {
		history.push(paths.cash4.balances.href);
	}, [history]);

	const handleDeleteClick = useCallback((params: Balance) => {
		setBalanceIdToDelete(params.id);
	}, []);

	const { data: isCurrentDayRefreshEnabled } = useQuery(
		['currentDayRefreshEnabled'],
		() => getCurrentDayRefreshStatus(),
		{
			refetchOnWindowFocus: false,
			initialData: false,
		},
	);

	const { data: lastRefreshedDate } = useQuery(
		['lastRefreshedDate'],
		() => getCurrentDayRefreshDate(),
		{
			refetchOnWindowFocus: false,
			initialData: '',
		},
	);

	const columns = useMemo(() => {
		const getCurrencyValue = (balanceValue: BalanceValue) => {
			if (isReportingCurrency) {
				return balanceValue.reportingValue;
			} else {
				return balanceValue.value;
			}
		};

		let columns: GridColDef<Balance>[] = [];

		columns.push({
			field: 'actions',
			headerName: '',
			width: 25,
			renderCell: (params) => {
				return (
					<Actions
						objecttype="Balance"
						stonlyId={stonlyIds.balancesRowContextMenu}
						id="balancesId-more-menu"
						actions={[
							{
								action: ActionsEnum.view,
								callback: () =>
									history.push(`${paths.cash4.balances.href}/${params.row.id}`),
								stonlyId: stonlyIds.balancesRowContextMenuView,
							},
							...(cash4.isAuthor &&
							params.row.sourceType === BalanceSourceType.Manual
								? [
										{
											action: ActionsEnum.edit,
											isDisabled: false,
											callback: () => {
												setIsCreateOrEditBalanceDrawerOpen(true);
												history.push(
													`${paths.cash4.balances.href}/${params.row.id}/edit`,
												);
											},
											stonlyId: stonlyIds.balancesRowContextMenuEdit,
										},
								  ]
								: []),
							...(cash4.isAuthor
								? [
										{
											action: ActionsEnum.delete,
											isDisabled: false,
											callback: () => handleDeleteClick(params.row),
											stonlyId: stonlyIds.balancesRowContextMenuDelete,
										},
								  ]
								: []),
						]}
					/>
				);
			},
			resizable: false,
			sortable: false,
			disableColumnMenu: true,
			filterable: false,
			disableExport: true,
			hideable: false,
			disableReorder: true,
		});

		columns.push(
			{
				...getDateColumnDefinition(),
				field: 'statementDate',
				headerName: 'Date',
				description: 'Date',
			},
			{
				field: 'e4AccountName',
				headerName: 'Account Name',
				description: 'Account Name',
			},
			{
				field: 'e4AccountNumber',
				headerName: 'Account Number',
				description: 'Account Number',
			},
			{
				field: 'c4AccountNumber',
				headerName: 'C4 Account Number',
				description: 'C4 Account Number',
			},
			{
				field: 'entityCode',
				headerName: 'Entity Code',
				description: 'Entity Code',
			},
			{
				field: 'entityAnglicizedLegalName',
				headerName: 'Entity Name',
				description: 'Entity Name',
			},
			{
				field: 'bankCode',
				headerName: 'Counterparty Code',
				description: 'Counterparty Code',
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'openingLedgerBalance',
				headerName: 'Opening Ledger Balance',
				description: 'Opening Ledger Balance',
				valueGetter: (params) => getCurrencyValue(params.value),
				renderCell: (params) => {
					return AmountCurrencyTooltipGridCell({
						accountCurrencyAmount: params.row.openingLedgerBalance.value,
						accountCurrencyCode: params.row.openingLedgerBalance.valueCurrency,
						reportingCurrencyAmount:
							params.row.openingLedgerBalance.reportingValue,
						reportingCurrencyCode: configurations?.reportingCurrencyCode,
						displayedCurrencyCode: isReportingCurrency
							? CurrencySource.Reporting
							: CurrencySource.Account,
					});
				},
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'openingAvailableBalance',
				headerName: 'Opening Available Balance',
				description: 'Opening Available Balance',
				valueGetter: (params: GridValueGetterParams<Balance, any>) =>
					getCurrencyValue(params.value),
				renderCell: (params) => {
					return AmountCurrencyTooltipGridCell({
						accountCurrencyAmount: params.row.openingAvailableBalance.value,
						accountCurrencyCode:
							params.row.openingAvailableBalance.valueCurrency,
						reportingCurrencyAmount:
							params.row.openingAvailableBalance.reportingValue,
						reportingCurrencyCode: configurations?.reportingCurrencyCode,
						displayedCurrencyCode: isReportingCurrency
							? CurrencySource.Reporting
							: CurrencySource.Account,
					});
				},
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'currentLedgerBalance',
				headerName: 'Current Ledger Balance',
				description: 'Current Ledger Balance',
				valueGetter: (params) => getCurrencyValue(params.value),
				renderCell: (params) => {
					return AmountCurrencyTooltipGridCell({
						accountCurrencyAmount: params.row.currentLedgerBalance.value,
						accountCurrencyCode: params.row.currentLedgerBalance.valueCurrency,
						reportingCurrencyAmount:
							params.row.currentLedgerBalance.reportingValue,
						reportingCurrencyCode: configurations?.reportingCurrencyCode,
						displayedCurrencyCode: isReportingCurrency
							? CurrencySource.Reporting
							: CurrencySource.Account,
					});
				},
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'currentAvailableBalance',
				headerName: 'Current Available Balance',
				description: 'Current Available Balance',
				valueGetter: (params: GridValueGetterParams<Balance, any>) =>
					getCurrencyValue(params.value),
				renderCell: (params) => {
					return AmountCurrencyTooltipGridCell({
						accountCurrencyAmount: params.row.currentAvailableBalance.value,
						accountCurrencyCode:
							params.row.currentAvailableBalance.valueCurrency,
						reportingCurrencyAmount:
							params.row.currentAvailableBalance.reportingValue,
						reportingCurrencyCode: configurations?.reportingCurrencyCode,
						displayedCurrencyCode: isReportingCurrency
							? CurrencySource.Reporting
							: CurrencySource.Account,
					});
				},
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'closingLedgerBalance',
				headerName: 'Closing Ledger Balance',
				description: 'Closing Ledger Balance',
				valueGetter: (params: GridValueGetterParams<Balance, any>) =>
					getCurrencyValue(params.value),
				renderCell: (params) => {
					return AmountCurrencyTooltipGridCell({
						accountCurrencyAmount: params.row.closingLedgerBalance.value,
						accountCurrencyCode: params.row.closingLedgerBalance.valueCurrency,
						reportingCurrencyAmount:
							params.row.closingLedgerBalance.reportingValue,
						reportingCurrencyCode: configurations?.reportingCurrencyCode,
						displayedCurrencyCode: isReportingCurrency
							? CurrencySource.Reporting
							: CurrencySource.Account,
					});
				},
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'closingAvailableBalance',
				headerName: 'Closing Available Balance',
				description: 'Closing Available Balance',
				valueGetter: (params: GridValueGetterParams<Balance, any>) =>
					getCurrencyValue(params.value),
				renderCell: (params) => {
					return AmountCurrencyTooltipGridCell({
						accountCurrencyAmount: params.row.closingAvailableBalance.value,
						accountCurrencyCode:
							params.row.closingAvailableBalance.valueCurrency,
						reportingCurrencyAmount:
							params.row.closingAvailableBalance.reportingValue,
						reportingCurrencyCode: configurations?.reportingCurrencyCode,
						displayedCurrencyCode: isReportingCurrency
							? CurrencySource.Reporting
							: CurrencySource.Account,
					});
				},
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'forwardAvailableBalance0Day',
				headerName: 'Forward Available Balance (0Day)',
				description: 'Forward Available Balance (0Day)',
				valueGetter: (params: GridValueGetterParams<Balance, any>) =>
					getCurrencyValue(params.value),
				renderCell: (params) => {
					let currencyCode: string | undefined =
						params.row.currency ?? undefined;
					if (isReportingCurrency) {
						currencyCode = configurations?.reportingCurrencyCode;
					}

					return formatCurrency(params.value, {
						currency: currencyCode || 'USD',
					});
				},
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'forwardAvailableBalance1Day',
				headerName: 'Forward Available Balance (1Day)',
				description: 'Forward Available Balance (1Day)',
				valueGetter: (params: GridValueGetterParams<Balance, any>) =>
					getCurrencyValue(params.value),
				renderCell: (params) => {
					let currencyCode: string | undefined =
						params.row.currency ?? undefined;
					if (isReportingCurrency) {
						currencyCode = configurations?.reportingCurrencyCode;
					}

					return formatCurrency(params.value, {
						currency: currencyCode || 'USD',
					});
				},
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'forwardAvailableBalance2Day',
				headerName: 'Forward Available Balance (2Day)',
				description: 'Forward Available Balance (2Day)',
				valueGetter: (params: GridValueGetterParams<Balance, any>) =>
					getCurrencyValue(params.value),
				cellClassName: (params: GridCellParams<Balance, number>) =>
					params.row.forwardAvailableBalance2Day &&
					params.row.forwardAvailableBalance2Day.value &&
					params.row.forwardAvailableBalance2Day.value < 0
						? 'negative-balance'
						: '',
				renderCell: (params) => {
					let currencyCode: string | undefined =
						params.row.currency ?? undefined;
					if (isReportingCurrency) {
						currencyCode = configurations?.reportingCurrencyCode;
					}

					return formatCurrency(params.value, {
						currency: currencyCode || 'USD',
					});
				},
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'forwardAvailableBalance3Day',
				headerName: 'Forward Available Balance (3Day)',
				description: 'Forward Available Balance (3Day)',
				valueGetter: (params: GridValueGetterParams<Balance, any>) =>
					getCurrencyValue(params.value),
				cellClassName: (params: GridCellParams<Balance, number>) =>
					params.row.forwardAvailableBalance3Day &&
					params.row.forwardAvailableBalance3Day.value &&
					params.row.forwardAvailableBalance3Day.value < 0
						? 'negative-balance'
						: '',
				renderCell: (params) => {
					let currencyCode: string | undefined =
						params.row.currency ?? undefined;
					if (isReportingCurrency) {
						currencyCode = configurations?.reportingCurrencyCode;
					}

					return formatCurrency(params.value, {
						currency: currencyCode || 'USD',
					});
				},
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'totalDebitTransactions',
				headerName: 'Total Debit Transactions',
				description: 'Total Debit Transactions',
				valueGetter: (params: GridValueGetterParams<Balance, any>) =>
					getCurrencyValue(params.value),
				renderCell: (params) => {
					let currencyCode: string | undefined =
						params.row.currency ?? undefined;
					if (isReportingCurrency) {
						currencyCode = configurations?.reportingCurrencyCode;
					}

					return formatCurrency(params.value, {
						currency: currencyCode || 'USD',
					});
				},
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'totalCreditTransactions',
				headerName: 'Total Credit Transactions',
				description: 'Total Credit Transactions',
				valueGetter: (params: GridValueGetterParams<Balance, any>) =>
					getCurrencyValue(params.value),
				renderCell: (params) => {
					let currencyCode: string | undefined =
						params.row.currency ?? undefined;
					if (isReportingCurrency) {
						currencyCode = configurations?.reportingCurrencyCode;
					}

					return formatCurrency(params.value, {
						currency: currencyCode || 'USD',
					});
				},
			},
			{
				...getIntegerColumnDefinition(),
				field: 'transactionCount',
				headerName: 'Transaction Count',
				description: 'Transaction Count',
			},
			{
				...getIntegerColumnDefinition(),
				field: 'debitTransactionsCount',
				headerName: 'Debit Transaction Count',
				description: 'Debit Transaction Count',
			},
			{
				...getIntegerColumnDefinition(),
				field: 'creditTransactionsCount',
				headerName: 'Credit Transaction Count',
				description: 'Credit Transaction Count',
			},
			{
				field: 'currency',
				headerName: 'Currency',
				description: 'Currency',
				valueGetter: (params: { value: number | string }) => {
					if (isReportingCurrency) {
						return configurations?.reportingCurrencyCode || defaultCurrencyCode;
					} else {
						return params.value;
					}
				},
			},
			{
				...getFormattedDateTimeColumnDefinition(),
				field: 'balanceImportedDate',
				headerName: 'Balance Imported Date',
				description: 'Balance Imported Date',
			},
			{
				...getFormattedDateTimeColumnDefinition(),
				field: 'lastUpdatedDate',
				headerName: 'Last Updated Date',
				description: 'Last Updated Date',
			},
			{
				field: 'note',
				headerName: 'Note',
				description: 'Note',
				width: 100,
			},
		);

		return columns;
	}, [
		history,
		cash4.isAuthor,
		configurations?.reportingCurrencyCode,
		handleDeleteClick,
		isReportingCurrency,
	]);

	const closingAvailableBalanceSelection = useRowSelectionCalculations(
		balances?.map((b) => ({
			id: b.id,
			accountAmount: b.closingAvailableBalance.value || 0,
			accountCurrencyCode:
				b.closingAvailableBalance.valueCurrency || defaultCurrencyCode,
			reportingAmount: b.closingAvailableBalance.reportingValue || undefined,
			reportingCurrencyCode:
				configurations?.reportingCurrencyCode || defaultCurrencyCode,
		})) ?? [],
	);

	const closingLedgerBalanceSelection = useRowSelectionCalculations(
		balances?.map((b) => ({
			id: b.id,
			accountAmount: b.closingLedgerBalance.value || 0,
			accountCurrencyCode:
				b.closingLedgerBalance.valueCurrency || defaultCurrencyCode,
			reportingAmount: b.closingLedgerBalance.reportingValue || undefined,
			reportingCurrencyCode:
				configurations?.reportingCurrencyCode || defaultCurrencyCode,
		})) ?? [],
	);

	useEffect(() => {
		if (isLoadingBalances && closingLedgerBalanceSelection.selectedCount > 0) {
			closingLedgerBalanceSelection.setRowSelectionHandler([]);
		}
		if (
			isLoadingBalances &&
			closingAvailableBalanceSelection.selectedCount > 0
		) {
			closingAvailableBalanceSelection.setRowSelectionHandler([]);
		}
	}, [
		closingAvailableBalanceSelection,
		closingLedgerBalanceSelection,
		isLoadingBalances,
	]);

	return (
		<T4View
			header={
				<PageHeader id={pageHeaderStonlyIds.balancesPage} title="Balances" />
			}
		>
			<Grid
				container
				direction="column"
				wrap="nowrap"
				sx={{ height: '100%', rowGap: 2 }}
			>
				<Grid
					container
					item
					xs="auto"
					sx={{ gap: 2, justifyContent: 'space-between' }}
				>
					<Grid container item xs={true} sx={{ gap: 2 }}>
						<Grid item>
							<T4DateRangePicker
								id="balances-date-filter"
								defaultValue={dateRange}
								onAccept={([startDate, endDate]) => {
									if (
										startDate &&
										startDate.isValid() &&
										endDate &&
										endDate.isValid() &&
										startDate.isSameOrBefore(endDate, 'day')
									) {
										setStartDate(startDate);
										setEndDate(endDate);
										if (
											!startDate.isSame(dateRange[0]) ||
											!endDate.isSame(dateRange[1])
										) {
											setDateRange([startDate, endDate]);
										}
									}
								}}
								disableFuture
								showShortcuts
								shortcutResetDefaults={[
									moment().subtract(8, 'days').startOf('day'),
									moment().endOf('day'),
								]}
								sx={{
									marginBottom: 0,
								}}
							/>
						</Grid>
						<Grid item>
							<T4TextFieldV2
								label="Display Currency"
								select
								value={selectedReportingCurrency}
								onChange={(value) =>
									setSelectedReportingCurrency(value ?? 'original')
								}
								{...stonlyData({
									id: stonlyIds.balancesDisplayCurrencyDropdown,
								})}
							>
								{[
									{ id: 'original', value: 'Account Currency' },
									{
										id: 'reporting',
										value: `Reporting Currency (${
											configurations?.reportingCurrencyCode ??
											defaultCurrencyCode
										})`,
									},
								].map((x) => {
									return (
										<MenuItem key={x.id} value={x.id}>
											{x.value}
										</MenuItem>
									);
								})}
							</T4TextFieldV2>
						</Grid>
						<Grid item>
							<LegalEntityGroupsFilter loading={isLoadingBalances} />
						</Grid>
						<Grid item>
							<AccountGroupsFilter />
						</Grid>
					</Grid>
					{cash4.isAuthor && isCurrentDayRefreshEnabled && (
						<Grid container item xs="auto" sx={{ alignItems: 'end ' }}>
							<Tooltip title={lastRefreshedDate}>
								<Box>
									<T4Button
										variant="outlined"
										startIcon={<CurrencyExchange />}
										onClick={() => {
											updateCurrentDay();
										}}
										sx={{
											height: '40px',
										}}
									>
										Update
									</T4Button>
								</Box>
							</Tooltip>
						</Grid>
					)}
					{cash4.isAuthor && (
						<Grid
							item
							xs="auto"
							sx={{ display: 'flex', alignItems: 'flex-end' }}
						>
							<T4Button
								variant="outlined"
								startIcon={<Add />}
								onClick={() => setIsCreateOrEditBalanceDrawerOpen(true)}
								sx={{ height: 'fit-content' }}
							>
								Balance
							</T4Button>
						</Grid>
					)}
				</Grid>
				<Grid
					item
					xs={true}
					sx={{
						height: '50vh',
					}}
				>
					<ActuallyPrettyGoodDataGridWrapper
						sx={{
							minHeight: '31rem',
							'& .negative-balance': {
								backgroundColor: theme.cash4?.negativeCell?.background,
								color: theme.cash4?.negativeCell?.text,
							},
						}}
					>
						<UserPreferencesDataGrid
							tableKey="balancesPage"
							stonlyId={stonlyIds.balancesTable}
							loading={isLoadingBalances || isFetchingBalances}
							rows={balances ?? []}
							columns={columns}
							calculateColumnWidths
							pinnedColumns={{
								left: ['actions'],
							}}
							columnVisibilityModel={{
								c4AccountNumber: false,
								openingLedgerBalance: false,
								currentLedgerBalance: false,
								openingAvailableBalance: false,
								currentAvailableBalance: false,
								closingAvailableBalance: false,
								forwardAvailableBalance0Day: false,
								forwardAvailableBalance1Day: false,
								forwardAvailableBalance2Day: false,
								forwardAvailableBalance3Day: false,
								totalDebitTransactions: false,
								totalCreditTransactions: false,
								transactionCount: false,
								debitTransactionsCount: false,
								creditTransactionsCount: false,
								balanceImportedDate: false,
								lastUpdatedDate: false,
								entityCode: false,
								entityAnglicizedLegalName: false,
							}}
							initialState={{
								sorting: {
									sortModel: [
										{ field: 'statementDate', sort: 'desc' },
										{ field: 'e4AccountNumber', sort: 'desc' },
									],
								},
							}}
							showToolbar
							showCustomViewButton
							pagination
							hideFooter={false}
							pageSizeOptions={[25, 50, 100]}
							onRowSelectionModelChange={(model: GridRowSelectionModel) => {
								closingAvailableBalanceSelection.setRowSelectionHandler(model);
								closingLedgerBalanceSelection.setRowSelectionHandler(model);
							}}
						/>
					</ActuallyPrettyGoodDataGridWrapper>
				</Grid>
				{closingAvailableBalanceSelection.isSelectedTotalValid &&
					closingLedgerBalanceSelection.isSelectedTotalValid &&
					closingAvailableBalanceSelection.selectedCount >= 2 && (
						<Grid item xs="auto">
							<T4Alert severity="info" fullWidth sx={{ width: '100%' }}>
								{'The '}
								<C4AlertBold>{`${
									closingAvailableBalanceSelection.selectedCurrencySource ===
									CurrencySource.Account
										? 'account currency'
										: 'reporting currency'
								}`}</C4AlertBold>
								{' totals for the selected rows are '}
								<C4AlertBold>
									{`${formatCurrency(
										closingAvailableBalanceSelection.selectedTotal,
										{
											currency:
												closingAvailableBalanceSelection.selectedCurrency,
										},
									)} ${closingAvailableBalanceSelection.selectedCurrency}`}
								</C4AlertBold>
								{' for closing available balance and '}
								<C4AlertBold>
									{`${formatCurrency(
										closingLedgerBalanceSelection.selectedTotal,
										{
											currency: closingLedgerBalanceSelection.selectedCurrency,
										},
									)} ${closingLedgerBalanceSelection.selectedCurrency}`}
								</C4AlertBold>
								{' for closing ledger balance.'}
							</T4Alert>
						</Grid>
					)}
				{(!closingAvailableBalanceSelection.isSelectedTotalValid ||
					!closingLedgerBalanceSelection.isSelectedTotalValid) && (
					<Grid item xs="auto">
						<T4Alert severity="info" fullWidth sx={{ width: '100%' }}>
							{'The '}
							<C4AlertBold>{`${
								closingAvailableBalanceSelection.selectedCurrencySource ===
								CurrencySource.Account
									? 'account currency'
									: 'reporting currency'
							}`}</C4AlertBold>
							{
								' total of the selected rows is unavailable due to missing exchange rates. Select records with the same account currency.'
							}
						</T4Alert>
					</Grid>
				)}
			</Grid>
			<BalanceDrawer onClose={handleClose} />
			<CreateOrEditBalanceDrawer
				isOpen={isCreateOrEditBalanceDrawerOpen}
				onClose={() => {
					setIsCreateOrEditBalanceDrawerOpen(false);
					if (!!routeBalanceId) history.push(paths.cash4.balances.href);
				}}
			/>
			<DeleteBalanceConfirmationDialog
				id={balanceIdToDelete}
				onClose={() => setBalanceIdToDelete(undefined)}
			/>
		</T4View>
	);
});
