import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Grid, IconButton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { T4NumberInput } from 'shared/components/t4NumberInput';
import { ModalBase } from '../../../../../shared/components/modalBase';
import { fetchRules } from '../../services';
import { useFormContext } from '../providers/FormProvider';
import { isValidName } from '../validation';
import RuleMatchCount from './RuleMatchCount';
import { cash4QueryKeys } from 'features/cash4/_shared/cash4QueryKeys';

export type RuleNameProps = {
	isEditing: boolean;
};

export const RuleName: React.FC<RuleNameProps> = observer(({ isEditing }) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const {
		priority,
		selectPriority,
		name,
		selectName,
		selectIsManualCategorization,
	} = useFormContext();

	const { data: rules } = useQuery([cash4QueryKeys.rules], fetchRules);

	useEffect(() => {
		if (!isEditing && !!rules) {
			selectPriority((rules.length as number) + 1);
		}

		selectIsManualCategorization(false);
	}, [isEditing, rules, selectIsManualCategorization, selectPriority]);

	return (
		<Grid container item xs={12}>
			<Grid item xs={12} mb={2}>
				<RuleMatchCount />
			</Grid>
			<Grid item xs={2} pr={1}>
				<T4NumberInput
					data-testid="rule-priority"
					label="Priority"
					value={priority.field}
					onValueChange={({ floatValue }) => selectPriority(floatValue ?? null)}
					min={1}
					max={
						isEditing
							? (rules?.length as number)
							: (rules?.length as number) + 1
					}
					required
					decimalScale={0}
					incrementBy={1}
					InputProps={{
						startAdornment: (
							<IconButton
								onClick={() => {
									setIsModalOpen(true);
								}}
							>
								<InfoOutlinedIcon />
							</IconButton>
						),
					}}
				/>
				<ModalBase
					title="Priority"
					open={isModalOpen}
					onClose={() => {
						setIsModalOpen(false);
					}}
				>
					Rules with the same or lower priority than the new value will move
					down one place in the list.
				</ModalBase>
			</Grid>
			<Grid item xs={10}>
				<T4TextFieldV2
					data-testid="rule-name"
					label="Rule Name"
					value={name.field ?? ''}
					onChange={selectName}
					required
					error={!isValidName(name.field, name.dirty).valid}
					helperText={isValidName(name.field, name.dirty).helperText}
				/>
			</Grid>
		</Grid>
	);
});
