/* eslint-disable mobx/missing-observer */
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Divider, Grid, Tooltip } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { FinancialInstitution } from 'modules/clients/apiGateway/financialInstitutions';
import {
	Account,
	BankConnection,
	LegalEntity,
	UpdateBankConnectionRequest,
} from 'modules/clients/apiGateway/payments4/bankConnections';
import { T4ProblemDetails } from 'modules/clients/types';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { T4MultiSelectAutocompleteVertical } from 'shared/components/autocompletes/t4MultiselectAutocompleteVertical';
import { CancellationModal } from 'shared/components/CancellationModal';
import { T4DrawerBase } from 'shared/components/drawer/drawerBase';
import {
	DrawerCancelButton,
	DrawerSubmitButton,
} from 'shared/components/drawer/drawerButtons';
import { TooltipAdornment } from 'shared/components/tooltipAdornment';
import { useClients } from 'shared/hooks/useClients';
import { flattenProblemDetails, MultiError } from 'utilities/errors/errorUtils';
import { trimStringsInObject } from 'utilities/objectUtils';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { ConnectionSettingsForm } from './connectionSettingsForm';
import {
	BankConnectionForm,
	BankConnectionSettingsType,
	BankConnectionTypes,
	defaultBankConnectionForm,
} from './constants';
import {
	BankConnectionQueryKeys,
	useGetBankConnection,
} from './hooks/useGetBankConnections';
import { useGetCertificateNames } from './hooks/useGetCertificateNames';
import {
	useGetBankConnectionAccounts,
	useGetBankConnectionLegalEntities,
} from './hooks/useGetE4Objects';
import { useGetFinancialInstitutions } from './hooks/useGetFinancialInstitutions';
import { GetBankConnectionFormValidator } from './validators';

type EditBankConnectionDrawerProps = {
	bankConnectionId: string | null;
	bankConnections: BankConnection[];
	onClose: () => void;
};

export const EditBankConnectionDrawer: FC<EditBankConnectionDrawerProps> = ({
	bankConnectionId,
	bankConnections,
	onClose,
}) => {
	const { applicationApiClient } = useClients();
	const queryClient = useQueryClient();
	const { enqueueSnackbar } = useSnackbar();

	const formMethods = useForm<BankConnectionForm>({
		defaultValues: defaultBankConnectionForm,
		resolver: yupResolver(GetBankConnectionFormValidator(bankConnections)),
	});
	const { control, watch, setValue, reset, handleSubmit, formState } =
		formMethods;

	const closeDrawer = useCallback(() => {
		onClose();
		reset(defaultBankConnectionForm);
		setErrors([]);
	}, [onClose, reset]);

	const {
		isLoading: isBankConnectionLoading,
		isFetching: isBankConnectionFetching,
		data: bankConnection,
		error: loadingBankConnectionError,
	} = useGetBankConnection(bankConnectionId);
	useEffect(() => {
		if (!isBankConnectionLoading && loadingBankConnectionError !== null) {
			if (!bankConnection) {
				closeDrawer();
			}
			enqueueSnackbar(loadingBankConnectionError?.message, {
				key: 'get-bank-connection-failure',
				variant: 'error',
				preventDuplicate: true,
			});
		}
	}, [
		isBankConnectionLoading,
		isBankConnectionFetching,
		loadingBankConnectionError,
		bankConnection,
		closeDrawer,
		enqueueSnackbar,
	]);

	const {
		isLoading: areFinancialInstitutionsLoading,
		isFetching: areFinancialInstitutionsFetching,
		data: financialInstitutions,
		error: loadingFinancialInstitutionsError,
	} = useGetFinancialInstitutions(!!bankConnection);
	const {
		isLoading: areCertificateNamesLoading,
		isFetching: areCertificateNamesFetching,
		data: certificateNames,
		error: loadingCertificateNamesError,
	} = useGetCertificateNames(!!bankConnection);

	const {
		isLoading: areLegalEntitiesLoading,
		isFetching: areLegalEntitiesFetching,
		data: legalEntities,
		error: loadingLegalEntitiesError,
	} = useGetBankConnectionLegalEntities(
		!!bankConnection && bankConnection.e4EntityIds.length > 0,
	);
	useEffect(() => {
		if (!areLegalEntitiesLoading && loadingLegalEntitiesError !== null) {
			closeDrawer();
			enqueueSnackbar(
				'Unable to load legal entities associated with payment connection. Please try again later.',
				{
					key: 'load-payment-connection-legal-entities',
					variant: 'error',
					preventDuplicate: true,
				},
			);
		}
	}, [
		areLegalEntitiesLoading,
		loadingLegalEntitiesError,
		bankConnection,
		closeDrawer,
		enqueueSnackbar,
	]);

	const {
		isLoading: areAccountsLoading,
		isFetching: areAccountsFetching,
		data: accounts,
		error: loadingAccountsError,
	} = useGetBankConnectionAccounts(
		!!bankConnection && bankConnection.e4AccountIds.length > 0,
	);
	useEffect(() => {
		if (
			loadingFinancialInstitutionsError ||
			loadingCertificateNamesError ||
			loadingLegalEntitiesError ||
			loadingAccountsError
		) {
			onClose();
			reset(defaultBankConnectionForm);
			enqueueSnackbar(
				'Unable to load supporting data to enable bank connection editing. Please try again later.',
				{
					key: 'load-bank-connection-data-failure',
					variant: 'error',
					preventDuplicate: true,
				},
			);
		}
	}, [
		loadingFinancialInstitutionsError,
		loadingCertificateNamesError,
		loadingLegalEntitiesError,
		loadingAccountsError,
		onClose,
		reset,
		enqueueSnackbar,
	]);

	useEffect(() => {
		if (bankConnection && financialInstitutions?.length) {
			const form: BankConnectionForm = {
				id: bankConnection.id,
				bank:
					financialInstitutions.find(
						(x) => x.bankCode === bankConnection.bankCode,
					) ?? null,
				connectionType: bankConnection.isGlobalConnection
					? 'Global'
					: bankConnection.e4EntityIds.length
					? 'Entity'
					: 'Account',
				name: bankConnection.name,
				connectionSettings:
					bankConnection.connectionSettings as BankConnectionSettingsType,
				e4Entities: legalEntities?.length
					? bankConnection.e4EntityIds.map(
							(id) =>
								legalEntities?.find((entity) => entity.id === id) ?? {
									id: 'unknownEntityId',
									code: 'Unknown Entity',
									displayName: 'Unknown Entity',
									isApproved: false,
									anglicizedLegalName: null,
								},
					  )
					: [],
				e4Accounts: accounts?.length
					? bankConnection.e4AccountIds.map(
							(id) =>
								accounts?.find((entity) => entity.id === id) ?? {
									id: 'unknownAccountId',
									code: 'Deleted Account',
									displayName: 'Deleted Acccount',
									isApproved: false,
									shortName: null,
								},
					  )
					: [],
				notes: bankConnection.notes,
			};
			reset(form);
		}
	}, [bankConnection, financialInstitutions, legalEntities, accounts, reset]);

	const isInitializing = useMemo(
		() =>
			(isBankConnectionLoading && isBankConnectionFetching) ||
			(areFinancialInstitutionsFetching && areFinancialInstitutionsLoading) ||
			(areCertificateNamesFetching && areCertificateNamesLoading),
		[
			isBankConnectionLoading,
			isBankConnectionFetching,
			areFinancialInstitutionsFetching,
			areFinancialInstitutionsLoading,
			areCertificateNamesFetching,
			areCertificateNamesLoading,
		],
	);

	const [isCancellationModalOpen, setIsCancellationModalOpen] =
		useState<boolean>(false);

	const [errors, setErrors] = useState<string[]>([]);
	const errorsRef = useRef<HTMLDivElement>(null);

	const updateMutationFn = useCallback(
		async (data: BankConnectionForm) => {
			if (bankConnectionId === null) throw new Error();

			const request = trimStringsInObject({
				name: data.name,
				isGlobalConnection: data.connectionType === 'Global',
				connectionSettings: data.connectionSettings! as {
					[key: string]: string;
				},
				e4EntityIds: data.e4Entities.map((x) => x.id),
				e4AccountIds: data.e4Accounts.map((x) => x.id),
				notes: data.notes,
			}) as UpdateBankConnectionRequest;

			const response =
				await applicationApiClient.payments4.bankConnections.update({
					id: bankConnectionId!,
					data: request,
				});
			if (response.status === 200 && response.data) return true;
			else if (response.status === 400 && response.data)
				throw new MultiError(
					flattenProblemDetails(response.data as T4ProblemDetails),
				);
			else throw new Error();
		},
		[bankConnectionId, applicationApiClient],
	);
	const { isLoading, mutate: onUpdate } = useMutation<
		boolean,
		Error,
		BankConnectionForm
	>({
		mutationFn: updateMutationFn,
		onMutate: () => {
			setErrors([]);
		},
		onSuccess: () => {
			closeDrawer();
			enqueueSnackbar('Payment connection updated successfully.', {
				key: 'update-payment-connection-success',
				variant: 'success',
				preventDuplicate: true,
			});
			queryClient.invalidateQueries(BankConnectionQueryKeys.base);
		},
		onError: (error) => {
			if (error instanceof MultiError) {
				setErrors(error.messages);
				errorsRef?.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			} else
				enqueueSnackbar(
					'Unable to update payment connection. Please try again later.',
					{
						key: 'update-payment-connection-failure',
						variant: 'error',
						preventDuplicate: true,
					},
				);
		},
	});

	const selectedBank = watch('bank');
	const bankAlreadyHasGlobalConnection = useMemo(
		() =>
			bankConnections.some(
				(x) => x.bankCode === selectedBank?.bankCode && x.isGlobalConnection,
			),
		[selectedBank, bankConnections],
	);
	const selectedConnectionType = watch('connectionType');

	const connectionTypeAlert = useMemo(() => {
		let messaging = '';
		switch (selectedConnectionType) {
			case 'Global':
				messaging =
					'These credentials will be used for all entities and accounts associated with this bank.';
				break;
			case 'Entity':
				messaging =
					'Entity-level credentials override any global connection and apply to all accounts within the selected entities for the specified bank.';
				break;
			case 'Account':
				messaging =
					'Account-level credentials will take precedence over both global and entity-level connections for the selected accounts.';
		}

		return (
			<Grid item xs={12}>
				<Alert
					severity="info"
					sx={{ '&.MuiPaper-root': { margin: 0, height: '100%' } }}
				>
					{messaging}
				</Alert>
			</Grid>
		);
	}, [selectedConnectionType]);

	const relatedObjectSelector = useMemo(() => {
		if (selectedConnectionType === 'Entity')
			return (
				<Grid item xs={12}>
					{loadingLegalEntitiesError ? (
						<Alert
							severity="warning"
							sx={{ '&.MuiPaper-root': { marginTop: 0, height: '100%' } }}
						>
							Unable to load Legal Entities. Please try again later.
						</Alert>
					) : (
						<Controller
							name={'e4Entities'}
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4MultiSelectAutocompleteVertical<LegalEntity>
									id="e4-entities"
									value={value}
									options={legalEntities ?? []}
									onChange={(_, value) => onChange(value)}
									getOptionKey={(option) => option.id}
									isOptionEqualToValue={(option, value) =>
										option.id === value.id
									}
									getOptionLabel={(option) => option.displayName}
									textFieldProps={{
										label: 'Entities',
										error: !!error,
										helperText: error && error.message,
										required: true,
										InputProps: {
											startAdornment: (
												<TooltipAdornment
													adornmentType="info"
													text="If you don't see an expected entity in the dropdown, check the account relationships in Entity4."
												/>
											),
										},
									}}
									loading={areLegalEntitiesFetching && areLegalEntitiesLoading}
									readOnly={areLegalEntitiesFetching && areLegalEntitiesLoading}
								/>
							)}
						/>
					)}
				</Grid>
			);
		else if (selectedConnectionType === 'Account')
			return (
				<Grid item xs={12}>
					{loadingAccountsError ? (
						<Alert
							severity="warning"
							sx={{ '&.MuiPaper-root': { marginTop: 0, height: '100%' } }}
						>
							Unable to load Accounts. Please try again later.
						</Alert>
					) : (
						<Controller
							name={'e4Accounts'}
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4MultiSelectAutocompleteVertical<Account>
									id="e4-accounts"
									value={value}
									options={accounts ?? []}
									onChange={(_, value) => onChange(value)}
									getOptionKey={(option) => option.id}
									isOptionEqualToValue={(option, value) =>
										option.id === value.id
									}
									getOptionLabel={(option) => option.displayName}
									textFieldProps={{
										label: 'Accounts',
										error: !!error,
										helperText: error && error.message,
										required: true,
										InputProps: {
											startAdornment: (
												<TooltipAdornment
													adornmentType="info"
													text="If you don't see an expected account in the dropdown, check the account relationships in Entity4."
												/>
											),
										},
									}}
									loading={areAccountsFetching && areAccountsLoading}
									readOnly={areAccountsFetching && areAccountsLoading}
								/>
							)}
						/>
					)}
				</Grid>
			);
		else return null;
	}, [
		control,
		selectedConnectionType,
		legalEntities,
		accounts,
		areLegalEntitiesFetching,
		areLegalEntitiesLoading,
		loadingLegalEntitiesError,
		areAccountsFetching,
		areAccountsLoading,
		loadingAccountsError,
	]);

	return (
		<T4DrawerBase
			title="Edit Connection"
			open={!!bankConnectionId}
			onClose={() => {
				if (formState.isDirty) setIsCancellationModalOpen(true);
				else closeDrawer();
			}}
			initializing={isInitializing}
			loading={isLoading}
			actions={[
				<DrawerCancelButton
					onCancel={() => {
						if (formState.isDirty) setIsCancellationModalOpen(true);
						else closeDrawer();
					}}
					disabled={isLoading}
				/>,
				<DrawerSubmitButton
					onSubmit={handleSubmit((data) => onUpdate(data))}
					label="Save"
					disabled={!formState.isDirty || isLoading || isInitializing}
				/>,
			]}
			disableNavigationCollapse
		>
			<FormProvider {...formMethods}>
				<Grid container sx={{ gap: 2 }}>
					<Grid item xs={12}>
						<Controller
							name={'bank'}
							control={control}
							render={({ field: { value } }) => {
								return (
									<T4Autocomplete<FinancialInstitution>
										id="bank"
										label="Bank"
										value={value}
										options={financialInstitutions ?? []}
										isOptionEqualToValue={(option, value) =>
											option?.bankCode === value?.bankCode
										}
										getOptionLabel={(option) =>
											option?.displayName ??
											option?.bankCode ??
											option?.financialInstitutionId
										}
										readOnly={true}
										disableClearable
										required
									/>
								);
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name={'connectionType'}
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => {
								return (
									<T4Autocomplete<string, false, true>
										id="connectionType"
										label="Connection Type"
										value={value}
										options={BankConnectionTypes}
										onChange={(_: any, newValue: string) => {
											if (newValue !== value) {
												onChange(newValue);
												setValue('e4Entities', []);
												setValue('e4Accounts', []);
											}
										}}
										getOptionDisabled={(option) =>
											option === 'Global' && bankAlreadyHasGlobalConnection
										}
										renderOption={(props, option, _, ownerState) => {
											const labelBox = (
												<Box component="li" {...props} key={option}>
													{ownerState.getOptionLabel(option)}
												</Box>
											);
											if (ownerState.getOptionDisabled?.(option)) {
												return (
													<Box key={option}>
														<Tooltip
															title={`${selectedBank?.displayName} already has a global connection.`}
															placement="left"
															arrow
														>
															<span>{labelBox}</span>
														</Tooltip>
													</Box>
												);
											}

											return labelBox;
										}}
										error={!!error}
										helperText={error && error.message}
										disableClearable
										required
									/>
								);
							}}
						/>
					</Grid>

					{connectionTypeAlert}

					{relatedObjectSelector}

					<Grid item xs={12}>
						<Controller
							name={'name'}
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => {
								return (
									<T4TextFieldV2
										id="connection-name"
										label="Connection Name"
										value={value}
										onChange={onChange}
										error={!!error}
										helperText={error && error.message}
										required
										InputProps={{
											startAdornment: (
												<TooltipAdornment
													adornmentType="info"
													text="Choose a unique and descriptive name for this connection to help you easily identify it later."
												/>
											),
										}}
									/>
								);
							}}
						/>
					</Grid>

					<Grid item xs={12}>
						<Divider />
					</Grid>

					<ConnectionSettingsForm certificateNames={certificateNames ?? []} />

					<Grid item xs={12}>
						<Divider />
					</Grid>

					<Grid item xs={12}>
						<Controller
							name="notes"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4TextFieldV2
									id="notes"
									label="Notes"
									value={value ?? ''}
									onChange={(value: string) => {
										if (isStringUndefinedOrNullOrWhitespace(value))
											onChange(null);
										else onChange(value);
									}}
									minRows={4}
									maxRows={4}
									multiline
									error={!!error}
									helperText={`${value?.length ?? 0}/140${
										error?.message ? ' ' + error.message : ''
									}`}
									inputProps={{
										maxLength: 140,
									}}
								/>
							)}
						/>
					</Grid>

					{Object.values(errors).length > 0 && (
						<Grid item xs={12} ref={errorsRef}>
							<T4AlertStack errors={errors} />
						</Grid>
					)}
				</Grid>
			</FormProvider>

			<CancellationModal
				isOpen={isCancellationModalOpen}
				resourceType="payment connection"
				variant="edit"
				onClose={() => setIsCancellationModalOpen(false)}
				onSubmit={() => {
					closeDrawer();
				}}
			/>
		</T4DrawerBase>
	);
};
