import { Add, AltRoute } from '@mui/icons-material';
import {
	Alert,
	Button,
	CircularProgress,
	Divider,
	Grid,
	MenuItem,
	Tooltip,
	Typography,
} from '@mui/material';
import {
	GRID_REORDER_COL_DEF,
	GridColDef,
	GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';
import { DeleteMenuItem } from 'shared/components/deleteMenuItem';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { paths } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import { getDateColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import {
	DataGridColumnWidths,
	getOptionsMenuColDef,
} from 'shared/utilities/dataGrid/dataGridUtils';
import { stonlyData } from 'stonly/functions';
import { capitalizeString } from 'utilities/stringUtils';
import { usePartiesQuery } from '../_queries/usePartiesQuery';
import { RunRulesModal } from '../transactions/_components/runRulesModal';
import ListRules from './ListRules';
import { useDeleteRuleMutation } from './_hooks/useDeleteRuleMutation';
import { RuleModal } from './createRuleModal/RuleModal';
import {
	DataProvider,
	useDataContext,
} from './createRuleModal/providers/DataProvider';
import { FormProvider } from './createRuleModal/providers/FormProvider';
import { DeleteRuleModal } from './deleteRuleModal/deleteRuleModal';
import { MatchCondition, TransactionRuleListItem } from './models';
import { fetchRule } from './services';
import { dataTestIds, stonlyIds } from './utilities';
import { formatDateTime } from 'shared/utilities/dateUtilities';
import { useRulesEngineStatusQuery } from './_hooks/useLastRulesExecutionQuery';
import { enqueueSnackbar } from 'notistack';
import { cash4QueryKeys } from '../_shared/cash4QueryKeys';

export const RulesRoute: FC = observer(() => (
	<DataProvider>
		<RulesPage />
	</DataProvider>
));

export const RulesPage: FC = observer(() => {
	const { ruleId } = useParams<{ ruleId: string }>();
	const { cash4 } = useUser();
	const history = useHistory();
	const { categories, rule, setRule } = useDataContext();
	const { data: parties } = usePartiesQuery();

	const [runRulesRequestSubmitted, setRunRulesRequestSubmitted] =
		useState(false);
	const [openRunRules, setOpenRunRules] = useState(false);
	const [openRule, setOpenRule] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [openDeleteRule, setOpenDeleteRule] = useState(false);

	const { mutateAsync: deleteRule } = useDeleteRuleMutation();
	const queryClient = useQueryClient();
	const { data: fetchedRule } = useQuery(
		['rule', ruleId],
		() => fetchRule(ruleId),
		{
			enabled: !!ruleId && ruleId !== 'create' && categories !== undefined,
			onSuccess: (data) => {
				if (data) {
					setIsEditing(true);
					setRule(data);
					setOpenRule(true);
				}
			},
		},
	);

	const { queryResult: rulesEngineStatusQuery, lastRulesEngineStatus } =
		useRulesEngineStatusQuery(runRulesRequestSubmitted);
	const rulesRuntime = useMemo(() => {
		if (
			!rulesEngineStatusQuery ||
			!rulesEngineStatusQuery.data?.mostRecentEndTime
		)
			return null;
		return (
			<Grid
				container
				sx={{
					flexDirection: 'column',
				}}
			>
				<Grid item>
					Last Completed: {rulesEngineStatusQuery.data?.mostRecentExecutionType}
				</Grid>
				<Grid item>
					{formatDateTime(
						rulesEngineStatusQuery.data?.mostRecentEndTime.toString(),
						'DD-MMM-YYYY h:mm A z',
					)}
				</Grid>
			</Grid>
		);
	}, [rulesEngineStatusQuery]);

	const isRulesRunning = useMemo(
		() =>
			rulesEngineStatusQuery?.data?.currentStatus === 'Processing' ||
			runRulesRequestSubmitted,
		[runRulesRequestSubmitted, rulesEngineStatusQuery?.data?.currentStatus],
	);

	// Reset runRulesRequestSubmitted when rulesEngineStatusQuery is completed

	// we only want to show the success message if the status changes to completed

	// we do not want to show the success message if the page is loaded and the status is complete
	useEffect(() => {
		if (
			rulesEngineStatusQuery?.data?.currentStatus === 'Completed' &&
			rulesEngineStatusQuery?.data?.mostRecentEndTime &&
			lastRulesEngineStatus &&
			lastRulesEngineStatus?.mostRecentEndTime &&
			lastRulesEngineStatus?.mostRecentEndTime <
				rulesEngineStatusQuery?.data?.mostRecentEndTime
		) {
			enqueueSnackbar('Rules have successfully finished running!', {
				key: 'cash4-rules-run-complete',
				preventDuplicate: true,
				variant: 'success',
			});
			setRunRulesRequestSubmitted(false);
			queryClient.invalidateQueries([cash4QueryKeys.rules]);
		}
	}, [runRulesRequestSubmitted, lastRulesEngineStatus, rulesEngineStatusQuery?.data?.currentStatus, rulesEngineStatusQuery?.data?.mostRecentEndTime, queryClient]);

	const handleCreateRuleClick = useCallback(
		(rule: TransactionRuleListItem | undefined = undefined) => {
			history.push(`${paths.cash4.rules.href}/create`);
			setRule(
				rule
					? { ...rule, id: undefined, name: rule.name + ' - COPY' }
					: undefined,
			);
			setOpenRule(true);
		},
		[history, setRule],
	);

	const handleEditRuleClick = useCallback(
		(rule: TransactionRuleListItem) => {
			history.push(`${paths.cash4.rules.href}/${rule.id}`);
			setIsEditing(true);
			setRule(rule);
			setOpenRule(true);
		},
		[history, setRule],
	);

	const handleCloseRule = useCallback(() => {
		setOpenRule(false);
		setIsEditing(false);
		setRule(null);
		history.push(paths.cash4.rules.href);
	}, [history, setRule]);

	const handleOpenDeleteRule = useCallback(
		async (row: TransactionRuleListItem) => {
			if (row.id && (row.transactionCount ?? 0) === 0) {
				await deleteRule(row.id);
			} else {
				setRule(row);
				setOpenDeleteRule(true);
			}
		},
		[deleteRule, setRule],
	);

	const handleCloseDeleteRule = useCallback(() => {
		setOpenDeleteRule(false);
		setRule(undefined);
	}, [setRule]);

	const getConditionsDescription = useCallback(
		(conditions: MatchCondition[]) => {
			let conditionArr: string[] = [];

			const mapValuesToString = (
				values: string[],
				isEntityName: boolean = false,
			): string => {
				return values
					.map((value, index) => {
						let actual = value;
						if (isEntityName) {
							actual = parties?.find((party) => party.id === value)?.name ?? '';
						}
						return index === values.length - 1 ? `${actual}` : `${actual} or `;
					})
					.join(' ');
			};

			conditions.forEach((condition) => {
				conditionArr.push(
					`${condition.field} ${condition.operator} ${mapValuesToString(
						condition.values,
						condition.field === 'Entity Name',
					)}`,
				);
			});

			let description = '';
			conditionArr.forEach((element, index) => {
				if (index > 0 && index <= conditionArr.length - 1)
					description += ', and ';

				if (index === 0) description += capitalizeString(element);
				else description += element;
				if (index === conditionArr.length - 1) description += '.';
			});
			return description;
		},
		[parties],
	);

	const columns = useMemo(() => {
		let columns: GridColDef<TransactionRuleListItem>[] = [
			{
				field: 'priority',
				headerName: 'Priority',
				description: 'Priority',
				flex: 1 / 2,
				sortable: false,
				filterable: false,
				hideable: false,
			},
			{
				field: 'name',
				headerName: 'Rule Name',
				description: 'Rule Name',
				type: 'string',
				flex: 2,
				sortable: false,
			},
			{
				field: 'conditions',
				headerName: 'Conditions',
				description: 'Conditions',
				type: 'string',
				cellClassName: () => 'conditions-cell',
				renderCell: (params: GridRenderCellParams<TransactionRuleListItem>) => {
					return (
						<Typography
							width={1}
							variant="body2"
							sx={{
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								display: '-webkit-box!important',
								lineClamp: 2,
								WebkitLineClamp: 2,
								WebkitBoxOrient: 'vertical',
							}}
						>
							{getConditionsDescription(params.value)}
						</Typography>
					);
				},
				valueGetter: (params) => params.row.matchConditions,
				valueFormatter: (params) => getConditionsDescription(params.value),
				flex: 4,
				sortable: false,
			},
			{
				field: 'cfc',
				headerName: 'CFC',
				description: 'Cash Flow Class',
				type: 'string',
				width: DataGridColumnWidths.threeChar,
				sortable: false,
				valueGetter: (params) => params.row.cfc.code,
				valueFormatter: (params) => params.value ?? '',
				renderCell: (params: GridRenderCellParams<TransactionRuleListItem>) => {
					return (
						<Tooltip title={params.row.cfc.name}>
							<Typography variant="body2" noWrap>
								{params.value ?? ''}
							</Typography>
						</Tooltip>
					);
				},
			},
			{
				field: 'cfcName',
				headerName: 'CFC Description',
				description: 'Cash Flow Class',
				type: 'string',
				valueGetter: (params) => params.row.cfc.name,
				valueFormatter: (params) => params.value ?? '',
				renderCell: (params: GridRenderCellParams<TransactionRuleListItem>) =>
					params.value ?? '',
			},
			{
				field: 'cft',
				headerName: 'CFT',
				description: 'Cash Flow Type',
				type: 'string',
				width: DataGridColumnWidths.threeChar,
				sortable: false,
				valueGetter: (params) => params.row.cft.code,
				valueFormatter: (params) => params.value ?? '',
				renderCell: (params: GridRenderCellParams<TransactionRuleListItem>) => {
					return (
						<Tooltip title={params.row.cft.name}>
							<Typography variant="body2" noWrap>
								{params.value ?? ''}
							</Typography>
						</Tooltip>
					);
				},
			},
			{
				field: 'cftName',
				headerName: 'CFT Description',
				description: 'Cash Flow Type',
				type: 'string',
				valueGetter: (params) => params.row.cft.name,
				valueFormatter: (params) => params.value ?? '',
				renderCell: (params: GridRenderCellParams<TransactionRuleListItem>) =>
					params.value ?? '',
			},
			{
				field: 'cfst',
				headerName: 'CFST',
				description: 'Cash Flow Subtype',
				type: 'string',
				width: DataGridColumnWidths.fourChar,
				sortable: false,
				valueGetter: (params) => params.row.cfst?.code,
				valueFormatter: (params) => params.value ?? '',
				renderCell: (params: GridRenderCellParams<TransactionRuleListItem>) => {
					if (params.row.cfst) {
						return (
							<Tooltip title={params.row.cfst.name}>
								<Typography variant="body2" noWrap>
									{params.value ?? ''}
								</Typography>
							</Tooltip>
						);
					}

					return '';
				},
			},
			{
				field: 'cfstName',
				headerName: 'CFST Description',
				description: 'Cash Flow Subtype',
				type: 'string',
				valueGetter: (params) => params.row.cfst?.name,
				valueFormatter: (params) => params.value ?? '',
				renderCell: (params: GridRenderCellParams<TransactionRuleListItem>) =>
					params.value ?? '',
			},
			{
				field: 'glCode',
				headerName: 'GL Code',
				description: 'GL Code',
				type: 'string',
				sortable: false,
				valueGetter: (params) => params.row.glCode?.code,
				valueFormatter: (params) => params.value ?? '',
			},
			{
				field: 'transactionCount',
				headerName: 'Transaction Count',
				description: 'Transaction Count',
				type: 'number',
				sortable: false,
				valueGetter: (params) => params.row.transactionCount ?? 0,
			},
			{
				...getDateColumnDefinition(),
				field: 'lastAppliedOn',
				headerName: 'Last Applied On',
				description:
					'The most recent date this rule was used to categorize a transaction.',
				sortable: false,
			},
		];

		if (cash4.isAuthor) {
			columns.unshift(
				{
					...getOptionsMenuColDef(
						(params: GridRenderCellParams<TransactionRuleListItem>) => {
							return (
								<OverflowMenu
									iconButtonProps={{
										'data-testid': `${dataTestIds.menuOptionsButton}-${params.row.id}`,
										...stonlyData({
											id: stonlyIds.menuOptionsButton,
										}),
									}}
									id={`rulesId-more-menu`}
								>
									<MenuItem
										{...stonlyData({
											id: stonlyIds.editRuleButton,
										})}
										data-testid={`${dataTestIds.editRuleButton}-${params.row.id}`}
										onClick={() => handleEditRuleClick(params.row)}
									>
										Edit Rule
									</MenuItem>
									<MenuItem onClick={() => handleCreateRuleClick(params.row)}>
										Copy Rule
									</MenuItem>
									<Divider />
									<DeleteMenuItem
										{...stonlyData({
											id: stonlyIds.deleteRuleButton,
										})}
										data-testid={`${dataTestIds.deleteRuleButton}-${params.row.id}`}
										onClick={() => {
											handleOpenDeleteRule(params.row);
										}}
									/>
								</OverflowMenu>
							);
						},
					),
					disableExport: true,
					field: 'menu',
				},
				{
					...GRID_REORDER_COL_DEF,
					hideable: false,
				},
			);
		}

		return columns;
	}, [
		cash4.isAuthor,
		getConditionsDescription,
		handleCreateRuleClick,
		handleEditRuleClick,
		handleOpenDeleteRule,
	]);

	return (
		<T4View
			header={<PageHeader id={pageHeaderStonlyIds.rulesPage} title="Rules" />}
		>
			<Grid
				container
				sx={{
					height: '100%',
					flexDirection: 'column',
					flexWrap: 'nowrap',
					gap: 2,
				}}
			>
				<Grid item xs="auto">
					<Alert severity="info">
						Rules are applied to transactions according to the priority
						specified in the table below. Drag rules to change their priority.
						The first rule that matches a transaction is used to categorize that
						transaction, so it is best to assign general rules lower on the
						list.
					</Alert>
				</Grid>
				{cash4.isAuthor && (
					<Grid item xs="auto">
						<Grid
							container
							columnSpacing={2}
							sx={{
								justifyContent: 'flex-end',
							}}
						>
							<Grid item>{rulesRuntime}</Grid>
							<Grid item>
								<Button
									{...stonlyData({ id: stonlyIds.runRulesButton })}
									data-testid={dataTestIds.runRulesButton}
									variant="outlined"
									onClick={() => setOpenRunRules(true)}
									disabled={isRulesRunning}
									startIcon={
										isRulesRunning ? (
											<CircularProgress size="1.5rem" />
										) : (
											<AltRoute />
										)
									}
								>
									{isRulesRunning ? 'Running' : 'Run Rules'}
								</Button>
							</Grid>
							<Grid item>
								<Button
									startIcon={<Add />}
									type="button"
									variant="outlined"
									color="primary"
									data-testid={dataTestIds.createRuleButton}
									{...stonlyData({ id: stonlyIds.createRuleButton })}
									onClick={() => handleCreateRuleClick()}
								>
									Create Rule
								</Button>
							</Grid>
						</Grid>
					</Grid>
				)}
				<Grid item xs={true}>
					<ActuallyPrettyGoodDataGridWrapper
						sx={{
							'& .conditions-cell': {
								whiteSpace: 'normal!important',
							},
						}}
					>
						<ListRules columns={columns} onRowClick={handleEditRuleClick} />
					</ActuallyPrettyGoodDataGridWrapper>
				</Grid>
			</Grid>
			<FormProvider>
				<RuleModal
					open={openRule}
					onClose={handleCloseRule}
					rule={rule || fetchedRule}
					isEditing={isEditing}
				/>
			</FormProvider>
			<DeleteRuleModal
				open={openDeleteRule}
				onClose={handleCloseDeleteRule}
				rule={rule || fetchedRule}
			/>
			<RunRulesModal
				open={openRunRules}
				onClose={() => setOpenRunRules(false)}
				onSubmit={() => setRunRulesRequestSubmitted(true)}
			/>
		</T4View>
	);
});
