import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, ReactNode } from 'react';
import { stonlyData } from 'stonly/functions';

export const stonlyIds = {
	closeButton: 'close-button',
};

export const modalBaseTestIds = {
	modalBase: 'modal-base',
	modalTitle: 'modal-base-title',
	modalContent: 'modal-base-content',
	modalActions: 'modal-base-actions',
	closeButton: 'modal-base-button',
};

export type ModalBaseProps = Omit<DialogProps, 'title' | 'onClose'> & {
	title?: ReactNode | string | undefined;
	onClose?: () => void;
	headerActions?: ReactNode;
	actions?: ReactNode;
	isDraggable?: boolean;
};

export const ModalBase: FC<ModalBaseProps> = observer(
	({ title, headerActions, actions, isDraggable = false, ...props }) => {
		return (
			<Dialog data-testid={modalBaseTestIds.modalBase} {...props}>
				{(title || headerActions) && (
					<DialogTitle
						data-testid={modalBaseTestIds.modalTitle}
						className={isDraggable ? 'draggable-dialog' : ''}
						style={{
							cursor: isDraggable ? 'move' : 'auto',
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						{title}
						{headerActions}
					</DialogTitle>
				)}
				<DialogContent data-testid={modalBaseTestIds.modalContent}>
					{props.children}
				</DialogContent>
				{actions ?? (
					<DialogActions data-testid={modalBaseTestIds.modalActions}>
						<Button
							data-testid={modalBaseTestIds.closeButton}
							{...stonlyData({ id: stonlyIds.closeButton })}
							onClick={props.onClose}
							color="primary"
						>
							Close
						</Button>
					</DialogActions>
				)}
			</Dialog>
		);
	},
);

export const ModalBaseActions: FC = observer(({ children }) => {
	return (
		<DialogActions
			data-testid={modalBaseTestIds.modalActions}
			sx={{
				paddingX: '1.5rem',
				paddingBottom: '1.5rem',
				gap: 1,
			}}
		>
			{children}
		</DialogActions>
	);
});
