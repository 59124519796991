import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import RuleCategories from './RuleCategories';
import { RuleConditions } from './RuleConditions';
import { RuleName } from './RuleName';

export type RuleFormProps = {
	isEditing: boolean;
};

export const RuleForm: FC<RuleFormProps> = observer(({ isEditing }) => (
	<Grid container rowSpacing={2}>
		<RuleName isEditing={isEditing} />
		<RuleConditions />
		<RuleCategories />
	</Grid>
));
