import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Cash4Configurations } from 'modules/clients/customer-api/src/api/administration';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { sharedQueryKeys } from './sharedQueryKeys';

export type UseCash4ConfigurationssV2QueryProps = UseQueryResult<
	Cash4Configurations,
	Error
>;

export function useCash4ConfigurationsQuery(): UseCash4ConfigurationssV2QueryProps {
	const { customerApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();

	const getCash4Configurations = useCallback(async () => {
		const response =
			await customerApiClient.api.administration.cash4Configurations();

		if (response && response.data && response.data.data) {
			return response.data.data;
		}

		throw new Error('Unable to load Cash4 configurations.');
	}, [customerApiClient.api.administration]);

	const result = useQuery<Cash4Configurations, Error>(
		[sharedQueryKeys.cash4Configurations],
		getCash4Configurations,
		{
			refetchOnWindowFocus: false,
		},
	);

	const { isError } = result;
	useEffect(() => {
		if (isError) {
			enqueueSnackbar('Unable to load Cash4 configurations.', {
				key: 'shared-cash4-configurations-failure',
				preventDuplicate: true,
				variant: 'error',
			});
		}
	}, [enqueueSnackbar, isError]);

	return result;
}
