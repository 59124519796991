import { DragHandle, FolderOpen } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import { Box, Popover, Typography } from '@mui/material';
import { DataGridPro, GridRowOrderChangeParams } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useSigma } from '../_providers/sigmaProvider';
import { SigmaButton } from './sigmaButton';

export type SigmaNavMenuProps = {
	folderId: string;
	anchorEl: null | HTMLElement;
	setAnchorEl: (value: null | HTMLElement) => void;
	menuId: string | null;
	setMenuId: (value: string | null) => void;
	handleOpenFolderAdd: () => void;
	handleOpenReportAdd: () => void;
};

export const SigmaNavMenu: FC<SigmaNavMenuProps> = observer(
	({
		folderId,
		anchorEl,
		setAnchorEl,
		menuId,
		setMenuId,
		handleOpenFolderAdd,
		handleOpenReportAdd,
	}) => {
		const {
			folders,
			workbooks,
			selectFolder,
			selectWorkbook,
			createFolderItems,
			updateFolderItems,
		} = useSigma();

		const filteredFolders = useMemo(() => {
			return folders
				.filter((folder) => folder.parentId === folderId)
				.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
		}, [folderId, folders]);

		const orderFolders = useCallback(() => {
			const maxOrder = Math.max(
				...filteredFolders.map((folder) => folder.order ?? -1),
			);

			const sortedFolders = [...filteredFolders].sort(
				(a, b) => (a.order ?? maxOrder + 1) - (b.order ?? maxOrder + 1),
			);

			sortedFolders.forEach((folder, index) => {
				if (folder.order !== index) {
					folder.order = index;
					updateFolderItems && updateFolderItems(folder, folder.order, false);
				}
			});

			sortedFolders.forEach((folder) => {
				if (folder.order === undefined) {
					folder.order = maxOrder + 1;
					createFolderItems && createFolderItems(folder, folder.order, false);
				}
			});
		}, [createFolderItems, filteredFolders]);

		const filteredWorkbooks = useMemo(() => {
			return workbooks
				.filter((workbook) => workbook.folderId === folderId)
				.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
		}, [folderId, workbooks, updateFolderItems]);

		const orderWorkbooks = useCallback(() => {
			const maxOrder = Math.max(
				...filteredWorkbooks.map((workbook) => workbook.order ?? -1),
			);

			const sortedWorkbooks = [...filteredWorkbooks].sort(
				(a, b) => (a.order ?? maxOrder + 1) - (b.order ?? maxOrder + 1),
			);

			sortedWorkbooks.forEach((workbook, index) => {
				if (workbook.order !== index) {
					workbook.order = index;
					updateFolderItems &&
						updateFolderItems(workbook, workbook.order, false);
				}
			});

			sortedWorkbooks.forEach((workbook) => {
				if (workbook.order === undefined) {
					workbook.order = maxOrder + 1;
					createFolderItems &&
						createFolderItems(workbook, workbook.order, true);
				}
			});
		}, [createFolderItems, filteredWorkbooks, updateFolderItems]);

		const reorderFolders = (newOrder: GridRowOrderChangeParams) => {
			const updatedFolders = filteredFolders.map((folder, index) => {
				if (folder.id === newOrder.row.id) {
					folder.order = newOrder.targetIndex;
				} else if (index >= newOrder.targetIndex && index < newOrder.oldIndex) {
					folder.order = index + 1;
				} else if (index <= newOrder.targetIndex && index > newOrder.oldIndex) {
					folder.order = index - 1;
				}
				return folder;
			});

			updatedFolders.forEach((folder, index) => {
				if (updateFolderItems) {
					updateFolderItems(folder, folder.order ?? -1, false);
				}
			});
		};

		const reorderWorkbooks = (newOrder: GridRowOrderChangeParams) => {
			const updatedWorkbooks = filteredWorkbooks.map((workbook, index) => {
				if (workbook.id === newOrder.row.id) {
					workbook.order = newOrder.targetIndex;
				} else if (index >= newOrder.targetIndex && index < newOrder.oldIndex) {
					workbook.order = index + 1;
				} else if (index <= newOrder.targetIndex && index > newOrder.oldIndex) {
					workbook.order = index - 1;
				}
				return workbook;
			});

			updatedWorkbooks.forEach((workbook, index) => {
				if (updateFolderItems) {
					updateFolderItems(workbook, workbook.order ?? -1, true);
				}
			});
		};

		useEffect(() => {
			if (
				filteredFolders.filter((x) => x.order === undefined).length > 0 &&
				menuId === folderId
			) {
				orderFolders();
			}
			if (
				filteredWorkbooks.filter((x) => x.order === undefined).length > 0 &&
				menuId === folderId
			) {
				orderWorkbooks();
			}
		}, [
			filteredFolders,
			filteredWorkbooks,
			menuId,
			folderId,
			orderFolders,
			orderWorkbooks,
		]);

		return (
			<Popover
				open={menuId === folderId && Boolean(anchorEl)}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				onClose={() => {
					setAnchorEl(null);
					setMenuId(null);
				}}
				sx={{ height: '80%', width: '400px' }}
			>
				{filteredFolders.length > 0 && (
					<>
						<Typography
							variant="subtitle2"
							key="reports-label"
							sx={{ paddingLeft: 1, paddingTop: 1, color: 'text.secondary' }}
						>
							Folders
						</Typography>

						<DataGridPro
							slots={{ rowReorderIcon: DragHandle }}
							rowHeight={28}
							onRowOrderChange={(newOrder) => {
								reorderFolders(newOrder);
							}}
							columnHeaderHeight={0}
							rows={filteredFolders.map((folder) => ({
								...folder,
								__reorder__: folder.name,
							}))}
							onRowSelectionModelChange={(selection) => {
								const selectedFolder = folders.find(
									(folder) => folder.id === selection[0],
								);
								if (selectedFolder) {
									setAnchorEl(null);
									setMenuId(null);
									selectFolder(selectedFolder);
								}
							}}
							columns={[
								{
									field: 'name',
									headerName: '',
									width: 250,
								},
							]}
							rowReordering={anchorEl !== null && menuId !== 'root'}
							sx={{ height: '30%', paddingX: 1 }}
							hideFooter
						/>
						<Box
							sx={{ display: 'flex', justifyContent: 'center', paddingY: 2 }}
						>
							<SigmaButton
								variant="outlined"
								startIcon={<FolderOpen />}
								onClick={handleOpenFolderAdd}
								sx={{ width: '80%' }}
							>
								Create Folder
							</SigmaButton>
						</Box>
					</>
				)}

				{filteredWorkbooks.length > 0 && (
					<>
						<Typography
							variant="subtitle2"
							key="reports-label"
							sx={{
								paddingLeft: 1,
								color: 'text.secondary',
								paddingTop:
									filteredFolders.length > 0 && filteredWorkbooks.length > 0
										? 1
										: 0,
							}}
						>
							Reports
						</Typography>
						<DataGridPro
							columnHeaderHeight={0}
							hideFooter
							rowHeight={28}
							slots={{
								rowReorderIcon: DragHandle,
							}}
							onRowOrderChange={(newOrder) => {
								reorderWorkbooks(newOrder);
							}}
							rows={filteredWorkbooks.map((workbook) => ({
								...workbook,
								__reorder__: workbook.name,
							}))}
							onRowSelectionModelChange={(selection) => {
								setAnchorEl(null);
								setMenuId(null);
								selectWorkbook(
									workbooks.find((workbook) => workbook.id === selection[0]),
								);
							}}
							columns={[
								{
									field: 'name',
									headerName: ' ',
									width: 250,
								},
							]}
							rowReordering
							sx={{ height: '30%' }}
						/>
					</>
				)}
				{folderId !== 'root' && (
					<Box sx={{ display: 'flex', justifyContent: 'center', paddingY: 2 }}>
						<SigmaButton
							variant="outlined"
							startIcon={<Add />}
							onClick={handleOpenReportAdd}
							sx={{ width: '80%' }}
						>
							Create Report
						</SigmaButton>
					</Box>
				)}
			</Popover>
		);
	},
);
