import { useSigma } from 'features/_shared/_providers/sigmaProvider';
import { SigmaWorkbook } from 'modules/clients/customer-api/src/sigmaEmbed';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useClients } from './useClients';

export type UseDeleteFolderButtonProps = {
	isLoading: boolean;
	onSubmit: (onSuccess: () => void) => Promise<void>;
};

export function useDeleteFolderButton(): UseDeleteFolderButtonProps {
	const { enqueueSnackbar } = useSnackbar();
	const { customerApiClient } = useClients();
	const {
		workbooks,
		folder,
		recentlyDeletedFolder,
		updateWorkbooks,
		removeFolder,
	} = useSigma();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const onSubmit = useCallback(
		async (onSuccess: () => void) => {
			try {
				setIsLoading(true);

				if (folder && recentlyDeletedFolder) {
					const workbooksInFolder = workbooks.filter(
						(x) => x.folderId === folder?.id,
					);

					const updatedWorkbooks: SigmaWorkbook[] = [];
					for (const workbook of workbooksInFolder) {
						try {
							const response = await customerApiClient.sigmaEmbed.moveWorkbook({
								fileId: workbook.id,
								folderId: recentlyDeletedFolder.id,
							});
							updatedWorkbooks.push(response.data);
						} catch {
							enqueueSnackbar(
								`Unable to move workbook with name: ${workbook.name}.`,
								{ variant: 'error' },
							);
						}
					}
					updateWorkbooks(updatedWorkbooks);

					await customerApiClient.sigmaEmbed.deleteFile({
						id: folder.id,
					});
					await customerApiClient.sigmaEmbed.deleteFolderItems({
						itemId: folder.id,
					});
					removeFolder(folder);

					onSuccess();
				} else {
					enqueueSnackbar('Unable to delete folder.', { variant: 'error' });
				}
			} catch {
				enqueueSnackbar('Unable to delete folder.', { variant: 'error' });
			} finally {
				setIsLoading(false);
			}
		},
		[
			customerApiClient.sigmaEmbed,
			enqueueSnackbar,
			folder,
			recentlyDeletedFolder,
			removeFolder,
			updateWorkbooks,
			workbooks,
		],
	);

	return {
		isLoading: isLoading,
		onSubmit: onSubmit,
	};
}
