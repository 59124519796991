import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useClients } from 'shared/hooks/useClients';

export type ReconciliationUpdateInput = {
	id: string;
	reportedTransactionIds: string[];
	projectedTransactionIds: string[];
	manuallyReconciled: boolean;
	notes?: string;
};

export type UseReconciliationUpdateMutationProps = UseMutationResult<
	string,
	Error,
	ReconciliationUpdateInput
>;

export function useReconciliationUpdateMutation(): UseReconciliationUpdateMutationProps {
	const { customerApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();

	const updateReconciliation = useCallback(
		async ({
			id,
			reportedTransactionIds,
			projectedTransactionIds,
			manuallyReconciled,
			notes,
		}: ReconciliationUpdateInput) => {
			const response = await customerApiClient.api.cash4.updateReconciliation(
				id,
				{
					reportedTransactions: reportedTransactionIds,
					projectedTransactions: projectedTransactionIds,
					manuallyReconciled: manuallyReconciled,
					note: notes || undefined,
				},
			);

			if (response?.data?.data) {
				return response.data.data;
			}

			throw new Error('Unable to update reconciliation.');
		},
		[customerApiClient.api.cash4],
	);

	return useMutation<string, Error, ReconciliationUpdateInput>({
		mutationFn: updateReconciliation,
		onSuccess: () => {
			enqueueSnackbar('Reconciliation updated.', {
				key: 'cash4-reconciliation-update-success',
				preventDuplicate: true,
				variant: 'success',
			});
		},
		onError: () => {
			enqueueSnackbar('Unable to update reconciliation.', {
				key: 'cash4-reconciliation-update-failure',
				preventDuplicate: true,
				variant: 'error',
			});
		},
	});
}
