import {
	ProjectedTransaction,
	Reconciliation,
} from 'modules/clients/customer-api/src/api/cash4';
import { useMemo } from 'react';
import { TransactionListItem } from '../../transactions/models';
import { projectedTransactions } from 'modules/clients/apiGateway/cash4/transactions/projectedItems';

export enum ReconciliationStatus {
	Unreconciled = 'Unreconciled',
	PartiallyReconciled = 'PartiallyReconciled',
	Reconciled = 'Reconciled',
	Posted = 'Posted',
}

export function normalizeReconciliationStatus(status: string): string {
	return status === ReconciliationStatus.PartiallyReconciled
		? 'Partially Reconciled'
		: status === ReconciliationStatus.Posted
		? 'No Reconciliation'
		: status;
}

export function convertToPercentage(value: number): string {
	return `${Math.round((value ?? 0) * 10000) / 100}%`;
}

export function getCurrencyCode(
	reconciliation:
		| Reconciliation
		| [
				projectedTransactions: ProjectedTransaction[],
				reportedTransactions: TransactionListItem[],
		  ]
		| undefined,
) {
	let projectedTransactions: ProjectedTransaction[] = [];
	let reportedTransactions: TransactionListItem[] = [];
	if (Array.isArray(reconciliation)) {
		const [_projectedTransactions, _reportedTransactions] = reconciliation;
		projectedTransactions = _projectedTransactions;
		reportedTransactions = _reportedTransactions;
	} else {
		projectedTransactions = reconciliation?.projectedTransactions ?? [];
		reportedTransactions = reconciliation?.reportedTransactions ?? [];
	}

	const currencyCodes = new Map<string, number>();
	projectedTransactions.forEach((item) => {
		currencyCodes.set(
			item.amount.accountCurrencyCode,
			(currencyCodes.get(item.currencyCode) ?? 0) +
				item.amount.accountCurrencyAmount,
		);
	});
	reportedTransactions.forEach((item) => {
		if (item.currency) {
			currencyCodes.set(
				item.currency,
				(currencyCodes.get(item.currency) ?? 0) +
					item.number.accountCurrencyAmount,
			);
		}
	});

	let count = 0;
	let currencyCode = 'USD';
	currencyCodes.forEach((value, key) => {
		if (value > count) {
			count = value;
			currencyCode = key;
		}
	});

	return currencyCode;
}

export function getProjectedTransactionsTotal(
	projectedTransactions: ProjectedTransaction[],
) {
	return projectedTransactions.reduce(
		(acc, item) => acc + Math.abs(item.amount.accountCurrencyAmount),
		0,
	);
}

export function getreportedTransactionsTotal(
	reportedTransactions: TransactionListItem[],
) {
	return reportedTransactions.reduce(
		(acc, item) => acc + Math.abs(item.number.accountCurrencyAmount),
		0,
	);
}

// export function calculateVariance(
// 	projectedAmount: number,
// 	reportedAmount: number,
// ) {
// 	// if (projectedAmount >= 0 && reportedAmount >= 0) {
// 	// 	return Math.abs(projectedAmount - reportedAmount);
// 	// } else if (projectedAmount < 0 && reportedAmount < 0) {
// 	// 	return -1 * Math.abs(projectedAmount - reportedAmount);
// 	// } else {
// 	// 	return projectedAmount >= reportedAmount
// 	// 		? reportedAmount - projectedAmount
// 	// 		: Math.abs(projectedAmount - reportedAmount);
// 	// }
// 	return 0;
// }

export function calculateUnreconciled(
	pts: ProjectedTransaction[],
	rts: TransactionListItem[],
) {
	const positiveProjectedAmount = pts
		.filter((x) => x.amount.accountCurrencyAmount >= 0)
		.reduce((acc, item) => acc + item.amount.accountCurrencyAmount, 0);

	const negativeProjectedAmount = pts
		.filter((x) => x.amount.accountCurrencyAmount < 0)
		.reduce(
			(acc, item) => acc + Math.abs(item.amount.accountCurrencyAmount),
			0,
		);

	const positiveReportedAmount = rts
		.filter((x) => x.number.accountCurrencyAmount >= 0)
		.reduce((acc, item) => acc + item.number.accountCurrencyAmount, 0);

	const negativeReportedAmount = rts
		.filter((x) => x.number.accountCurrencyAmount < 0)
		.reduce(
			(acc, item) => acc + Math.abs(item.number.accountCurrencyAmount),
			0,
		);

	const unreconciledPositive = Math.abs(
		Math.max(positiveReportedAmount, positiveProjectedAmount) -
			Math.min(positiveReportedAmount, positiveProjectedAmount),
	);
	const unreconciledNegative = Math.abs(
		Math.max(negativeReportedAmount, negativeProjectedAmount) -
			Math.min(negativeReportedAmount, negativeProjectedAmount),
	);

    if (unreconciledPositive > 0 && unreconciledNegative === 0) {
			return unreconciledPositive;
		} else if (unreconciledNegative > 0 && unreconciledPositive === 0) {
			return -1 * unreconciledNegative;
		} else {
			return unreconciledPositive + unreconciledNegative;
		}
}

export type UseReconciliationCalculationsProps = {
	unreconciledAmount: number;
	unreconciledVariance: number;
	reportedAmount: number;
	projectedAmount: number;
	isProjectedAmountValid: boolean;
	isReportedAmountValid: boolean;
	isUnreconciledAmountValid: boolean;
	isUnreconciledVarianceValid: boolean;
};

export function useReconciliationCalculations(
	projectedTransactions: ProjectedTransaction[],
	reportedTransactions: TransactionListItem[],
): UseReconciliationCalculationsProps {
	const projectedAmount = useMemo<number>(() => {
		return !projectedTransactions?.length
			? 0
			: getProjectedTransactionsTotal(projectedTransactions);
	}, [projectedTransactions]);

	const reportedAmount = useMemo<number>(() => {
		return !reportedTransactions?.length
			? 0
			: getreportedTransactionsTotal(reportedTransactions);
	}, [reportedTransactions]);

	const unreconciledAmount = useMemo<
		UseReconciliationCalculationsProps['unreconciledAmount']
	>(() => {
		return !reportedTransactions?.length || !projectedTransactions?.length
			? 0
			: calculateUnreconciled(projectedTransactions, reportedTransactions);
	}, [projectedTransactions, reportedTransactions]);

	const unreconciledVariance = useMemo<
		UseReconciliationCalculationsProps['unreconciledVariance']
	>(() => {
		if (unreconciledAmount === 0) {
			return 0;
		} else if (projectedAmount === 0) {
			return 1;
		} else {
			return unreconciledAmount / projectedAmount;
		}
	}, [projectedAmount, unreconciledAmount]);

	const isProjectedAmountValid = useMemo(() => {
		return !isNaN(projectedAmount);
	}, [projectedAmount]);

	const isReportedAmountValid = useMemo(() => {
		return !isNaN(reportedAmount);
	}, [reportedAmount]);

	const isUnreconciledAmountValid = useMemo(() => {
		return !isNaN(unreconciledAmount);
	}, [unreconciledAmount]);

	const isUnreconciledVarianceValid = useMemo(() => {
		return !isNaN(unreconciledVariance);
	}, [unreconciledVariance]);

	return {
		projectedAmount,
		reportedAmount,
		unreconciledAmount,
		unreconciledVariance,
		isProjectedAmountValid,
		isReportedAmountValid,
		isUnreconciledAmountValid,
		isUnreconciledVarianceValid,
	};
}
