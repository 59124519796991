import { useClients } from 'shared/hooks/useClients';
import { RulesEngineStatus } from '../models';
import { useCallback, useEffect, useState } from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { cash4QueryKeys } from 'features/cash4/_shared/cash4QueryKeys';

export type UseRulesEngineStatusQueryProps = UseQueryResult<
	RulesEngineStatus,
	Error
>;

export type UseRulesEngineStatusQueryPropsWrapper = {
	queryResult?: UseRulesEngineStatusQueryProps;
	lastRulesEngineStatus: RulesEngineStatus | null;
};

export function useRulesEngineStatusQuery(
	isRulesRunning: boolean,
): UseRulesEngineStatusQueryPropsWrapper {
	const { customerApiClient } = useClients();
	const [lastRulesEngineStatus, setLastRulesEngineStatus] =
		useState<RulesEngineStatus | null>(null);

	const getRulesEngineStatus = useCallback(async () => {
		const response = await customerApiClient.api.cash4.getRulesEngineStatus();

		if (response?.data?.data) {
			return response.data.data;
		}

		throw new Error('Unable to get rules executions.');
	}, [customerApiClient.api.cash4]);

	const result = useQuery<RulesEngineStatus, Error>({
		queryKey: [cash4QueryKeys.rulesExecution],
		queryFn: getRulesEngineStatus,
		refetchOnWindowFocus: false,
		refetchInterval(ruleExecution) {
			if (ruleExecution?.currentStatus === 'Processing' || isRulesRunning) {
				return 2_500;
			}
			return 30_000;
		},
	});

	useEffect(() => {
		if (result.data?.currentStatus === 'Failed') {
			enqueueSnackbar('Rules tripped while running. Please try again.', {
				key: 'cash4-rules-run-error',
				preventDuplicate: true,
				variant: 'error',
			});
		}
				if (result.data) {
			setLastRulesEngineStatus(result.data);
		}
	}, [
		lastRulesEngineStatus,
		lastRulesEngineStatus?.currentStatus,
		result.data,
		result.data?.currentStatus,
	]);

	return { queryResult: result, lastRulesEngineStatus };
}
