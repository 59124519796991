import moment, { Moment } from 'moment';
import { useState } from 'react';
import {
	getSessionItem,
	sessionStorageKeys,
	updateSessionItem,
} from 'shared/constants';

export type UseBalancesDateRangeProps = {
	startDate: Moment;
	endDate: Moment;
	setStartDate: (date: Moment) => void;
	setEndDate: (date: Moment) => void;
};

export function useBalancesDateRange(): UseBalancesDateRangeProps {
	const [startDate, _setBalanceStartDate] = useState<Moment>(() => {
		const date = getSessionItem(sessionStorageKeys.balancesStartDate);

		if (date) {
			return moment(date);
		} else {
			return moment().subtract(8, 'days').startOf('day');
		}
	});
	const [endDate, _setBalanceEndDate] = useState<Moment>(() => {
		const date = getSessionItem(sessionStorageKeys.balancesEndDate);

		if (date) {
			return moment(date);
		} else {
			return moment().startOf('day');
		}
	});

	function setStartDate(date: Moment) {
		updateSessionItem(sessionStorageKeys.balancesStartDate, date.toISOString());
		_setBalanceStartDate(date);
	}

	function setEndDate(date: Moment) {
		updateSessionItem(sessionStorageKeys.balancesEndDate, date.toISOString());
		_setBalanceEndDate(date);
	}

	return {
		startDate: startDate,
		endDate: endDate,
		setStartDate: setStartDate,
		setEndDate: setEndDate,
	};
}
