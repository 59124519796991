import { Account as E4Account } from 'modules/clients/apiGateway/entity4/accounts';
import * as yup from 'yup';

import { Address } from 'modules/clients/apiGateway/entity4';
import { RoutingCodes } from 'modules/clients/apiGateway/entity4/counterparties';
import { Moment } from 'moment';
import { BalanceForm } from './_hooks/constants';

export const E4AccountValidator: yup.ObjectSchema<E4Account> = yup.object({
	id: yup.string().label('Id').required(),
	code: yup.string().label('Code').required(),
	displayName: yup.string().label('Display Name').required(),
	isApproved: yup.boolean().required().oneOf([true], 'Account is not approved'),
	objectType: yup.string().required().defined().oneOf(['Account']),
	shortName: yup.string().label('Short Name').nullable().defined(),
	currencyCode: yup.string().label('Currency Code').nullable().defined(),
	accountNumbers: yup.object({
		naturalAccountNumber: yup
			.string()
			.trim()
			.label('Natural Account Number')
			.nullable()
			.required('Natural Account Number'),
		pain001: yup.string().label('PAIN001 Account Number').nullable().defined(),
	}),
	heldWithCounterparty: yup
		.object({
			id: yup.string().label('Id').required(),
			code: yup.string().label('Code').required(),
			displayName: yup.string().label('Display Name').required(),
			isApproved: yup
				.boolean()
				.required()
				.oneOf([true], 'Counterparty holder is not approved'),
			objectType: yup
				.string()
				.required()
				.oneOf(['Counterparty'], 'Object must be a Counterparty'),
			anglicizedLegalName: yup
				.string()
				.label('Anglicized Legal Name')
				.nullable()
				.defined(),
			bankCode: yup
				.string()
				.trim()
				.label('Ultimate Parent')
				.nullable()
				.required('Counterparty holder must have Ultimate Parent'),
			routingCodes: yup.mixed<RoutingCodes>().required(),
			operatingAddress: yup.mixed<Address>().nullable().defined(),
		})
		.required('Account must have Counterparty holder'),
});

export const BalanceFormValidator: yup.ObjectSchema<BalanceForm> = yup.object({
	e4Account: E4AccountValidator.label('Account').nullable().required(),
	balanceDate: yup.mixed<Moment>().label('Balance Date').nullable().required(),
	currency: yup.string().label('Currency').nullable().required().max(4),
	note: yup.string().label('Note').nullable().defined().max(2048),
	openingLedgerBalance: yup
		.number()
		.label('Opening Ledger Balance')
		.nullable()
		.defined()
		.test(
			'balance-value-required',
			'Opening Ledger Balance is required',
			(value, testContext) =>
				value !== null ||
				testContext.parent.openingAvailableBalance !== null ||
				testContext.parent.closingLedgerBalance !== null ||
				testContext.parent.closingAvailableBalance !== null,
		),
	openingAvailableBalance: yup
		.number()
		.label('Opening Available Balance')
		.nullable()
		.defined()
		.test(
			'balance-value-required',
			'Opening Available Balance is required',
			(value, testContext) =>
				value !== null ||
				testContext.parent.openingLedgerBalance !== null ||
				testContext.parent.closingLedgerBalance !== null ||
				testContext.parent.closingAvailableBalance !== null,
		),
	closingLedgerBalance: yup
		.number()
		.label('Closing Ledger Balance')
		.nullable()
		.defined()
		.test(
			'balance-value-required',
			'Closing Ledger Balance is required',
			(value, testContext) =>
				value !== null ||
				testContext.parent.openingLedgerBalance !== null ||
				testContext.parent.openingAvailableBalance !== null ||
				testContext.parent.closingAvailableBalance !== null,
		),
	closingAvailableBalance: yup
		.number()
		.label('Closing Available Balance')
		.nullable()
		.defined()
		.test(
			'balance-value-required',
			'Closing Available Balance is required',
			(value, testContext) =>
				value !== null ||
				testContext.parent.openingLedgerBalance !== null ||
				testContext.parent.openingAvailableBalance !== null ||
				testContext.parent.closingLedgerBalance !== null,
		),
});
