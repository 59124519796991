import { AxiosInstance, AxiosResponse } from 'axios';
import { T4DataResponse } from 'modules/clients/types';
import moment from 'moment';
import { formatWriteDate } from 'shared/utilities/dateUtilities';
import { ApiResponse } from 'utilities/api';

export type Note = {
	noteId: string;
	content?: string;
};

export type BalanceCards = {
	dailyAccountOpeningBalances: number;
	dailyAccountClosingBalances: number;
};

export enum BalanceReportType {
	PreviousDay = 0,
	CurrentDay = 1,
}

export enum BalanceSourceType {
	Manual = 'Cash4-Manual',
	Import = 'Cash4-Import',
	ApiIntegration = 'API Integration',
}

export type BalanceListItem = {
	balanceId: string;
	statementDate: string;
	balanceImportedDate: string;
	createdBy: string | null;
	lastUpdatedDate: string | null;
	updatedBy: string | null;
	c4AccountNumber: string | null;
	e4AccountNumber: string | null;
	e4AccountName: string | null;
	e4AccountId: string | null;
	e4AccountType: string | null;
	bankCode: string | null;
	bankName: string | null;
	openingLedgerBalance: number | null;
	openingLedgerBalance_ReportingCurrency: number | null;
	openingAvailableBalance: number | null;
	openingAvailableBalance_ReportingCurrency: number | null;
	currentLedgerBalance: number | null;
	currentLedgerBalance_ReportingCurrency: number | null;
	currentAvailableBalance: number | null;
	currentAvailableBalance_ReportingCurrency: number | null;
	totalCreditTransactions: number | null;
	totalCreditTransactions_ReportingCurrency: number | null;
	totalDebitTransactions: number | null;
	totalDebitTransactions_ReportingCurrency: number | null;
	closingLedgerBalance: number | null;
	closingLedgerBalance_ReportingCurrency: number | null;
	closingAvailableBalance: number | null;
	closingAvailableBalance_ReportingCurrency: number | null;
	forwardAvailableBalance0Day: number | null;
	forwardAvailableBalance0Day_ReportingCurrency: number | null;
	forwardAvailableBalance1Day: number | null;
	forwardAvailableBalance1Day_ReportingCurrency: number | null;
	forwardAvailableBalance2Day: number | null;
	forwardAvailableBalance2Day_ReportingCurrency: number | null;
	forwardAvailableBalance3Day: number | null;
	forwardAvailableBalance3Day_ReportingCurrency: number | null;
	transactionCount: number | null;
	debitTransactionsCount: number | null;
	creditTransactionsCount: number | null;
	currency: string | null;
	foreignExchangeRate: number | null;
	foreignExchangeDate: string | null;
	sourceType: BalanceSourceType | null;
	reportType: BalanceReportType;
	note: string | null;
};

export type CreateBalanceRequest = {
	e4AccountId: string;
	balanceDate: string;
	currency: string;
	openingLedgerBalance: number | null;
	openingAvailableBalance: number | null;
	closingLedgerBalance: number | null;
	closingAvailableBalance: number | null;
	naturalAccountNumber: string;
	bankCode: string;
	note: string | null;
	sourceType?: string | null;
	reportType?: string | null;
};

export type EditBalanceRequest = {
	e4AccountId: string;
	balanceDate: string;
	currency: string;
	openingLedgerBalance: number | null;
	openingAvailableBalance: number | null;
	closingLedgerBalance: number | null;
	closingAvailableBalance: number | null;
	naturalAccountNumber: string;
	bankCode: string;
	note: string | null;
};

export type BalancesQueryParams = {
	startDate: string;
	endDate: string;
	legalEntityGroupIds: string[] | undefined;
	accountGroupIds: string[] | undefined;
};

export type BalanceEndpoints = {
	all: (
		params: BalancesQueryParams,
	) => Promise<AxiosResponse<ApiResponse<BalanceListItem[]>>>;
	get: (id: string) => Promise<AxiosResponse<T4DataResponse<BalanceListItem>>>;
	getPriorDayLedger: () => Promise<AxiosResponse<ApiResponse<BalanceCards>>>;
	upsertNote: (
		id: string,
		note?: string,
	) => Promise<AxiosResponse<T4DataResponse<Note>>>;
	create: (
		request: CreateBalanceRequest,
	) => Promise<AxiosResponse<T4DataResponse<string>>>;
	edit: (
		id: string,
		request: EditBalanceRequest,
	) => Promise<AxiosResponse<T4DataResponse<string>>>;
	delete: (id: string) => Promise<AxiosResponse<T4DataResponse<string>>>;
};

export function balances(axiosInstance: AxiosInstance): BalanceEndpoints {
	return {
		all: async ({ startDate, endDate, legalEntityGroupIds, accountGroupIds }) =>
			axiosInstance.get<ApiResponse<BalanceListItem[]>>('/balances', {
				params: {
					startDate: startDate,
					endDate: endDate,
					legalEntityGroupIds: legalEntityGroupIds,
					accountGroupIds: accountGroupIds,
				},
				paramsSerializer: {
					indexes: null,
				},
			}),
		get: async (id: string) =>
			axiosInstance.get<T4DataResponse<BalanceListItem>>(
				`api/v1.0/cash4/cash/balances/${id}`,
			),
		getPriorDayLedger: async () =>
			axiosInstance.get<ApiResponse<BalanceCards>>('/balances/priordayledger', {
				params: {
					clientDate: formatWriteDate(moment().subtract(1, 'days')),
				},
			}),
		upsertNote: async (id: string, note?: string) =>
			axiosInstance.put<T4DataResponse<Note>>(
				`api/v1.0/cash4/cash/balances/${id}/note`,
				{ content: note ?? null },
			),
		create: async (request: CreateBalanceRequest) =>
			axiosInstance.post<T4DataResponse<string>>(
				'api/v1.0/cash4/cash/balances',
				request,
			),
		edit: async (id: string, request: EditBalanceRequest) =>
			axiosInstance.put<T4DataResponse<string>>(
				`api/v1.0/cash4/cash/balances/${id}`,
				request,
			),
		delete: async (id: string) =>
			axiosInstance.delete<T4DataResponse<string>>(
				`api/v1.0/cash4/cash/balances/${id}`,
			),
	};
}
