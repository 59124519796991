/* eslint-disable mobx/missing-observer */
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, IconButton } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { cash4QueryKeys } from 'features/cash4/_shared/cash4QueryKeys';
import { T4Checkbox } from 'features/entity4/shared/components/atoms/t4Checkbox';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { ModalBase } from 'shared/components/modalBase';
import { useClients } from 'shared/hooks/useClients';

interface TransactionAttributesProps {
	transactionId: string;
	isForecastModelExcluded: boolean;
	isAuthorRole: boolean;
}

const TransactionAttributes: React.FC<TransactionAttributesProps> = ({
	transactionId,
	isForecastModelExcluded,
	isAuthorRole,
}) => {
	const [forecastModelExcluded, setForecastModelExcluded] = useState<boolean>(
		isForecastModelExcluded,
	);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const { enqueueSnackbar } = useSnackbar();
	const queryClient = useQueryClient();
	const { customerApiClient } = useClients();

	async function updateAttributes(
		transactionId: string,
		isForecastModelExcluded: boolean,
	) {
		const transactionAttributesPayload = {
			transactionId: transactionId,
			isForecastModelExcluded: isForecastModelExcluded,
		};
		return await customerApiClient.transactions.updateAttributes(
			transactionAttributesPayload,
		);
	}

	const { mutate: updateAttributesMutation } = useMutation(
		(isForecastModelExcluded: boolean) =>
			updateAttributes(transactionId, isForecastModelExcluded),
		{
			onSuccess: (data) => {
				if (data.data) {
					enqueueSnackbar('Exclude from forecast reports saved', {
						variant: 'success',
					});
					queryClient.invalidateQueries([cash4QueryKeys.reportedTransactions]);
					queryClient.invalidateQueries(['transaction', transactionId]);
				} else {
					setForecastModelExcluded(!isForecastModelExcluded);
					enqueueSnackbar('Failed to save exclude from forecast reports', {
						variant: 'error',
					});
				}
			},
			onError: () => {
				enqueueSnackbar('Failed to save exclude from forecast reports', {
					variant: 'error',
				});
			},
		},
	);

	useEffect(() => {
		setForecastModelExcluded(isForecastModelExcluded);
	}, [isForecastModelExcluded]);

	return (
		<Box>
			<IconButton
				onClick={() => {
					setIsModalOpen(true);
				}}
			>
				<InfoOutlinedIcon />
			</IconButton>
			<T4Checkbox
				label="Exclude from forecast reports"
				checked={forecastModelExcluded}
				value={forecastModelExcluded}
				onChange={(_, value) => {
					setForecastModelExcluded(value);
					updateAttributesMutation(value);
				}}
				disabled={!isAuthorRole}
			/>
			<ModalBase
				title="Exclude from forecast reports"
				open={isModalOpen}
				onClose={() => {
					setIsModalOpen(false);
				}}
			>
				Select to exclude the transaction from forecast model calculations. This
				is useful for removing one-time or non-recurring transactions that may
				skew future projections.
			</ModalBase>
		</Box>
	);
};

export default TransactionAttributes;
