import {
	useMutation,
	UseMutationResult,
	useQueryClient,
} from '@tanstack/react-query';
import { cash4QueryKeys } from 'features/cash4/_shared/cash4QueryKeys';
import { CreateBalanceRequest } from 'modules/clients/customer-api/src/api/v1/cash4/balances';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { T4Link } from 'shared/components/t4Link';
import { paths } from 'shared/constants/paths';
import { useClients } from 'shared/hooks/useClients';
import { formatDate } from 'shared/utilities/dateUtilities';
import { BalanceForm } from './constants';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';

export const useCreateBalanceMutation = (): UseMutationResult<
	string,
	Error,
	BalanceForm
> => {
	const { customerApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();
	const queryClient = useQueryClient();

	const createBalance = useCallback(
		async (data: BalanceForm) => {
			const request: CreateBalanceRequest = {
				e4AccountId: data.e4Account!.id,
				naturalAccountNumber:
					data.e4Account!.accountNumbers.naturalAccountNumber!,
				bankCode: data.e4Account!.heldWithCounterparty!.bankCode!,
				balanceDate: formatDate(data.balanceDate)!,
				currency: data.currency!,
				openingLedgerBalance: data.openingLedgerBalance,
				openingAvailableBalance: data.openingAvailableBalance,
				closingLedgerBalance: data.closingLedgerBalance,
				closingAvailableBalance: data.closingAvailableBalance,
				note: data.note,
			};

			const response = await customerApiClient.api.v1.balances.create(request);
			if (response.status === 201) return response.data.data;
			else if (response.status === 400 && response.data) {
				throw new Error(response.data.error ?? undefined);
			} else throw new Error();
		},
		[customerApiClient],
	);

	return useMutation<string, Error, BalanceForm>({
		mutationFn: createBalance,
		onSuccess: (data) => {
			queryClient.invalidateQueries([cash4QueryKeys.balances]);
			enqueueSnackbar('Successfully created balance.', {
				key: 'cash4-create-balance-success',
				preventDuplicate: true,
				variant: 'success',
				action: (
					<T4Link to={`${paths.cash4.balances.href}/${data}`}>View</T4Link>
				),
			});
		},
		onError: (error: Error) => {
			let errorMessage = 'Unable to create balance. Please try again later.';
			if (
				!isStringUndefinedOrNullOrWhitespace(error.message) &&
				error.message.toLowerCase().includes('duplicate')
			)
				errorMessage =
					'Create Failed. The update conflicts with an existing balance record. Please choose a different account or adjust the details to prevent duplication.';
			enqueueSnackbar(errorMessage, {
				key: 'cash4-create-balance-error',
				preventDuplicate: true,
				variant: 'error',
			});
		},
	});
};
