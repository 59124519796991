export const cash4QueryKeys = {
	balancePriorDayLedger: 'cash4-balance-prior-day-ledger-query',
	balances: 'cash4-balances-query',
	balance: 'cash4-balance-query',
	reportedTransactions: 'cash4-reported-transactions-query',
	projectedTransactions: 'cash4-projected-transactions-query',
	forecastedTransactions: 'cash4-forecasted-transactions-query',
	parties: 'cash4-parties-query',
    rulesExecution: 'cash4-rules-execution-query',
    rules: 'cash4-rules-query'
};
