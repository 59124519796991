import {
	Box,
	Button,
	CircularProgress,
	DialogActions,
	Grid,
	Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { stonlyData } from 'stonly/functions';
import { ModalBase } from '../../../shared/components/modalBase';
import { T4Autocomplete } from '../shared/components/atoms/t4Autocomplete';
import { T4AlertStack } from '../shared/components/molecules/t4AlertStack';
import { IEntityAspectDto } from '../shared/repositories/frontendRepository';
import { useProfileView } from './providers/entityProfileContextProvider';

export const stonlyIds = {
	addButton: 'submit-button',
	cancelButton: 'cancel-button',
};
export const testIds = {
	addButton: 'add-button',
	cancelButton: 'cancel-button',
};

export interface AddAspectModalProps {
	open: boolean;
	onClose: () => void;
}
export const AddAspectModal: FC<AddAspectModalProps> = observer(
	({ open, onClose }) => {
		const { aspectManager } = useProfileView();
		const [selected, setSelected] = useState<IEntityAspectDto | null>(null);

		const closeModal = () => {
			setSelected(null);
			aspectManager.addAspectError = '';
			onClose();
		};

		const onCreate = async () => {
			await aspectManager.addAspect(selected!.urlId!);
			if (!aspectManager.hasAspectsError) closeModal();
		};

		return (
			<ModalBase
				title="Add an Aspect"
				onClose={() => onClose()}
				open={open}
				actions={
					<DialogActions
						sx={{
							paddingX: '1.5rem',
							paddingBottom: '1.5rem',
						}}
					>
						<Box display="flex" justifyContent="space-between" width="100%">
							{aspectManager.loadingAddAspect ? (
								<Box display="flex" alignItems="center">
									<Box marginRight={2}>
										<Typography noWrap={true}>Loading...</Typography>
									</Box>
									<CircularProgress size={25} />
								</Box>
							) : null}
							<Grid container spacing={1} justifyContent="flex-end">
								<Grid item>
									<Button
										onClick={closeModal}
										type="button"
										color="secondary"
										data-testid={testIds.cancelButton}
										{...stonlyData({ id: stonlyIds.cancelButton })}
									>
										Cancel
									</Button>
								</Grid>
								<Grid item>
									<Button
										data-testid={testIds.addButton}
										{...stonlyData({ id: stonlyIds.addButton })}
										onClick={onCreate}
										type="button"
										variant="contained"
										color="primary"
										disabled={aspectManager.loadingAddAspect || !selected}
									>
										Add
									</Button>
								</Grid>
							</Grid>
						</Box>
					</DialogActions>
				}
			>
				<p>
					Some records may require additional information beyond the normally
					available fields. Additional tabs, called Aspects, contain predefined
					sets of fields to enter extra record details. Using these tabs, you
					can configure individual records with information that may not be
					needed for most records of that type.
				</p>
				<p>
					Select an aspect below to add additional details to this specific
					record.
				</p>

				<T4Autocomplete<IEntityAspectDto, false, false, false>
					id="aspect-autocomplete"
					label="Available Aspects"
					value={selected ?? undefined}
					options={aspectManager.availableAspects}
					onSelect={(value) => setSelected(value)}
					getOptionLabel={(option) => option.name}
					isOptionEqualToValue={(option, value) => option.urlId === value.urlId}
					renderOption={(props, option) => {
						if (option === null) return null;
						return (
							<li {...props}>
								<Box paddingX={0.5}>
									<Typography variant="h6">{option?.name}</Typography>
									<Typography variant="caption">
										{option?.description}
									</Typography>
								</Box>
							</li>
						);
					}}
					required
				/>

				<T4AlertStack info={aspectManager.addAspectError} />
			</ModalBase>
		);
	},
);
