import {
	useQuery,
	useQueryClient,
	UseQueryResult,
} from '@tanstack/react-query';
import { cash4QueryKeys } from 'features/cash4/_shared/cash4QueryKeys';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { mapBalance } from '../_utilities/balanceUtils';
import { Balance } from './useBalancesQuery';

export type UseBalanceQueryProps = UseQueryResult<Balance, Error>;

export function useBalanceQuery(id?: string): UseBalanceQueryProps {
	const { customerApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();
	const queryClient = useQueryClient();

	const initialData = useMemo(() => {
		const balances = queryClient.getQueryData<Balance[]>([
			cash4QueryKeys.balances,
		]);

		if (balances) {
			return balances.find((x) => x.id === id);
		}
	}, [id, queryClient]);

	const getBalance = useCallback(
		async (id?: string) => {
			if (id) {
				const response = await customerApiClient.api.v1.balances.get(id);

				if (response?.data?.data) {
					return mapBalance(response.data.data);
				}
			}

			throw new Error('Unable to get balance.');
		},
		[customerApiClient.api.v1.balances],
	);
	const result = useQuery<Balance, Error>(
		[cash4QueryKeys.balance, id],
		() => getBalance(id),
		{
			initialData: initialData,
			enabled: !!id,
			refetchOnWindowFocus: false,
		},
	);
	const { isError } = result;

	useEffect(() => {
		if (isError) {
			enqueueSnackbar('Unable to get balance.', {
				key: 'cash4-balance-failure',
				preventDuplicate: true,
				variant: 'error',
			});
		}
	}, [enqueueSnackbar, isError]);

	return result;
}
