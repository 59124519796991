import {
	Box,
	Button,
	CircularProgress,
	DialogActions,
	Divider,
	Grid,
	Typography,
} from '@mui/material';
import { GridPaginationModel } from '@mui/x-data-grid-pro';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4FieldAdornment } from 'features/entity4/shared/components/molecules/t4FieldAdornment';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { T4DataGrid } from 'shared/components/dataGrid/dataGrid';
import { useReferenceDataFetcher } from 'shared/hooks/useReferenceDataFetcher';
import { useHeader } from 'shared/providers/contexts/headerContext';
import { ReferenceDataValue } from 'shared/types/referenceDataTypes';
import { ModalBase } from '../../../../../../shared/components/modalBase';
import { ObjectRegisteredAgentDetailPathParams } from '../../../../../../shared/constants/paths';
import { DocumentListView } from '../../../../documents/components/documentListView';
import DocumentListViewModel from '../../../../documents/models/documentListViewModel';
import { SubObjectType } from '../../../../entity4Constants';
import { RegisteredAgentAddressWrapper } from '../../../../entityAspect/RegisteredAgentDetails/addresses/registeredAgentAddressWrapper';
import { JURISDICTION_FIELD_DESCRIPTIONS } from '../../../../entityAspect/RegisteredAgentDetails/models/jurisdictionModel';
import { RegisteredAgentJurisdictionViewModel } from '../../../../entityAspect/RegisteredAgentDetails/models/registeredAgentJurisdictionViewModel';
import { useProfileView } from '../../../../entityProfile/providers/entityProfileContextProvider';
import { T4Checkbox } from '../../../../shared/components/atoms/t4Checkbox';
import { T4AlertStack } from '../../../../shared/components/molecules/t4AlertStack';
import { FieldViews } from '../../../../shared/fieldSets/fieldViews/fieldViewTypes';

export const ObjectRegisteredAgentJurisdictionPage: FC = observer(() => {
	const history = useHistory();
	const {
		objectId,
		objectType,
		registeredAgentDetailId: jurisdictionId,
	} = useParams<ObjectRegisteredAgentDetailPathParams>();
	const { updateLastAutoSave, updateSubObjectName } = useHeader();
	const { viewType } = useProfileView();
	const { fetch } = useReferenceDataFetcher();

	const [viewModel] = useState(
		new RegisteredAgentJurisdictionViewModel(
			objectId,
			objectType,
			jurisdictionId,
			updateLastAutoSave,
		),
	);
	const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
		page: 0,
		pageSize: 25,
	});
	const documentListViewModel = useMemo(
		() =>
			new DocumentListViewModel(
				objectType,
				objectId!,
				history,
				fetch,
				viewModel.jurisdictionId,
				SubObjectType.RegisteredAgentJurisdiction,
			),
		[objectType, objectId, history, fetch, viewModel.jurisdictionId],
	);

	useEffect(() => {
		updateSubObjectName('');
		viewModel.load().finally(() => {
			updateSubObjectName(viewModel.formattedLocation);
		});
	}, [updateSubObjectName, viewModel]);

	useEffect(() => {
		documentListViewModel.load();
	}, [documentListViewModel]);

	const readOnly = viewType !== FieldViews.edit;

	if (viewModel.loading || !viewModel.jurisdiction) {
		return (
			<Box width="100%" my={5} py={2} display="flex" justifyContent="center">
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Box sx={{ paddingY: '1.5rem', paddingX: '2rem' }}>
			<Grid container item xs={12}>
				<Typography variant="h3" paddingBottom={1}>
					Jurisdiction Details
				</Typography>
				<Grid container columnSpacing={2}>
					<Grid item xs={12} sm={6}>
						<T4Autocomplete<ReferenceDataValue, false, true, false>
							label="Country"
							value={viewModel.jurisdiction.countryOption ?? undefined}
							options={viewModel.countryOptions}
							onSelect={async (country) => {
								await viewModel.setCountry(country);
							}}
							getOptionLabel={(option) => option.displayName}
							isOptionEqualToValue={(option, value) =>
								option.value === value.value
							}
							onFocus={async () => {
								if (!readOnly) await viewModel.location.loadCountries();
							}}
							loading={
								viewModel.location.loadingCountries ||
								viewModel.jurisdiction.isWaiting('country')
							}
							startAdornment={
								<T4FieldAdornment
									description={JURISDICTION_FIELD_DESCRIPTIONS.country}
									t4AdornmentType={viewModel.countryAdornment}
								/>
							}
							readOnly={readOnly}
							disableClearable={true}
							required
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<T4Autocomplete
							label="State/Province"
							value={viewModel.jurisdiction.stateOption}
							options={viewModel.provinceOptions}
							onSelect={async (state) => {
								await viewModel.setState(state);
							}}
							getOptionLabel={(option) => option.displayName}
							isOptionEqualToValue={(option, value) =>
								option.value === value.value
							}
							onFocus={async () => {
								if (!readOnly && !viewModel.location.provinceOptions.length)
									await viewModel.location.loadProvinces(
										viewModel.jurisdiction!.countryOption!.value!,
									);
							}}
							loading={
								viewModel.location.loadingProvinces ||
								viewModel.jurisdiction.isWaiting('state')
							}
							startAdornment={
								<T4FieldAdornment
									description={JURISDICTION_FIELD_DESCRIPTIONS.state}
									t4AdornmentType={viewModel.stateAdornment}
								/>
							}
							readOnly={readOnly}
						/>
						<T4AlertStack error={viewModel.location.provinceError} />
					</Grid>
				</Grid>
				<Grid container columnSpacing={2}>
					<Grid item xs={12} sm={12}>
						<T4AlertStack error={viewModel.jurisdiction.error} />
					</Grid>
				</Grid>
			</Grid>
			<Box paddingY={2}>
				<Box display="flex" alignItems="center" paddingBottom={1}>
					<Typography variant="h4">Street Address</Typography>
				</Box>
				<RegisteredAgentAddressWrapper
					readOnly={readOnly}
					address={viewModel.jurisdiction.streetAddress}
				/>
			</Box>
			<Box paddingY={2}>
				<Divider />
			</Box>
			<Box paddingY={2}>
				<Box display="flex" alignItems="center" paddingBottom={1}>
					<Typography variant="h4" paddingRight={2}>
						Mailing Address
					</Typography>
					<T4Checkbox
						label="Same as Street Address"
						disabled={readOnly}
						checked={viewModel.jurisdiction.isMailingSameAsStreetAddress}
						onChange={(event) => {
							viewModel?.jurisdiction?.setIsMailingSameAsStreetAddress(event);
							updateLastAutoSave();
						}}
					/>
				</Box>
				<RegisteredAgentAddressWrapper
					readOnly={readOnly}
					disabled={viewModel.jurisdiction.isMailingSameAsStreetAddress}
					address={
						viewModel.jurisdiction.isMailingSameAsStreetAddress
							? viewModel.jurisdiction.streetAddress
							: viewModel.jurisdiction.mailingAddress
					}
					isMailingAddress
				/>
			</Box>
			<Box paddingY={2}>
				<Typography variant="h4" paddingBottom={2}>
					Associated Registrations
				</Typography>
				<T4DataGrid
					stonlyId="registrations"
					columns={viewModel.associatedRegistrationColumns}
					rows={viewModel.associatedRegistrationRows}
					loading={viewModel.loadingAssociatedRegistrations}
					pagination
					paginationModel={paginationModel}
					onPaginationModelChange={(model) => setPaginationModel(model)}
					pageSizeOptions={[25, 50, 100]}
					hideFooter={false}
					showToolbar
					initialState={{
						sorting: {
							sortModel: [{ field: 'entityName', sort: 'asc' }],
						},
					}}
					errorMessage={viewModel.associatedRegistrationsError}
					emptyMessage="There are no associated Registrations"
					autoHeight={true}
				/>
			</Box>
			<Box paddingY={2}>
				<DocumentListView viewModel={documentListViewModel} />
			</Box>

			<ModalBase
				title="Replace Mailing Address?"
				onClose={viewModel.jurisdiction!.closeMailingAddressModal}
				open={viewModel.jurisdiction.openMailingAddressModal}
				actions={
					<DialogActions
						sx={{
							paddingX: '1.5rem',
							paddingBottom: '1.5rem',
						}}
					>
						<Button
							onClick={viewModel.jurisdiction!.clearMailingAddress}
							type="button"
							variant="contained"
							color="primary"
							disabled={viewModel.jurisdiction!.isWaiting(
								'isMailingSameAsStreetAddress',
							)}
						>
							Replace
						</Button>
						<Button
							onClick={viewModel.jurisdiction!.closeMailingAddressModal}
							type="button"
							color="secondary"
						>
							Cancel
						</Button>
					</DialogActions>
				}
				sx={{
					'& .MuiDialog-paper': {
						width: '100%',
					},
				}}
			>
				Are you sure you want to replace the mailing address with the street
				address? This will remove the existing information.
			</ModalBase>
		</Box>
	);
});
